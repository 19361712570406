import store from '../store/index.js';
import { getAPI, postAPI, getGuestID } from "./general";
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'
import i18n from './i18n.js';

const getCart = async (country_id = null, postal_code = null, selected_shipping = null) => {
	var data = {};
	// data.language_id = store.state.language_id[i18n.global.locale];
	if (!store.state.isLoggedIn) {
		data['guest_id'] = getGuestID();
	}
	if (localStorage.outlet_id) {
		data['outlet_id'] = localStorage.outlet_id;
	}
	if (country_id != null) {
		data['country'] = country_id;
	}
	if (postal_code != null) {
		data['postal_code'] = postal_code;
	}
	if (selected_shipping != null) {
		data['selected_shipping'] = selected_shipping;
	}
	var response = await getAPI('/cart', data);
	if (response.code == 'success') {
		store.state.carts = response.data;
	} else if (response.code != 'invalid_token') {
		var errorMessage = i18n.global.t('something_wrong');
		if (response.message) {
			errorMessage.message;
		}

		Swal.fire({
			toast: true,
			position: "top",
			text: errorMessage,
			icon: 'error',
			showConfirmButton: false,
			timer: 2000,
			didOpen: (toast) => {
				toast.addEventListener('click', () => {
					Swal.close();
				});
			}
		});
	}
	return response;
}

const addCartItem = async (
	product_id,
	variant_id = null,
	bundle_type_id = null,
	quantity = null,
	outlet_id = null,
	order_item_remark = null,
	addon_products = null
) => {
	var data = {
		product_id: product_id,
	};
	if (!store.state.isLoggedIn) {
		data['guest_id'] = getGuestID();
	}

	if (order_item_remark) {
		data['order_item_remark'] = order_item_remark;
	}

	if (variant_id) {
		data['variant_id'] = variant_id;
	}

	if (bundle_type_id) {
		data['bundle_type_id'] = bundle_type_id;
	}

	if (quantity) {
		data['quantity'] = quantity;
	}

	if (addon_products.length > 0) {
		data['addon_products'] = JSON.parse(JSON.stringify(addon_products));
	}

	if (outlet_id) {
		data['outlet_id'] = outlet_id;
	}

	var response = await postAPI('/cart/item/add', data);
	if (response.code == 'success') {

		Swal.fire({
			toast: true,
			position: "top",
			text: i18n.global.t('cart_updated'),
			icon: 'success',
			showConfirmButton: false,
			timer: 2000,
			didOpen: (toast) => {
				toast.addEventListener('click', () => {
					Swal.close();
				});
			}
		});
	} else {

		Swal.fire({
			toast: true,
			position: "top",
			text: response.message ? response.message : 'Unexpected Error Occurs.',
			icon: 'error',
			showConfirmButton: false,
			timer: 2000,
			didOpen: (toast) => {
				toast.addEventListener('click', () => {
					Swal.close();
				});
			}
		});
	}
	return response;
}

const addCartItemCartPage = async (
	product_id,
	variant_id = null,
	bundle_type_id = null,
	quantity = null,
	outlet_id = null,
	order_item_remark = null,
	addon_products = [],
) => {
	var data = {
		product_id: product_id,
		set_quantity: 1
	};
	if (!store.state.isLoggedIn) {
		data['guest_id'] = getGuestID();
	}

	if (variant_id) {
		data['variant_id'] = variant_id;
	}

	if (bundle_type_id) {
		data['bundle_type_id'] = bundle_type_id;
	}

	if (quantity) {
		data['quantity'] = quantity;
	}

	if (outlet_id) {
		data['outlet_id'] = outlet_id;
	}

	if (order_item_remark) {
		data['order_item_remark'] = order_item_remark;
	}

	if (addon_products.length > 0) {
		data['addon_products'] = JSON.parse(JSON.stringify(addon_products));
	}

	var response = await postAPI('/cart/item/add', data);
	if (response.code == 'success') {

		Swal.fire({
			toast: true,
			position: "top",
			text: i18n.global.t('cart_updated'),
			icon: 'success',
			showConfirmButton: false,
			timer: 2000,
			didOpen: (toast) => {
				toast.addEventListener('click', () => {
					Swal.close();
				});
			}
		});
	} else {

		Swal.fire({
			toast: true,
			position: "top",
			text: response.message ? response.message : 'Unexpected Error Occurs.',
			icon: 'error',
			showConfirmButton: false,
			timer: 2000,
			didOpen: (toast) => {
				toast.addEventListener('click', () => {
					Swal.close();
				});
			}
		});
	}
	return response;
}

const updateCartItemVariant = async (cart_item_id, new_variant_id, new_cart_item_quantity = false, addon_products = null) => {

	var data = {
		cart_item_id: cart_item_id,
		new_variant_id: new_variant_id
	};
	if (!store.state.isLoggedIn) {
		data['guest_id'] = getGuestID();
	}
	if (new_cart_item_quantity !== false) {
		data['new_cart_item_quantity'] = new_cart_item_quantity;
	}

	if (localStorage.outlet_id) {
		data.outlet_id = localStorage.outlet_id
	}

	if (addon_products.length > 0) {
		data['addon_products'] = JSON.parse(JSON.stringify(addon_products));
	}

	var response = await postAPI('/cart/item/updateVariant', data);
	if (response.code == 'success') {
		Swal.fire({
			toast: true,
			position: "top",
			text: i18n.global.t('cart_updated'),
			icon: 'success',
			showConfirmButton: false,
			timer: 2000,
			didOpen: (toast) => {
				toast.addEventListener('click', () => {
					Swal.close();
				});
			}
		});
	} else if (response.code != 'invalid_token') {
		// var errorMessage = 'Something went wrong.';
		// if (response.message) {
		//     errorMessage.message;
		// }
		Swal.fire({
			toast: true,
			position: "top",
			text: response.message,
			icon: 'error',
			showConfirmButton: false,
			timer: 2000,
			didOpen: (toast) => {
				toast.addEventListener('click', () => {
					Swal.close();
				});
			}
		});
	}
	return response;
}

const updateCartItemBundle = async ( cart_item_id, new_bundle_type_id, new_cart_item_quantity = false, addon_products = null) => {

	var data = {
		cart_item_id: cart_item_id,
		new_bundle_type_id: new_bundle_type_id
	};
	if (!store.state.isLoggedIn) {
		data['guest_id'] = getGuestID();
	}
	if (new_cart_item_quantity !== false) {
		data['new_cart_item_quantity'] = new_cart_item_quantity;
	}
	if (localStorage.outlet_id) {
		data.outlet_id = localStorage.outlet_id
	}
	if (addon_products.length > 0) {
		data['addon_products'] = JSON.parse(JSON.stringify(addon_products));
	}

	var response = await postAPI('/cart/item/updateBundle', data);
	if (response.code == 'success') {
		Swal.fire({
			toast: true,
			position: "top",
			text: i18n.global.t('cart_updated'),
			icon: 'success',
			showConfirmButton: false,
			timer: 2000,
			didOpen: (toast) => {
				toast.addEventListener('click', () => {
					Swal.close();
				});
			}
		});
	} else if (response.code != 'invalid_token') {
		// var errorMessage = 'Something went wrong.';
		// if (response.message) {
		//     errorMessage.message;
		// }
		Swal.fire({
			toast: true,
			position: "top",
			text: response.message,
			icon: 'error',
			showConfirmButton: false,
			timer: 2000,
			didOpen: (toast) => {
				toast.addEventListener('click', () => {
					Swal.close();
				});
			}
		});
	}
	return response;
}

const deleteCartItem = async (cart_item_id) => {
	var data = {
		cart_item_id: cart_item_id,
	};
	if (!store.state.isLoggedIn) {
		data['guest_id'] = getGuestID();
	}

	var response = await postAPI('/cart/item/delete', data);
	if (response.code == 'success') {

		Swal.fire({
			toast: true,
			position: "top",
			text: i18n.global.t('remove_success'),
			icon: 'success',
			showConfirmButton: false,
			timer: 2000,
			didOpen: (toast) => {
				toast.addEventListener('click', () => {
					Swal.close();
				});
			}
		});
	} else if (response.code != 'invalid_token') {
		var errorMessage = i18n.global.t('something_wrong');
		if (response.message) {
			errorMessage.message;
		}

		Swal.fire({
			toast: true,
			position: "top",
			text: errorMessage,
			icon: 'error',
			showConfirmButton: false,
			timer: 2000,
			didOpen: (toast) => {
				toast.addEventListener('click', () => {
					Swal.close();
				});
			}
		});
	}
	return response;
}

const getCoupons = async (availableOnly = false, withoutReward = false) => {
	var data = {};
	if (availableOnly) {
		data.availableOnly = '1';
	}

	if (withoutReward) {
		data.withoutReward = '1';
	}
	// data.language_id = store.state.language_id[i18n.global.locale];

	if (!store.state.isLoggedIn) {
		Swal.fire({
			toast: true,
			position: "top",
			text: i18n.global.t('login_perform'),
			icon: 'error',
			showConfirmButton: false,
			timer: 2000,
			didOpen: (toast) => {
				toast.addEventListener('click', () => {
					Swal.close();
				});
			}
		});
	} else {
		var response = await getAPI('/user/coupon', data);
		if (response.code == 'success') {
			return response.data.coupons;
		} else if (response.code != 'invalid_token') {
			var errorMessage = i18n.global.t('something_wrong');
			if (response.message) {
				errorMessage.message;
			}
			Swal.fire({
				toast: true,
				position: "top",
				text: errorMessage,
				icon: 'error',
				showConfirmButton: false,
				timer: 2000,
				didOpen: (toast) => {
					toast.addEventListener('click', () => {
						Swal.close();
					});
				}
			});
		}
	}
	return false;
}

const getCartCoupons = async () => {
	var data = {};

	// data.language_id = store.state.language_id[i18n.global.locale];
	if (!store.state.isLoggedIn) {
		Swal.fire({
			toast: true,
			position: "top",
			text: i18n.global.t('login_perform'),
			icon: 'error',
			showConfirmButton: false,
			timer: 2000,
			didOpen: (toast) => {
				toast.addEventListener('click', () => {
					Swal.close();
				});
			}
		});
	} else {
		var response = await getAPI('/cart/coupons', data);
		if (response.code == 'success') {
			return response.data;
		} else if (response.code != 'invalid_token') {
			var errorMessage = i18n.global.t('something_wrong');
			if (response.message) {
				errorMessage.message;
			}
			Swal.fire({
				toast: true,
				position: "top",
				text: errorMessage,
				icon: 'error',
				showConfirmButton: false,
				timer: 2000,
				didOpen: (toast) => {
					toast.addEventListener('click', () => {
						Swal.close();
					});
				}
			});
		}
	}
	return false;
}

export {
	getCart,
	addCartItem,
	addCartItemCartPage,
	updateCartItemVariant,
	updateCartItemBundle,
	deleteCartItem,
	getCoupons,
	getCartCoupons,
};
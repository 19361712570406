<template>
    <div class="frame" id="popup-review">
        <div class="page page-review">
            <h1>{{ $t('review.title') }}</h1>
            <div class="link-back" @click="backToDetail()"><img src="/images/icons/back.png"></div>
            <section class="review__box">
                <h2>{{ $t('review.food_drink_review') }}</h2>
                <div class="review__star">
                    <div class="review__star_row">
                        <div v-for="rating in 5" :key="rating" class="review__star_rate"
                            :class="{ 'selected': rating <= selectedRating }" :data-selection="rating"
                            @click="startSelection(rating)"></div>
                    </div>
                </div>
                <div class="review__selection">
                    <label>{{ $t('review.how_we_improve') }}</label>
                    <div class="review__selection_row">
                        <div class="review__selection_box" :class="{ 'selected': isSelected('beer') }"
                            data-selection="beer" @click="toggleSelection('beer')">{{ $t('review.beer') }}</div>

                        <div class="review__selection_box" :class="{ 'selected': isSelected('food') }"
                            data-selection="food" @click="toggleSelection('food')">{{ $t('review.food') }}</div>

                        <div class="review__selection_box" :class="{ 'selected': isSelected('packaging') }"
                            data-selection="packaging" @click="toggleSelection('packaging')">{{ $t('review.packaging')
                            }}</div>

                        <div class="review__selection_box" :class="{ 'selected': isSelected('taste') }"
                            data-selection="taste" @click="toggleSelection('taste')">{{ $t('review.taste') }}</div>

                        <div class="review__selection_box" :class="{ 'selected': isSelected('portion_size') }"
                            data-selection="portion" @click="toggleSelection('portion_size')">{{
                $t('review.portion_size') }}
                        </div>

                        <div class="review__selection_box" :class="{ 'selected': isSelected('delivery') }"
                            data-selection="delivery" @click="toggleSelection('delivery')">{{
                $t('review.delivery_service') }}</div>
                    </div>
                </div>
                <div class="review__input">
                    <label>{{ $t('review.tell_us') }}</label>
                    <textarea v-model="additionalReview" :placeholder="$t('review.add_your_feedback')" rows="5"
                        maxlength="50" class="input-primary"></textarea>
                </div>
                <button class="btn btn-primary " :class="{ 'disabled': !submitButton }" @click="submitReview()">{{
                $t('review.submit') }}</button>
            </section>
        </div>
    </div>

</template>


<script>
import { postAPI } from '@/js/general';


export default {
    name: 'ReviewView',
    props: {
        popupData: {},
    },
    data() {
        return {
            selectedRating: 0,
            selectedOptions: [],
            additionalReview: '',
            submitButton: true,
        }
    },
    created() {
    },
    components: {
    },
    computed: {
    },
    mounted() {
        if (this.popupData != null) {
            document.getElementById('popup-review').style.display = 'block';
        }
    },
    methods: {
        toggleSelection(option) {
            const index = this.selectedOptions.indexOf(option);
            if (index !== -1) {
                this.selectedOptions.splice(index, 1); // Deselect option if already selected
            } else {
                this.selectedOptions.push(option); // Select option if not selected
            }
        },
        isSelected(option) {
            return this.selectedOptions.includes(option);
        },
        startSelection(selection) {
            this.selectedRating = selection;
        },
        async submitReview() {

            var data = {
                order_id: this.popupData.id,
                rating: this.selectedRating,
                type: {
                    "types": this.selectedOptions
                },
                remark: this.additionalReview
            }

            if (this.selectedRating < 1) {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('order_details.noRating'),
                    icon: 'info',
                    showConfirmButton: false,
                    timer: 3000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    },
                });
            } else {
                var reviewResponse = await postAPI('/orderreview', data);

                if (reviewResponse.code == 'success') {
                    this.submitButton = false;

                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: this.$t('review.submitted'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$emit('close');
                                this.$router.push(`${this.$store.state.multiLanguage}` + 'history');
                                this.$swal.close();
                            });
                        },
                        willClose: () => {
                            this.$emit('close');
                            this.$router.push(`${this.$store.state.multiLanguage}` + 'history');
                        },
                    });
                } else {
                    this.submitButton = true;

                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: reviewResponse.message,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                }
            }
        },
        backToDetail() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
.review__box {
    bottom: 0;
    animation: slideIn 0.5s ease-in-out forwards;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>
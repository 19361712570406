<template>
    <div class="frame">
        <div class="page page-order-details"
            v-if="this.orderDetail.status === 'delivered' || this.orderDetail.status == 'closed' || this.orderDetail.status === 'cancelled' || this.orderDetail.status === 'failed' || this.orderDetail.status === 'collected'">
            <h1>{{ $t('order_details.title') }} #{{ orderDetail.id }}</h1>
            <router-link :to="this.$store.state.multiLanguage + 'history'" class="link-back"><img
                    src="/images/icons/back.png"></router-link>
            <section class="order_details__contact" v-if="false">
                <label>{{ $t('order_confirmation.contact_info') }}</label>
                <div class="order_details__contact_box">
                    <div class="order_details__contact--thumbnail">
                        <img src="/images/sample/profile.png">
                    </div>
                    <div class="order_details__contact--name">
                        <label>{{ orderDetail.member_firstname }}</label>
                        <span>{{ orderDetail.member_phone }}</span>
                    </div>
                </div>
            </section>
            <section class="order_details__action_info">
                <p class="order_details__action_info-id">#{{ orderDetail.id }}</p>
                <p v-if="orderDetail.status != 'cancelled'">{{ $t('order_details.completed') }}</p>
                <p v-else>{{ $t('order_details.cancelled') }}</p>
                <div class="order_details__action_button">
                    <button v-if="orderDetail.status != 'cancelled' && orderDetail.status != 'failed'"
                        @click="reviewOrder()" :class="'btn btn-secondary' + (orderDetail.is_reviewed ? ' reviewed' : '')" >{{ $t('order_details.rate') }}</button>
                    <button class="btn btn-primary" @click="reorderItem(orderDetail)">{{ $t('order_history.reorder')
                        }}</button>
                </div>
            </section>
            <section class="order_details__address-location" v-if="orderDetail.is_pickup == 1">
                <div class="page-order-preview-box-location">
                    <h2>{{ $t('order_details.location') }}</h2>
                    <div class="page-order-preview-box-location-outlet">
                        <div class="location_icon">
                            <img src="/images/icons/black_circle.png" alt="black circle" />
                            <img class="location_icon-center" src="/images/icons/white_dot.png"
                                alt="white dot" />
                        </div>
                        <p>{{ orderDetail.outlet ? orderDetail.outlet.name : '-' }}</p>
                    </div>
                </div>
            </section>
            <section class="order_details__address" v-if="orderDetail.is_pickup == 0">
                <label>{{ $t('order_details.delivered_to') }} {{ orderDetail.label ?? '-' }}</label>
                <span>{{ orderDetail.shipping_address_line_1 }}</span>
            </section>
            <section class="order_details__items">
                <label>{{ $t('order_confirmation.your_order') }}</label>
                <div class="order_confirmation__item" v-for="(item, index) in orderDetail.items" :key="index">
                    <div class="order_confirmation__item-main">
                        <div class="order_confirmation__item--quantity">
                            <span>x{{ item.quantity }}</span>
                        </div>
                        <div class="order_confirmation__item--thumbnail">
                            <img
                                :src="item.product_image != null ? item.product_image : require('/public/images/sample/no-image.png')">
                        </div>
                        <div class="order_confirmation__item--details">
                            <label class="order_confirmation__item--details_name">{{ item.product_description_name
                                }}</label>
                            <div v-if="item.product_type == 'configurable'">
                                <span>{{ item.variantItem }}</span>
                            </div>
                            <div v-if="item.product_type == 'configurable_bundle'">
                                <span v-for="(bundle, index) in item.bundle_items" :key="index">
                                    {{ '- ' + bundle.name + ' x' + bundle.bundle_quantity }}<br />
                                </span>
                            </div>
                            <div v-if="item.product_type == 'bundle'">
                                <span v-for="(bundle, index) in item.bundle_items" :key="index">
                                    {{ '- ' + bundle.product_description_name + ' x' + bundle.bundle_quantity }}<br />
                                </span>
                            </div>
                        </div>
                        <span class="order_confirmation__item--details_price">{{ item.total.toFixed(2) }}</span>
                        <div class="order_details__item--details_last">
                            <span v-if="item.remark != null" class="order_details__item--details_remark"
                                @click="viewRemark(item)">{{ $t('order_details.view_remark') }}</span>
                        </div>
                    </div>

                    <div class="order_confirmation__item-addon" v-if="item.addons != ''">
                        <h2 class="">{{ $t('add_on') }}</h2>
                        <div class="order_confirmation__item" v-for="(addon, index) in item.addons" :key="index">
                            <div class="order_confirmation__item-main">
                                <div class="order_confirmation__item--quantity">
                                    <span>x{{ addon.quantity }}</span>
                                </div>
                                <div class="order_confirmation__item--thumbnail">
                                    <img
                                        :src="addon.thumbnail_image != null ? addon.thumbnail_image : require('/public/images/sample/no-image.png')">
                                </div>
                                <div class="order_confirmation__item--details">
                                    <label class="order_confirmation__item--details_name">{{ addon.product_name
                                        }}</label>
                                    <span class="order_confirmation__item--details_desc" v-for="bundleItem in addon.bundle_items" :key="bundleItem.id" >- {{ bundleItem.name }}<br/></span>
                                    <span class="order_confirmation__item--details_desc" v-if="addon.product_type == 'configurable'" >{{ addon.selected_variant.reduce((carry, item) => carry + (carry.trim() ? ', ' : '') + item.variant_description_name, '')  }}<br/></span>
                                </div>
                                <div class="order_confirmation__item-addon-price">
                                    <span class="order_confirmation__item-addon-price-discounted">{{ addon.total.toFixed(2)
                                        }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="order_details__remark" v-if="orderDetail.remark != ''">
                <label>{{ $t('order_confirmation.remarks') }}</label>
                <div class="order_details__remark--input">
                    {{ orderDetail.remark }}
                </div>
            </section>
            <section class="order_details__promocode" v-if="false">
                <label>{{ $t('order_confirmation.promo_code') }}</label>
                <div class="order_details__promocode_box">
                    Discount RM3 Birthday Voucher
                </div>
            </section>
            <section class="order_details__payment">
                <label>{{ $t('order_details.payment_info') }}</label>
                <table class="table">
                    <tr>
                        <td class="td--label">{{ $t('order_confirmation.payment_method') }}</td>
                        <td class="td--value td--to-right">{{ orderDetail.displayPaymentMethod }}</td>
                    </tr>
                    <tr>
                        <td class="td--label">{{ $t('order_details.payment_ref') }}</td>
                        <td class="td--value td--to-right">{{ orderDetail.payment_ref != null ? orderDetail.payment_ref
                : '-' }}</td>
                    </tr>
                </table>
            </section>
            <section class="order_details__summary">
                <table class="table">
                    <tr class="bold">
                        <td class="td--label">{{ $t('order_confirmation.summary') }}</td>
                    </tr>
                    <tr>
                        <td class="td--label">{{ $t('order_confirmation.acc_incl_sst') }}</td>
                        <td class="td--value td--to-right">{{ orderDetail.display_subtotal }}</td>
                    </tr>
                    <tr>
                        <td class="td--label">{{ $t('order_confirmation.subtotal') }}</td>
                        <td class="td--value td--to-right">{{ orderDetail.display_subtotal }}</td>
                    </tr>
                    <tr v-if="false">
                        <td class="td--label">推销员</td>
                        <td class="td--value td--to-right">RM 165.00</td>
                    </tr>
                    <tr v-if="orderDetail.is_pickup == '0'">
                        <td class="td--label">{{ $t('order_confirmation.delivery_fee') }}</td>
                        <td class="td--value td--to-right">{{ orderDetail.display_shipping_fee }}</td>
                    </tr>
                    <tr class="bold">
                        <td class="td--label">{{ $t('order_confirmation.grand_total') }}</td>
                        <td class="td--value td--to-right">{{ orderDetail.display_total }}</td>
                    </tr>
                    <tr>
                        <td class="td--label">{{ $t('order_confirmation.1602_exp_earned') }}</td>
                        <td class="td--value td--to-right">{{ orderDetail.earned_points }} exp</td>
                    </tr>
                    <tr>
                        <td class="td--label">{{ $t('order_confirmation.1602_points_earned') }}</td>
                        <td class="td--value td--to-right">{{ orderDetail.earned_points }} pts</td>
                    </tr>
                    <tr v-for="coupon in orderDetail.order_totals.applied_coupon" :key="coupon.id">
                        <td class="td--label"><img src="/images/icons/loyaltycard.png"/>{{ coupon.name }}</td>
                        <td class="td--value td--to-right coupon" v-if="coupon.coupon_discount_type == 'gift'">{{ $t("checkout_detail.order_summary.voucher.free") }}</td>
                        <td class="td--value td--to-right coupon" v-else>- {{ coupon.display_value }}</td>
                    </tr>
                    <tr v-for="promo in orderDetail.order_totals.applied_promocode" :key="promo.id">
                        <td class="td--label"><img src="/images/icons/movieticket.png"/>{{ promo.name }}</td>
                        <td class="td--value td--to-right coupon">- {{ promo.display_value }}</td>
                    </tr>
                </table>
            </section>
            <section class="order_details__action" v-if='false'>
                <div class="order_details__action_summary">
                    <span>{{ $t('order_confirmation.total') }}</span>
                    <label>{{ orderDetail.display_total }}</label>
                </div>
                <div class="order_details__action_button">
                    <button v-if="orderDetail.status != 'cancelled' && orderDetail.status != 'failed'"
                        @click="reviewOrder()" class="btn btn-secondary">{{ $t('order_details.rate') }}</button>
                    <button class="btn btn-primary" @click="reorderItem(orderDetail)">{{ $t('order_details.add_to_cart')
                        }}</button>
                </div>
            </section>
        </div>

        <div class="page page-order-preview" v-else>
            <div class="link-back" @click="backOrderTab()"><img src="/images/icons/back.png"></div>
            <div class="link-help" @click="openWhatsapp()">{{ $t('help') }}</div>

            <div class="page-order-preview-box">
                <p class="order_details__action_info-id">#{{ orderDetail.id }}</p>
                <h1
                    v-if="['pending', 'successful', 'processing', 'delivering', 'pending_collection'].includes(orderDetail.status)">
                    {{ $t(`order_details.orderStatus.${orderDetail.status}`) }}
                </h1>

                <p class="page-order-preview-box-est">{{ $t('estimate_time') }} <span>{{
                (orderDetail.estimated_time_from ? orderDetail.estimated_time_from : '00.00')
                + ' - ' +
                (orderDetail.estimated_time_to ? orderDetail.estimated_time_to : '00.00') }}</span></p>
                <div class="page-order-preview-box-status">
                    <div class="page-order-preview-box-status-name">
                        <img :class="{ 'page-order-preview-box-status-name-img': orderDetail.status === 'successful' || orderDetail.status === 'processing' || orderDetail.status === 'delivering' || orderDetail.status === 'pending_collection' }" src="/images/icons/order_status/warehouse.png" />
                        <p :class="{'page-order-preview-box-status-name-active' : orderDetail.status === 'successful' || orderDetail.status === 'processing' || orderDetail.status === 'delivering' || orderDetail.status === 'pending_collection',
                                    'page-order-preview-box-status-name-inactive' : orderDetail.status !== 'successful'
                        }">{{ $t('order_details.progress.successful')
                            }}</p>
                    </div>
                    <div class="progress-container">
                        <div class="progress-bar " :class="{
                'progress-bar-active': orderDetail.status === 'successful',
                'progress-bar-inactive': orderDetail.status !== 'successful' && orderDetail.status !== 'processing' && orderDetail.status !== 'delivering'
            }">
                        </div>
                    </div>
                    <div class="page-order-preview-box-status-name">
                        <img :class="{ 'page-order-preview-box-status-name-img': orderDetail.status === 'processing' || orderDetail.status === 'delivering' || orderDetail.status === 'pending_collection' }"
                            :src="orderDetail.is_pickup == 1 ? require('/public/images/icons/order_status/kitchen_preparing.png') : require('/public/images/icons/order_status/processing.png')" />
                        <p
                            :class="{ 'page-order-preview-box-status-name-active': orderDetail.status === 'processing' || orderDetail.status === 'delivering' || orderDetail.status === 'pending_collection' }">
                            {{ orderDetail.is_pickup == 1 ? $t('order_details.progress.kitchen_preparing') :
                $t('order_details.progress.processing') }}</p>
                    </div>
                    <div class="progress-container">
                        <div class="progress-bar" :class="{
                'progress-bar-active': orderDetail.status === 'processing',
                'progress-bar-inactive': orderDetail.status !== 'processing' && orderDetail.status !== 'delivering'
            }">
                        </div>
                    </div>
                    <div class="page-order-preview-box-status-name">
                        <img v-if="orderDetail.is_pickup == 0"
                            :class="{ 'page-order-preview-box-status-name-img': (orderDetail.status === 'delivering') }"
                            src="/images/icons/order_status/delivering.png" />
                        <img v-if="orderDetail.is_pickup == 1"
                            :class="{ 'page-order-preview-box-status-name-img': (orderDetail.status === 'pending_collection') }"
                            src="/images/icons/order_status/pending_collection.png" />
                        <p v-if="orderDetail.is_pickup == 0"
                            :class="{ 'page-order-preview-box-status-name-active': orderDetail.status === 'delivering' }">
                            {{ $t('order_details.progress.out_of_delivery') }}</p>
                        <p v-if="orderDetail.is_pickup == 1"
                            :class="{ 'page-order-preview-box-status-name-active': orderDetail.status === 'pending_collection' }">
                            {{ $t('order_details.progress.pending_collection') }}</p>
                    </div>
                    <div class="progress-container">
                        <div class="progress-bar" :class="{
                'progress-bar-active': orderDetail.status === 'delivering' || orderDetail.status === 'pending_collection',
                'progress-bar-inactive': orderDetail.status !== 'delivering' && orderDetail.status !== 'pending_collection'

            }"></div>
                    </div>
                    <div class="page-order-preview-box-status-name">
                        <img src="/images/icons/order_status/delivered.png" />
                        <p :class="{ 'page-order-preview-box-status-name-active': orderDetail.status === 'delivered' }">
                            {{ orderDetail.is_pickup == 1 ? $t('order_details.progress.collected') :
                $t('order_details.progress.delivered') }}</p>
                    </div>
                </div>

                <div class="page-order-preview-box-order">
                    <!-- <h2>Info</h2> -->
                    <div class="order_confirmation__item" v-for="(item, index) in orderDetail.items" :key="index">
                        <div class="order_confirmation__item-main">
                            <div class="order_confirmation__item--quantity">
                                <span>x{{ item.quantity }}</span>
                            </div>
                            <div class="order_confirmation__item--thumbnail">
                                <img
                                    :src="item.product_image != null ? item.product_image : require('/public/images/sample/no-image.png')">
                            </div>
                            <div class="order_confirmation__item--details">
                                <label class="order_confirmation__item--details_name">{{ item.product_description_name
                                    }}</label>
                                <div v-if="item.product_type == 'configurable'">
                                    <span>{{ item.variantItem }}</span>
                                </div>
                                <div v-if="item.product_type == 'configurable_bundle'">
                                    <span v-for="(bundle, index) in item.bundle_items" :key="index">
                                        {{ '- ' + bundle.name + ' x' + bundle.bundle_quantity }}<br />
                                    </span>
                                </div>
                                <div v-if="item.product_type == 'bundle'">
                                    <span v-for="(bundle, index) in item.bundle_items" :key="index">
                                        {{ '- ' + bundle.product_description_name + ' x' + bundle.bundle_quantity }}<br />
                                    </span>
                                </div>
                            </div>
                            <span class="order_confirmation__item--details_price">{{ item.total.toFixed(2) }}</span>
                            <div class="order_details__item--details_last">
                                <span v-if="item.remark != null" class="order_details__item--details_remark"
                                    @click="viewRemark(item)">{{ $t('order_details.view_remark') }}</span>
                            </div>
                        </div>

                        <div class="order_confirmation__item-addon" v-if="item.addons != ''">
                            <h2 class="">{{ $t('add_on') }}</h2>
                            <div class="order_confirmation__item" v-for="(addon, index) in item.addons" :key="index">
                                <div class="order_confirmation__item-main">
                                    <div class="order_confirmation__item--quantity">
                                        <span>x{{ addon.quantity }}</span>
                                    </div>
                                    <div class="order_confirmation__item--thumbnail">
                                        <img
                                            :src="addon.thumbnail_image != null ? addon.thumbnail_image : require('/public/images/sample/no-image.png')">
                                    </div>
                                    <div class="order_confirmation__item--details">
                                        <label class="order_confirmation__item--details_name">{{ addon.product_name
                                            }}</label>
                                    <span class="order_confirmation__item--details_desc" v-for="bundleItem in addon.bundle_items" :key="bundleItem.id" >- {{ bundleItem.name }}<br/></span>
                                    <span class="order_confirmation__item--details_desc" v-if="addon.product_type == 'configurable'" >{{ addon.selected_variant.reduce((carry, item) => carry + (carry.trim() ? ', ' : '') + item.variant_description_name, '')  }}<br/></span>
                                    </div>
                                    <div class="order_confirmation__item-addon-price">
                                        <span class="order_confirmation__item-addon-price-discounted">{{
                addon.total.toFixed(2) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="page-order-preview-box-location">
                    <h2>{{ $t('order_details.location') }}</h2>
                    <div class="page-order-preview-box-location-outlet">
                        <div class="location_icon">
                            <img src="/images/icons/black_circle.png" alt="black circle" />
                            <img class="location_icon-center" src="/images/icons/white_dot.png"
                                alt="white dot" />
                        </div>
                        <p>{{ orderDetail.outlet ? orderDetail.outlet.name : '-' }}</p>
                    </div>
                    <div class="page-order-preview-box-location-divider" v-if="orderDetail.is_pickup == 0">
                        <img src="/images/icons/double_dot.png" alt="double dot" />
                    </div>
                    <div class="page-order-preview-box-location-user" v-if="orderDetail.is_pickup == 0">
                        <img src="/images/icons/map_pin.png" alt="map pin" />
                        <p>{{ orderDetail.billing_address_line_1 }}</p>
                    </div>
                </div>

                <section class="page-order-preview-box_remark" v-if="orderDetail.remark != ''">
                    <h3>{{ $t('order_confirmation.remarks') }}</h3>
                    <div class="page-order-preview-box_remark-input">
                        {{ orderDetail.remark }}
                    </div>
                </section>

                <div class="page-order-preview-box-detail">
                    <h3>{{ $t('order_details.info') }}</h3>
                    <div class="page-order-preview-box-detail-price">
                        <div class="page-order-preview-box-detail-price-subtotal">
                            <label>{{ $t('order_confirmation.subtotal') }}</label>
                            <p>{{ orderDetail.display_subtotal }}</p>
                        </div>
                        <div class="page-order-preview-box-detail-price-subtotal" v-if="orderDetail.is_pickup == '0'">
                            <label>{{ $t('order_confirmation.delivery_fee') }}</label>
                            <p>{{ orderDetail.display_shipping_fee }}</p>
                        </div>
                        <div class="page-order-preview-box-detail-price-subtotal">
                            <label>{{ $t('order_confirmation.grand_total') }}</label>
                            <p>{{ orderDetail.display_total }}</p>
                        </div>
                        <div class="page-order-preview-box-detail-price-sst price-field">
                            <label>6% SST</label>
                            <p>6% SST</p>
                        </div>
                        <div class="page-order-preview-box-detail-price-exp price-field">
                            <label>{{ $t('order_confirmation.1602_exp_earned') }}</label>
                            <p>{{ orderDetail.earned_points }} exp</p>
                        </div>
                        <div class="page-order-preview-box-detail-price-point price-field">
                            <label>{{ $t('order_confirmation.1602_points_earned') }}</label>
                            <p>{{ orderDetail.earned_points }} pts</p>
                        </div>
                        <div v-if="orderDetail.order_totals">
                            <div class="page-order-preview-box-detail-price-subtotal" v-for="coupon in orderDetail.order_totals.applied_coupon" :key="coupon.id">
                                <label><img src="/images/icons/loyaltycard.png"/>{{ coupon.name }}</label>
                                <p class="coupon" v-if="coupon.coupon_discount_type != 'gift'">- {{ coupon.display_value }}</p>
                                <p class="coupon" v-else>{{ $t("checkout_detail.order_summary.voucher.free") }}</p>
                            </div>
                            <div class="page-order-preview-box-detail-price-subtotal" v-for="promo in orderDetail.order_totals.applied_promocode" :key="promo.id">
                                <label><img src="/images/icons/movieticket.png"/>{{ promo.name }}</label>
                                <p class="coupon">- {{ promo.display_value }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <PopupRemark v-if="popupData != null" :popupData="popupData" @close="closePopup()" />
    <ReviewView v-if="orderData != null" :popupData="orderData" @close="closeReview()" />
    <Loader_component :loading="showLoader" :isFullPage="true" />
</template>


<script>
import PopupRemark from '@/components/order/PopupRemark.vue';
import ReviewView from '@/views/ReviewView.vue';
import { getAPI, postAPI } from '@/js/general';
import { displayPrice } from '@/js/app';
import Loader_component from "@/include/loader_component.vue";

export default {
    name: 'OrderDetails',
    data() {
        return {
            orderData: null,
            popupData: null,
            orderHashId: '',
            orderDetail: [],
            paymentMethods: this.$store.state.payment_methods,
            apiTriggered: false,
            timer: null,
            showLoader: false,
            progress: 0,
            attemptCount: 0,
            maxAttempts: 10,
        }
    },
    created() {
        this.$store.state.showFooter = false;
    },
    components: {
        PopupRemark,
        ReviewView,
        Loader_component
    },
    computed: {
        progressStyle() {
            return {
                width: `${this.progress}%`,
                backgroundPosition: `${this.progress}% 0`,
            };
        },
    },
    beforeUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
    async mounted() {
        this.showLoader = true;
        // this.incrementProgress();

        if (this.$route.query.order_id !== undefined) {
            this.orderHashId = this.$route.query.order_id;
            await this.getOrderDetail();
            if (this.apiTriggered) {
                this.startOrderDetailInterval();
            }

        } else {
            this.$router.push(`${this.$store.state.multiLanguage}` + 'history');
        }

        this.showLoader = false;
    },
    methods: {
        // incrementProgress() {
        //     setInterval(() => {
        //         if (this.progress >= 100) {
        //             // clearInterval(interval);
        //             this.progress = 0;
        //         } else {
        //             this.progress += 1;
        //         }
        //     }, 100);
        // },
        openWhatsapp() {
            window.location = "https://wa.me/60162931602";
        },
        async clearCart() {
            var clearResponse = await getAPI('/cart/clear');
            if (clearResponse.code == 'success') {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('success_clear_cart'),
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            }
        },
        async reorderItem(order) {
            this.$swal.fire({
                position: "top",
                text: this.$t('reorder.text'),
                icon: 'error',
                showCancelButton: true,
                confirmButtonText: this.$t('reorder.yes'),
                cancelButtonText: this.$t('reorder.no'),
            }).then(async (result) => {
                if (result.isConfirmed) {

                    var UserUpdateOrderResponse = await postAPI("/user/order/update", {
                        order_id: order.id,
                        action: 'reorder',
                        outlet_id: order.outlet_id,
                        // language_id: this.$store.state.language_id[this.$i18n.locale],
                    });

                    if (UserUpdateOrderResponse.code == 'success') {
                        localStorage.order = order.is_pickup == 1 ? 'pickup' : 'delivery';
                        localStorage.outlet_id = order.outlet_id;
                        this.$router.push(`${this.$store.state.multiLanguage}` + 'order-confirmation');
                    } else {
                        this.$swal.fire({
                            toast: true,
                            position: "top",
                            text: UserUpdateOrderResponse.message,
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 2000,
                            didOpen: (toast) => {
                                toast.addEventListener('click', () => {
                                    this.$swal.close();
                                });
                            }
                        });
                    }
                }
            });
        },
        backOrderTab() {
            this.$router.push(`${this.$store.state.multiLanguage}` + 'history');
        },
        startOrderDetailInterval() {
            this.timer = setInterval(async () => {
                this.attemptCount++;
                if (this.attemptCount <= this.maxAttempts) {
                    await this.getOrderDetail();
                } else {
                    clearInterval(this.timer);
                    this.attemptCount = 0;
                }
            }, 20000); 
        },
        async getOrderDetail() {
            try {
                const orderResponse = await getAPI('/order/detail?order_id=' + this.orderHashId);

                if (orderResponse.code === "success") {
                    this.orderDetail = orderResponse.data;
                    if (this.orderDetail.status === 'delivered' || this.orderDetail.status === 'closed' || this.orderDetail.status === 'cancelled' || this.orderDetail.status === 'failed' || this.orderDetail.status === 'collected') {
                        this.apiTriggered = false;
                        clearInterval(this.timer);
                    } else {
                        this.apiTriggered = true;
                    }

                    this.orderDetail.display_total = displayPrice(this.orderDetail.paid_amount);
                    this.orderDetail.displayPaymentMethod = this.paymentMethods.find(payment => payment.id === this.orderDetail.payment_method_id)?.name || '';
                    this.orderDetail.display_shipping_fee = displayPrice(this.orderDetail.shipping_fee);
                    this.orderDetail.display_subtotal = displayPrice(this.orderDetail.order_totals.subtotal.value);
                    if (this.orderDetail.order_totals.applied_coupon) {
                        this.orderDetail.order_totals.applied_coupon.forEach((coupon) => {
                            coupon.display_value = displayPrice(coupon.value);
                        });
                    }

                    if (this.orderDetail.order_totals.applied_promocode) {
                        this.orderDetail.order_totals.applied_promocode.forEach((promo) => {
                            promo.display_value = displayPrice(promo.value);
                        });
                    }

                    this.orderDetail.items.forEach(product => {

                        product.display_price = displayPrice(product.price);
                        product.variantItem = '';

                        if (product.product_type === 'configurable') {
                            const selectedVariant = product.selected_variant.map(variant => variant.variant_description_name);
                            product.variantItem = selectedVariant.join(", ");
                        }

                        product.addons.forEach((addon) => {
                            addon.display_total = displayPrice(addon.total);
                        })
                    });

                    if (!this.orderDetail) {
                        this.$router.push(`${this.$store.state.multiLanguage}menu`);
                    }
                } else {
                    this.$router.push(`${this.$store.state.multiLanguage}menu`);
                }
            } catch (error) {
                console.error('Error fetching order details:', error);
                this.$router.push(`${this.$store.state.multiLanguage}menu`);
            }
        },
        closePopup() {
            this.popupData = null;
        },
        closeReview() {
            this.orderData = null;
        },
        reviewOrder() {
            this.orderData = this.orderDetail;
        },
        viewRemark(item) {
            this.popupData = {
                remark: item.remark,
                label: item.name,
            };
        }
    }
}

</script>

<style scoped>
.progress-container {
    width: 80px;
    background-color: #ddd;
    border-radius: 25px;
    overflow: hidden;
    margin: 20px auto;
}

.progress-bar-active {
    animation: moveGradient 3s linear infinite;
}

.progress-bar-inactive {
    background: #03163433 !important;
}

.progress-bar {
    height: 3px;
    width: 100%;
    background: linear-gradient(90deg, #000916 0%, #CDCDD6 100%);
    background-size: 200% 100%;
    border-radius: 25px;
}

@keyframes moveGradient {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: 0% 0;
    }
}
</style>
<template>
	<div class="popup" id="popup">
		<div class="popup__box">
			<div class="popup__header">
				<label>{{ voucherData.caption }} {{ !redeem ? ('(' +
					parseInt(voucherData.points_needed).toLocaleString() + $t('points') + ')') : '' }}</label>
				<div class="popup__close" @click="closepopup()"><img src="/images/icons/close.png"></div>
			</div>
			<div class="popup__body">
				<div class="coupon-container">
					<div class="inner-card">
						<div class="inner-card-left">
							<img :src="voucherData.image != null ? voucherData.image : '/images/logo.png'"
								class="inner-card-left-img" />
						</div>
						<div class="inner-card-right">
							<p class="inner-card-right-caption">{{ voucherData.caption }}</p>
							<p class="inner-card-right-points" v-if="!redeem">{{
					parseInt(voucherData.points_needed).toLocaleString()
				}}
								<span>{{ $t('points') }}</span>
							</p>
						</div>
					</div>
				</div>
				<div v-if="!redeem">
					<hr><label>{{ voucherData.caption }} ({{ parseInt(voucherData.points_needed).toLocaleString() }} {{
					$t('points') }})</label><br>{{ parseInt(voucherData.points_needed).toLocaleString() }} {{
					$t('points') }}<br>
				</div>
				<b v-if="voucherData.discount_type == 'game' && voucherData.hash_id && voucherData.hash_id != null && showQrScanner">QR Code</b><br>
				<qrcode-stream @detect="onDetect" @camera-on="onCameraOn" @camera-off="onCameraOff" v-if="showQrScanner">
					<div v-show="showScanConfirmation" class="scan-confirmation">
						<img src="../../../public/images/icons/checkmark.svg" alt="Checkmark" width="128"/>
					</div>
				</qrcode-stream>
				<!-- <hr><b>{{ $t('rewards.description') }}</b><br>{{ $t('rewards.enjoy') }} {{ voucherData.caption }} {{
					$t('rewards.when_purchase') }}<br><br> -->
				<hr><b>{{ $t('rewards.description') }}</b><br> {{ voucherData.detail }} 
				<!-- {{ $t('rewards.when_purchase') }} -->
				<br><br>
				<div v-if="voucherData.terms_and_condition != null">
					<b>{{ $t('rewards.terms') }}</b><br>
					<div v-html="voucherData.terms_and_condition">
					</div>
					<br>
				</div>
				<!-- <div v-if="voucherData.key && voucherData.key != null"> -->
					<b v-if="voucherData.discount_type == 'game_reward' && voucherData.hash_id && voucherData.hash_id != null">{{ $t('rewards.qr') }}</b><br>
					<qrcode-vue v-if="voucherData.discount_type == 'game_reward' && voucherData.hash_id && voucherData.hash_id != null" :value="voucherData.hash_id" :size="size" level="H" class="canvas" margin="3" RenderAs="svg"/>
					<br>
				<!-- </div> -->
			</div>
			<div class="popup__action" v-if="!redeem">
				<button class="btn" id="popup__action--positive" @click="redeemVoucher()">{{ $t('rewards.redeem')
					}}</button>
			</div>
			<div class="popup__action" v-if="voucherData.discount_type == 'game' && voucherData.hash_id && voucherData.hash_id != null">
				<button class="btn" id="popup__action--positive" @click="openQrScanner" v-if="!showQrScanner">{{ $t('rewards.scan') }}</button>
				<button class="btn" id="popup__action--positive" @click="closeQrScanner" v-if="showQrScanner">{{ $t('rewards.cancel') }}</button>
			</div>
		</div>
	</div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
	name: 'PopupVoucher',
	props: {
		popupData: {
			type: Object,
			default: () => ({})
		},
		rewardType: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			voucherData: '',
			redeem: false,
			size: 0, 
			showQrScanner: false,
			paused: false,
			result: '',
			showScanConfirmation: false
		}
	},
	created() {

	},
	mounted() {

		if (this.popupData != null) {
			document.getElementById('popup').style.display = 'block';
			this.voucherData = this.popupData;
		}
		this.redeem = this.rewardType == 'my_rewards';

		const popupBody = document.querySelector('.popup__body');
		if (popupBody) {
			this.size = popupBody.clientWidth - (popupBody.clientWidth * 0.1);
		}
	},
	methods: {
		closepopup() {
			this.$emit('close');
		},
		redeemVoucher() {
			this.$emit('redeem');
		},
		openQrScanner() {
			this.showQrScanner = true;
		},
		closeQrScanner() {
			this.showQrScanner = false;
		},
		onCameraOn() {
			this.showScanConfirmation = false
		},

		onCameraOff() {
			this.showScanConfirmation = true
		},

		async onDetect(detectedCodes) {
			this.result = detectedCodes.map((code) => JSON.parse(code.rawValue));
			// this.paused = true
			this.decodeQr();
			// this.paused = false
		},

		decodeQr() {
			this.$emit('decodeQr', this.result[0].hash, this.result[0].device_id, this.voucherData.id);
		},
	},
	components: {
		QrcodeVue,
		QrcodeStream,
    },
}
</script>

<style scoped>
.popup__box {
	bottom: 0;
	animation: slideIn 0.5s ease-in-out forwards;
}
</style>
//1602 Miniapp Api
const baseApiURL = "https://api.1602craftbeer.com";

const vapidKey = 'BJILnr2YMgHfpkvaJOPL-W7Xy1Mre-P738neBysfVo99zoGC_1DY2WZYv_jfyynXDLGvqnWSn4NZGMFO-CLo590';

const firebaseConfig = {
  apiKey: "AIzaSyA_7Z-TKVn8_3ZHx1qLMoe9gQXyjxYx15Y",
  authDomain: "project-3934941186800853820.firebaseapp.com",
  projectId: "project-3934941186800853820",
  storageBucket: "project-3934941186800853820.appspot.com",
  messagingSenderId: "53520629326",
  appId: "1:53520629326:web:5ab28d443f8b137cac0d1f",
  measurementId: "G-7WDWFWY4TR"
};


export { baseApiURL, vapidKey, firebaseConfig};

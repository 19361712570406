<template>
    <div class="frame">
        <div class="page page-menu">
            <section class="menu_search">
                <div class="menu_search__location">
                    <div class="menu_search__location_address" :class="{ selected: deliveryTypeSelected }"
                        @click="selectDeliveryAddress()">
                        {{ this.addressSelected && this.addressSelected != '' ?
                        this.addressSelected.label : '-' }}
                    </div>
                    <div class="menu_search__location_pickup" :class="{ selected: pickupTypeSelected }"
                        @click="selectPickupStore()">{{ display_selected_outlet != '' ? display_selected_outlet : '-' }}
                    </div>
                    <div class="menu_search__type">
                        <div class="menu_search__type--delivery" :class="{ selected: deliveryTypeSelected }"
                            @click="selectDelivery()">
                            {{ $t('delivery') }}
                        </div>
                        <div class="menu_search__type--pickup" :class="{ selected: pickupTypeSelected }"
                            @click="selectPickup()">
                            {{ $t('pickup') }}
                        </div>
                    </div>
                </div>
            </section>
            <section class="menu__body">
                <div class="menu__categories">
                    <div class="menu__category " v-for="category in this.$store.state.categories" :key="category.id"
                        :class="{ 'selected': selectedCategory === category.slug }"
                        @click="scrollToSection(category.slug)">
                        <img
                            :src="category.thumbnail_image != null ? category.thumbnail_image : require('/public/images/sample/no-image.png')">
                        <span>{{ category.name }}</span>
                    </div>
                    <div class="menu__category new_product"
                        :class="{ 'selected': selectedCategory === 'new' }"
                        @click="scrollToSection('new')">
                        <img v-if="selectedCategory == 'new'"
                            :src="require('/public/images/sample/new_selected.png')">
                        <img v-else
                            :src="require('/public/images/sample/new.png')">
                        <span>New</span>
                    </div>
                </div>

                <div class="menu__display" v-if="noProduct">
                    <p class="menu__display-no_product">{{ $t('no_products') }}</p>
                </div>
                <div class="menu__display" v-if="!noProduct" v-on:scroll="handleScroll">
                    <div v-for="category in this.$store.state.categories" :key="category.id"
                        :id="category.slug + '_section'" :ref="category.slug + '_section'">
                        <label>{{ category.name }}</label>
                        <div class="menu__products">
                            <div class="menu__product_wrap" v-if="category.products != ''">
                                <div class="menu__product_box" v-for="product in category.products" :key="product.id"
                                    @click="loadProduct(product.slug)">
                                    <div class="menu__product_image">
                                        <img
                                            :src="product.thumbnail_image != null ? product.thumbnail_image : require('/public/images/sample/no-image.png')">
                                        <div class="menu__product_add" v-if="product.quantityAdded > 0">
                                            x {{ product.quantityAdded }}
                                        </div>
                                        <div class="menu__product_add_icon" v-if="product.quantityAdded <= 0"></div>
                                    </div>
                                    <div class="menu__product_name">
                                        {{ product.name }}
                                    </div>
                                    <div class="menu__product_bottom">
                                        <div class="menu__product_price">
                                            {{ (product.product_type == 'configurable' || product.product_type == 'configurable_bundle') ? product.display_lowest_price : product.display_price }}
                                        </div>
                                        <div class="menu__product_special_price" v-if="product.actual_price && product.actual_price > 0 && product.actual_price > product.price">
                                            {{ product.display_actual_price }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="menu__product_wrap" v-if="category.products == ''">
                                <p>{{ $t('no_products') }}</p>
                            </div>
                        </div>
                    </div>
                      <!-- New Product -->
                      <div :id="'new_section'" :ref="'new_section'" >
                        <label>New</label>
                        <div class="menu__products">
                            <div class="menu__product_wrap">
                                <div class="menu__product_box">
                                    <div class="menu__product_image new_product">
                                        <img
                                            :src="require('/public/images/sample/lager.png')">
                                        <!-- <div class="menu__product_add" v-if="product.quantityAdded > 0">
                                            x {{ product.quantityAdded }}
                                        </div>
                                        <div class="menu__product_add_icon" v-if="product.quantityAdded <= 0"></div> -->
                                        <!-- <div class="menu__product_description">
                                            Coming Soon
                                        </div> -->
                                    </div>
                                    <div class="menu__product_name">
                                        Lager 330ml Bottle
                                    </div>
                                    <!-- <div class="menu__product_price">
                                        RM 100.00
                                    </div> -->
                                </div>
                                <div class="menu__product_box">
                                    <div class="menu__product_image">
                                        <img
                                            :src="require('/public/images/sample/pale_ale.png')">
                                    </div>
                                    <div class="menu__product_name">
                                        Pale Ale 330ml Bottle
                                    </div>
                                </div>
                                <div class="menu__product_box">
                                    <div class="menu__product_image">
                                        <img
                                            :src="require('/public/images/sample/extra_dark.png')">
                                    </div>
                                    <div class="menu__product_name">
                                        Extra Dark 330ml Bottle
                                    </div>
                                </div>
                                <div class="menu__product_box">
                                    <div class="menu__product_image">
                                        <img
                                            :src="require('/public/images/sample/lager.png')">
                                    </div>
                                    <div class="menu__product_name">
                                        Lager 660ml Bottle
                                    </div>
                                </div>
                                <div class="menu__product_box">
                                    <div class="menu__product_image">
                                        <img
                                            :src="require('/public/images/sample/pale_ale.png')">
                                    </div>
                                    <div class="menu__product_name">
                                        Pale Ale 660ml Bottle
                                    </div>
                                </div>
                                <div class="menu__product_box">
                                    <div class="menu__product_image">
                                        <img
                                            :src="require('/public/images/sample/extra_dark.png')">
                                    </div>
                                    <div class="menu__product_name">
                                        Extra Dark 660ml Bottle
                                    </div>
                                </div>
                            </div>
                            <div class="menu__product_wrap" v-if="false">
                                <p>{{ $t('no_products') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="menu__cart hascart" v-if="itemCount > 0">
                <label>{{ itemCount + ' ' + $t('item') }} - {{ cartItem.display_total }}</label>
                <button @click="checkFaqAccept()">{{ $t('view_cart')
                    }}</button>
            </section>
        </div>
    </div>
    <popup v-if="popupData != null" :popupData="popupData" @close="closePopup()" @changeOutlet="changeOutlet($event)"
        @changeAddress="changeAddress()" @addressRemoved="addressRemoved()" @clearCart="clearCart()" />
    <FaqPopup v-if="triggerFaqDialog != null" :popupData="triggerFaqDialog" @close="closeFaqDialog()" />
    <MenuProduct v-if="productPopup != null" :popupData="productPopup" @close="closeProduct()" />
    <Loader_component :loading="showLoader" :isFullPage="true" />
</template>

<script>
import FaqPopup from '@/components/menu/FaqPopup.vue';
import MenuProduct from './MenuProduct.vue';
import popup from '@/components/menu/PopupAddress.vue'
import Loader_component from "@/include/loader_component.vue";
import { getAPI, getGuestID } from '@/js/general';
import { getCart } from '@/js/cart';

export default {
    name: 'MenuView',
    data() {
        return {
            productPopup: null,
            selectedCategory: null,
            observer: null,
            deliveryTypeSelected: true,
            pickupTypeSelected: false,
            addressPicked: '',
            label: '',
            popupData: null,
            showLoader: false,
            itemCount: 0,
            cartItem: [],

            activeSection: null,
            userAddressLatitude: '',
            userAddresslongitude: '',
            currentUserLatitude: '',
            currentUserLongitude: '',
            display_selected_outlet: '',
            outletSet: false,
            userLocationSet: false,
            addressSelected: null,

            noProduct: false,

            triggerFaqDialog: null,

            outlets: [],
            deniedLocationError: null,
        };
    },
    async created() {
    this.showLoader = true;
        this.$store.state.showFooter = true;
        await this.getLocationAndOutlets();
        this.userLocationSet = true;

        if (this.deniedLocationError == null) {
            if (localStorage.order == 'pickup') {
                // 
                this.selectPickup();
            } else {
                this.selectDeliveryAddress();

                this.addressSelected = localStorage.addressSelected ? JSON.parse(localStorage.addressSelected) : null;
                if (this.addressSelected) {
                    this.userAddressLatitude = this.addressSelected.latitude;
                    this.userAddressLongitude = this.addressSelected.longitude;
                    this.userLocationSet = true;
                }
                this.selectDelivery();
            }
        }
        this.showLoader = false;

    },
    components: {
        popup,
        Loader_component,
        MenuProduct,
        FaqPopup
    },
    mounted() {
        if (this.$store.state.categories != null) {
            this.categories = this.$store.state.categories;
            this.selectedCategory = this.$store.state.categories[0].slug;
            // this.selectedCategory = 'new';
        }

    },
    methods: {
        locationDenied() {
            this.$swal.fire({
                toast: true,
                position: "top",
                text: this.$t('location_denied'),
                icon: 'error',
                showConfirmButton: false,
                timer: 2000,
                didOpen: (toast) => {
                    toast.addEventListener('click', () => {
                        this.$swal.close();
                    });
                }
            });
        },
        checkFaqAccept() {
            if (localStorage.acceptCheckout && localStorage.acceptCheckout == 'true') {
                this.$router.push(this.$store.state.multiLanguage + 'order-confirmation');
            } else {
                this.triggerFaqDialog = 'true';
            }
        },
        closeFaqDialog() {
            this.triggerFaqDialog = null;
        },
        addressRemoved() {
            localStorage.addressSelected = "";
            this.addressSelected = localStorage.addressSelected ? JSON.parse(localStorage.addressSelected) : null;
        },
        async getPosition() {
            return new Promise((resolve, reject) => {
                const success = (position) => {
                    resolve(position.coords);
                };
                const error = (err) => {
                    reject(err);
                };
                navigator.geolocation.getCurrentPosition(success, error);
            });
        },
        async getLocationAndOutlets() {
            this.showLoader = true;
            try {
                const position = await this.getPosition();
                console.log('current position latitude:', position.latitude);
                console.log('current position longitude:', position.longitude);
                this.currentUserLatitude = position.latitude;
                this.currentUserLongitude = position.longitude;
                await this.getOutlets(true, false);
            } catch (error) {
                console.error('Error getting location:', error);
                this.deniedLocationError = error.message;

                if (this.deniedLocationError == 'User denied Geolocation') {
                    this.deniedLocationError = this.$t('location_denied');
                }

                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.deniedLocationError,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            }
            this.showLoader = false;
        },
        async changeAddress() {
            if (localStorage.addressSelected == '') {
                this.selectDeliveryAddress();
            } else {
                this.addressSelected = JSON.parse(localStorage.addressSelected);
            }

            if (this.addressSelected) {
                this.userAddressLatitude = this.addressSelected.latitude;
                this.userAddressLongitude = this.addressSelected.longitude;
                this.userLocationSet = true;
                await this.getOutlets(true);
                await this.getProducts();
                await this.getCart();
            } else {
                // this.getLocationAndOutlets();
                await this.getProducts();
                await this.getCart();
                this.userLocationSet = true;
            }
        },
        async changeOutlet(outlet_id) {
            localStorage.order = 'pickup';
            await this.getOutlets(false);
            // localStorage.addressSelected = '';
            localStorage.outlet_id = outlet_id;
            localStorage.outletSelected = JSON.stringify(this.outlets.find((outlet) => outlet.id == localStorage.outlet_id));
            this.$store.state.outlet_id = outlet_id;
            await this.getProducts();

            await this.getCart();
        },
        handleScroll() {
            const categories = this.$store.state.categories;
            if (categories) {
                for (const category of categories) {
                    const sectionId = category.slug + '_section';
                    const section = this.$refs[sectionId][0];
                    const sectionRect = section.getBoundingClientRect();

                    // Check if the section is in view
                    if (sectionRect.top <= window.innerHeight * 0.5 && sectionRect.bottom >= window.innerHeight * 0.5) {
                        // Section is in view
                        this.selectedCategory = category.slug;
                        break; // Exit the loop if one section is in view
                    }

                    const sectionId2 = 'new_section';
                    const section2 = this.$refs[sectionId2];
                    const sectionRect2 = section2.getBoundingClientRect();

                    // Check if the section is in view
                    if (sectionRect2.top <= window.innerHeight * 0.5 && sectionRect2.bottom >= window.innerHeight * 0.5) {
                        // Section is in view
                        this.selectedCategory = 'new';
                    }
                }
            }
        },
        // getAllProduct() {
        //     if (this.$store.state.categories.length > 0) {
        //         this.$store.state.categories.forEach(async (category) => {
        //             category.products = await this.getProducts(category.name);
        //         });
        //     }
        // },
        async getCartCount() {
            this.showLoader = true;
            var cartUser = {}
            if (!this.$store.state.isLoggedIn) {
                cartUser['guest_id'] = getGuestID();
            }

            var getCartCountResponse = await getAPI("/cart/count", cartUser)
            if (getCartCountResponse.code == "success") {
                this.itemCount = getCartCountResponse.data.count
            }
            this.showLoader = false;
        },
        async getCart() {
            this.showLoader = true;
            await this.getCartCount();
            var cartResponse = await getCart();

            if (cartResponse.code == "success") {
                this.cartItem = cartResponse.data;
                this.cartItem.items.forEach((item) => {
                    if (this.$store.state.categories) {
                        this.$store.state.categories.forEach((category) => {
                            if (category.products != null) {
                                category.products.forEach((product) => {
                                    if (product.id == item.id) {
                                        // product.quantityAdded = 0;
                                        product.quantityAdded += item.cart_item_quantity;
                                    }
                                });
                            }

                        });

                        item.selectedImageId = "";
                        item.selectedImage = null;
                        item.displayBundleItem = [];
                        item.selectedVariant = [];
                        item.invalid_text = "";

                        item.selectedImage = item.thumbnail_image;
                        if (item.product_type == "configurable" && item.invalid != true) {
                            var findVariantId = item.variant_properties.filter((inner_item) => inner_item.variant_id == item.cart_item_variant_id);
                            item.displayVariantProperties = findVariantId;

                            item.selectedVariant = item.variants.find((variant) => variant.variant_id == item.cart_item_variant_id);
                            item.selectedImageId = item.selectedVariant.images[0];
                            if (item.selectedImageId != null) {
                                item.selectedImage = item.images.find((img) => img.id == item.selectedImageId).image;
                            }
                        }

                        if (item.product_type == "configurable_bundle" && item.invalid != true) {
                            item.displayBundleItem = item.bundle_types.find((bundle) => bundle.bundle_type_id == item.cart_item_bundle_type_id);
                            item.selectedImage = item.thumbnail_image;
                        }

                        if (item.invalid) {
                            item.invalid_text = this.$t('invalid.item');

                            if (item.invalid_variant) {
                                item.invalid_text = this.$t('invalid.variant');
                            }
                        }
                    }

                })

            }

            this.showLoader = false;
        },
        async getOutlets(auto_select = false, selected_outlet_id = false) {
            this.showLoader = true;
            var latitude = '';
            var longitude = '';

            if (localStorage.order == 'delivery') {
                latitude = this.userAddressLatitude;
                longitude = this.userAddressLongitude;
            } 

            if (localStorage.order == 'pickup') {
                latitude = this.currentUserLatitude;
                longitude = this.currentUserLongitude;
            }
            // console.log('latitude to find outlet:' , latitude);
            // console.log('longitude to find outlet:' , longitude);
            var outletResponse = await getAPI('/outlet', {
                user_longitude: longitude,
                user_latitude: latitude,
                service_availability: localStorage.order
            });
            if (outletResponse) {
                this.outlets = outletResponse.data;
                if (this.outlets && this.outlets.length > 0) {
                    this.outlets.forEach((outlet) => {
                        outlet.title = outlet.name;
                        outlet.address = outlet.address_line_1;
                        outlet.display_status = outlet.status == 1 ? 'Open' : 'Close';
                    });

                    var closestOutlet = null;
    
                    if (localStorage.order == 'delivery') {
                        closestOutlet = this.outlets.find((outlet) => outlet.unsupported_location == false)
                    } else{
                        closestOutlet = this.outlets.find((outlet) => outlet.status == true)
                    }
                    
                    if (closestOutlet != null) {
                        // if (localStorage.order == 'delivery' && closestOutlet.id != localStorage.outlet_id) {
                            this.$store.state.outlet_id = closestOutlet.id;
                            localStorage.outlet_id = this.$store.state.outlet_id;
                            localStorage.outletSelected = JSON.stringify(closestOutlet);
                        // }
                    } else {
                        localStorage.removeItem('outlet_id');
                        localStorage.removeItem('outletSelected');
                        this.$store.state.categories = '';
                        this.categories = [];
                    }

                    if (auto_select && !selected_outlet_id) {
                        if (localStorage.outlet_id) {
                            localStorage.outletSelected = JSON.stringify(this.outlets.find((outlet) => outlet.id == localStorage.outlet_id));
                        } else {
                            if (closestOutlet) {
                                localStorage.outlet_id = closestOutlet.id;
                                this.outletSet = true;
                            } else {
                                this.$swal.fire({
                                    toast: true,
                                    position: "top",
                                    text: this.$t('location_denied'),
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    didOpen: (toast) => {
                                        toast.addEventListener('click', () => {
                                            this.$swal.close();
                                        });
                                    }
                                });
                            }
                        }

                    }
                } else {
                    localStorage.removeItem('outlet_id');
                    localStorage.removeItem('outletSelected');
                    this.$store.state.categories = '';
                    this.categories = [];
                }

            }
            this.showLoader = false;
        },
        async getProducts() {
            this.showLoader = true;
            var GetProductResponse;
            var data;
            var allProduct;

            data = {
                // category: category,
                // with_fields: 1,
                // from_platform: 'miniapp',
                // language_id: this.$store.state.language_id[this.$i18n.locale],
                outlet_id: localStorage.outlet_id ? localStorage.outlet_id : null,
            };

            if (this.userLatitude && this.userLongitude) {
                this.userLocationSet = true;
            }

            if (this.userLocationSet && data.outlet_id != null) {
                GetProductResponse = await getAPI("/product/outlet", data);

                if (GetProductResponse.code == "success") {
                    if (localStorage.outlet_id) {
                        this.display_selected_outlet = GetProductResponse.data.outlet ? GetProductResponse.data.outlet.name : 'Address Not Supported';
                    }

                    if (!GetProductResponse.data.outlet) {
                        this.$swal.fire({
                            toast: true,
                            position: "top",
                            text: this.$t('select_another_outlet'),
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 2000,
                            didOpen: (toast) => {
                                toast.addEventListener('click', () => {
                                    this.$swal.close();
                                });
                            }
                        });
                    }

                    if (this.display_selected_outlet != 'Address Not Supported') {
                        this.$store.state.outlet_id = localStorage.outlet_id;
                    }

                    if (GetProductResponse.data.outlet.categories) {
                        this.$store.state.categories = GetProductResponse.data.outlet.categories;
                        this.selectedCategory = this.$store.state.categories[0].slug;
                        // this.selectedCategory = 'new';
                        GetProductResponse.data.outlet.categories.forEach((item) => {
                            allProduct = item.products;
                            allProduct.forEach((item) => {
                                item.quantityAdded = 0;

                                if (item.product_type == 'configurable' || item.product_type == 'configurable_bundle') {
                                    item.display_lowest_price = '';
                                    item.display_lowest_price = 'From RM ' + item.config_product_lowest_price.toFixed(2);
                                }
                            })
                        })
                    }


                    return allProduct;
                } else {
                    if (GetProductResponse.code == 'outlet_not_available') {
                        localStorage.removeItem('outlet_id');
                        localStorage.removeItem('outletSelected');
                        this.$store.state.categories = '';
                        this.categories = [];

                        this.$swal.fire({
                            toast: true,
                            position: "top",
                            text: GetProductResponse.message,
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 5000,
                            didOpen: (toast) => {
                                toast.addEventListener('click', () => {
                                    this.$swal.close();
                                });
                            }
                        });
                    } else {
                        if (this.deniedLocationError == 'User denied Geolocation') {
                            this.$swal.fire({
                                toast: true,
                                position: "top",
                                text: this.$t('location_denied'),
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 5000,
                                didOpen: (toast) => {
                                    toast.addEventListener('click', () => {
                                        this.$swal.close();
                                    });
                                }
                            });
                        } else {
                            this.$swal.fire({
                                toast: true,
                                position: "top",
                                text: GetProductResponse.message,
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 2000,
                                didOpen: (toast) => {
                                    toast.addEventListener('click', () => {
                                        this.$swal.close();
                                    });
                                }
                            });
                        }

                        if (GetProductResponse.code == 'no_products_available') {
                            this.noProduct = true;
                        }
                    }
                }
            } else if (this.userLocationSet && data.outlet_id == null) {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('address_not_supported'),
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            } else {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('no_address_selected'),
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            }

            this.showLoader = false;
        },
        scrollToSection(category) {
            // this.selectedCategory = category;
            const targetSection = document.getElementById(`${category}_section`);
            if (targetSection) {
                targetSection.scrollIntoView({ behavior: 'smooth' });
            }
        },
        loadProduct(item) {
            if (localStorage.order == 'delivery' && this.addressSelected == null) {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('select_address'),
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            } else {
                this.productPopup = item;

            }
        },
        closePopup() {
            this.popupData = null;
        },
        async closeProduct() {
            this.productPopup = null;
            await this.getProducts();
            await this.getCart();
        },
        async selectDelivery() {
            if (this.deniedLocationError == null) {
                if (localStorage.order != 'delivery' && (this.cartItem.items && this.cartItem.items.length > 0)) {
                    this.$swal.fire({
                        position: "top",
                        text: this.$t('clear_cart.pickup.text'),
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: this.$t('clear_cart.yes'),
                        cancelButtonText: this.$t('clear_cart.pickup.no'),
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            await this.clearCart();
                            this.selectDeliveryTab();
                        }
                    });
                } else {
                    this.selectDeliveryTab();
                }
            } else {
                this.locationDenied();
            }
        },
        async selectDeliveryTab() {
            localStorage.order = 'delivery';
            this.deliveryTypeSelected = true;
            this.pickupTypeSelected = false;
            this.addressSelected = localStorage.addressSelected ? JSON.parse(localStorage.addressSelected) : null;
            localStorage.removeItem('outlet_id');
            localStorage.removeItem('outletSelected');
            this.$store.state.categories = '';
            this.categories = [];
            if (this.addressSelected) {
                this.userAddressLatitude = this.addressSelected.latitude;
                this.userAddressLongitude = this.addressSelected.longitude;
                this.userLocationSet = true;
                await this.getOutlets(true);
                await this.getProducts();
                await this.getCart();
            } else {
                // this.getLocationAndOutlets();
                await this.getProducts();
                await this.getCart();
                this.userLocationSet = true;
            }
        },
        async clearCart() {
            var clearResponse = await getAPI('/cart/clear');
            if (clearResponse.code == 'success') {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('success_clear_cart'),
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
                await this.getCartCount();
            }
        },
        async selectPickup() {
            if (this.deniedLocationError == null) {
                if (localStorage.order != 'pickup' && this.cartItem.items.length > 0) {
                    this.$swal.fire({
                        position: "top",
                        text: this.$t('clear_cart.delivery.text'),
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: this.$t('clear_cart.yes'),
                        cancelButtonText: this.$t('clear_cart.delivery.no'),
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            await this.clearCart();
                            this.selectPickupTab();
                        }
                    });
                } else {
                    this.selectPickupTab();
                }
            } else {
                this.locationDenied();
            }
        },
        async selectPickupTab() {
            this.deliveryTypeSelected = false;
            this.pickupTypeSelected = true;
            var outlet_id;
            localStorage.order = 'pickup'
            localStorage.removeItem('outlet_id');
            localStorage.removeItem('outletSelected');
            this.$store.state.categories = '';
            this.categories = [];
            await this.getOutlets();

            if (localStorage.outlet_id) {
                outlet_id = localStorage.outlet_id;
            } else {
                outlet_id = this.outlets ? (this.outlets[0] ? this.outlets[0].id : null) : null;
            }

            if (outlet_id) {
                this.changeOutlet(outlet_id);
            } else {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('location_denied'),
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            }
        },
        selectDeliveryAddress() {
            if (this.deniedLocationError == null) {
                this.popupData = {
                    addressPicked: "delivery",
                    label: this.$t('delivery_address'),
                }
            } else {
                this.locationDenied();
            }
        },
        async selectPickupStore() {
            await this.getLocationAndOutlets();

            if (this.outlets != undefined) {
                this.popupData = {
                    addressPicked: "pickup",
                    label: this.$t('pickup_store'),
                    outlets: this.outlets,
                }
            } else {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('location_denied'),
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            }
        }
    }
}
</script>
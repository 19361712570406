<template>
    <div class="popup" id="popup">
        <div class="popup__box">
            <div class="popup__header">
                <label>{{ title }}</label>
                <div class="popup__close" @click="closepopup()"><img src="/images/icons/close.png"></div>
            </div>
            <div class="popup__body">
                <label class="order_details__popup_remark--label">{{ $t('remarks') }}:</label>
                {{ remark }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PopupRemark',
    props: {
        popupData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            title: '',
            remark: '',
        }
    },
    created() {
    },
    mounted() {

        if (this.popupData != null) {
            document.getElementById('popup').style.display = 'block';
            this.title = this.popupData.label;
            this.remark = this.popupData.remark;
        }
    },
    methods: {
        closepopup() {
            this.$emit('close');
        }
    }
}
</script>

<template>
    <div class="popup_product" id="popup-product">
        <div class="popup_product__box" :style="backgroundStyle">
            <div class="popup_product__close" @click="backToMenu()"><img src="/images/icons/back.png"></div>
            <div class="popup_product__body">
                <div class="popup_product_detail">
                    <div class="popup_product__name">{{ productItem.name }}</div>
                    <div class="popup_product__price" v-if="productItem.product_type == 'simple' || productItem.product_type == 'bundle'">{{ productItem.price.toFixed(2) }}
                        <div class="popup_product__special_price" v-if="(productItem.product_type == 'simple' || productItem.product_type == 'bundle') 
                        && productItem.actual_price && productItem.actual_price > 0 && productItem.actual_price > productItem.price">{{ productItem.actual_price.toFixed(2) }}</div>
                    </div>
                </div>
                <p class="popup_product_variant" v-for="bundle in productItem.bundle_items" :key="bundle.id">x{{ bundle.bundle_quantity }} {{ bundle.name }}</p>
                <div class="popup_product__desc" v-html="productItem.descriptions"> </div>
                <div class="popup_product__divider"
                    v-if="productItem.product_type == 'configurable' && 'configurable_bundle'"></div>
                <div class="popup_product__variant" v-if="productItem.product_type == 'configurable'">
                    <div class="popup_product__variant_title">
                        <label>{{ $t('variation') }}</label> <span>{{ $t('select_one') }}</span>
                        <div class="popup_product__variant_option popup_product__variant_option--must">{{ $t('required')
                            }}</div>
                    </div>
                    <div class="popup_product__variant_selection" v-for="(variant, index) in productItem.variants"
                        :key="index">
                        <label class="radio__wrap">
                            <input v-model="selectedOption" :value="variant" name="product_variant_id"
                                id="product_variant_id-a" class="radio__input" type="radio" :data-price="variant.price"
                                @change="calculateTotal" @click="variantSelect(variant)"
                                :disabled="variant.stock_availability == '0'">
                                <!-- :disabled="this.editItem || variant.stock_availability == '0'"> -->
                            <div class="radio__design"></div>
                            <div class="radio__text">
                                <div class="radio__thumbnail" v-if="variant.img != null">
                                    <img :src="require('/public/images/sample/' + variant.img)" :alt="variant.name">
                                </div>
                                {{ variant.name }} <br />
                                <span class="radio__text-outofstock" v-if="variant.stock_availability == '0'">{{
            $t('out_of_stock') }} <br /></span>
                                <span>{{ variant.variant_properties_string }}</span>
                            </div>
                            <span class="popup_product__variant_selection--price">{{ variant.price.toFixed(2) }}</span>
                        </label>
                    </div>
                </div>
                <div class="popup_product__variant" v-if="productItem.product_type == 'configurable_bundle'">
                    <div class="popup_product__variant_title">
                        <label>{{ $t('variation') }}</label> <span>{{ $t('select_one') }}</span>
                        <div class="popup_product__variant_option popup_product__variant_option--must">{{ $t('required')
                            }}</div>
                    </div>
                    <div class="popup_product__variant_selection" v-for="(bundle, index) in productItem.bundle_types"
                        :key="index">
                        <label class="radio__wrap">
                            <input v-model="selectedOption" :value="bundle" name="product_variant_id"
                                id="product_variant_id-a" class="radio__input" type="radio" :data-price="bundle.price"
                                @change="calculateTotal" @click="bundleSelect(bundle)"
                                :disabled="bundle.stock_availability == '0'">
                                <!-- :disabled="this.editItem || bundle.stock_availability == '0'"> -->
                            <div class="radio__design"></div>
                            <div class="radio__text">
                                <div class="radio__thumbnail" v-if="bundle.img != null">
                                    <img :src="require('/public/images/sample/' + bundle.img)" :alt="bundle.name">
                                </div>
                                {{ bundle.name }}<br />
                                <span class="radio__text-outofstock" v-if="bundle.stock_availability == '0'">{{
            $t('out_of_stock') }} <br /></span>
                                <span v-for="item in bundle.items" :key="item.id">- {{ item.name }}<br/></span>
                            </div>
                            <span class="popup_product__variant_selection--price">{{ bundle.price.toFixed(2) }}</span>
                        </label>
                    </div>
                </div>
                <!-- <div class="popup_product__divider" v-if="this.productItem.quantity - this.productItem.quantity_turn_out_of_stock > 0"></div> -->
                <div class="popup_product__quantity" v-if="this.productItem.quantity - this.productItem.quantity_turn_out_of_stock > 0">
                    <div v-if="false">
                        {{ $t('quantity.name') }}
                    </div>
                    <div class="number-input">
                        <button id="quantity" @click="decrement" aria-label="Decrease Quantity"></button>
                        <input aria-label="Quantity" type="number" id="quantity" name="quantity" min="1"
                            v-model="quantity" oninput="this.value = Math.abs(this.value)"
                            @input="quantityCheck($event.target.value)" disabled />
                        <button id="quantity" class="plus" @click="increment"></button>
                    </div>
                </div>
                <div v-if="productItem.upsell_products != ''">
                    <div class="popup_product__divider"></div>
                    <div class="popup_product__variant_title">
                        <label>{{ $t('add_on') }}</label>
                        <div class="popup_product__variant_option popup_product__variant_option--popular">{{
            $t('popular')
        }}</div>
                    </div>
                    <div class="popup_product__variant_selection popup_product__variant_selection-addon"
                        v-for="(addOn, index) in visibleProducts" :key="index">
                        <label class="checkbox__wrap">
                            <input v-model="checkedOptions" :value="addOn" name="product_variant_id"
                                id="product_variant_id" class="checkbox__input" type="checkbox"
                                :data-price="addOn.price" @change="calculateAddonTotal">
                            <div class="checkbox__design"></div>
                            <div class="checkbox__text">
                                <div class="checkbox__thumbnail">
                                    <img
                                        :src="addOn.thumbnail_image != null ? addOn.thumbnail_image : require('/public/images/sample/no-image.png')">
                                </div>
                                <p>{{ addOn.name }}<br/>
                                    <span v-if="addOn.product_type == 'configurable'" >x{{ bundle.bundle_quantity }} {{ addOn.selected_variant.name }}<br/></span>
                                    <span v-for="bundleItem in addOn.bundle_items" :key="bundleItem.id" >x{{ bundle.bundle_quantity }} {{ bundleItem.name }}<br/></span>
                                </p>
                            </div>
                            <div class="popup_product__variant_selection-addon-price_qty">
                                <span class="popup_product__variant_selection--price addon">{{ addOn.price.toFixed(2) }}</span><br/>
                                <span class="popup_product__variant_selection--actualprice">{{ addOn.actual_price.toFixed(2) }}</span>
                            </div>
                        </label>
                        <div class="popup_product__quantity">
                            <div class="number-input"
                                v-if="addOn.quantity > 0 && (addOn.upsell_min_purchase_qty != null && addOn.quantity >= addOn.upsell_min_purchase_qty)">
                                <button @click="calculateAddonQuantity(addOn, false)"
                                    :disabled="(productItem.product_type == 'configurable' || productItem.product_type == 'configurable_bundle') && selectedProductVariant == null"></button>
                                <input type="number" v-model.number="counterValue" @input="validateInput"
                                    aria-label="Quantity" id="quantity" name="quantity" min="1"
                                    v-model="addOn.quantityAdded" oninput="this.value = Math.abs(this.value)"
                                    disabled />
                                <button class="plus" @click="calculateAddonQuantity(addOn, true)"
                                    ></button>
                            </div>
                            <div class="counter-container" v-else>
                                <p class="counter-container-oos">{{ $t('out_of_stock') }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="!showAll && this.addOns.length > 3" class="popup_product__variant_showmore"
                        @click="showAll = true">
                        {{ $t('view_more') }}
                    </div>
                </div>
                <div class="popup_product__divider"></div>
                <div class="popup_product__variant_title">
                    <label>{{ $t('remarks') }}</label>
                    <div class="popup_product__variant_option popup_product__variant_option--optional">{{
                        $t('optional')
                    }}</div>
                    <div class="popup_product__remark">
                        <textarea row="3" maxlength="50" v-model="productRemark"
                            :placeholder="$t('add_your_request')"></textarea>
                    </div>
                </div>
            </div>
            <div class="popup_product__action">
                <!-- <div class="popup_product__summary">
                    <label>{{ $t('subtotal') }}</label>
                    <span>{{ total_price }} <br /> {{ this.total_addon_price ? '+' + this.total_addon_price.toFixed(2) :
            ''
                        }}</span>
                </div> -->
                <div class="popup_product__action--row">
                    <!-- <div class="popup_product__divider"></div> -->
                    <div v-if="this.productItem.quantity - this.productItem.quantity_turn_out_of_stock <= 0">
                        <button v-if="!removeItem" class="btn disabled" id="popup_product__action--positive">{{
            $t('out_of_stock') }}</button>
                        <button v-if="removeItem" class="btn removeBtn " :class="!addEnable ? 'disabled' : ''"
                            @click="removeCartItemAction()" id="popup_product__action--positive">{{ $t('remove')
                            }}</button>
                    </div>

                    <div v-if="this.productItem.quantity - this.productItem.quantity_turn_out_of_stock > 0">
                        <div v-if="!removeItem">
                            <button v-if="productItem.changeVariation"  class="btn " :class="!addEnable || quantity <= 0 ? 'disabled' : ''"
                                @click="updateVariation()" id="popup_product__action--positive">{{ $t('change_variation') }}
                                <span class="popup_product__subtotal">{{ display_total_price }}</span>
                                <!-- {{ this.total_addon_price ? ' + RM' + this.total_addon_price.toFixed(2) : '' }} -->
                            </button>
                            <button v-if="!productItem.changeVariation" class="btn " :class="!addEnable || quantity <= 0 ? 'disabled' : ''"
                                @click="addToCart()" id="popup_product__action--positive">{{ updateCartCondition == true ?
                $t('update_basket') : $t('add_to_basket') }}
                                <span class="popup_product__subtotal">{{ display_total_price }}</span>
                                <!-- {{ this.total_addon_price ? ' + RM' + this.total_addon_price.toFixed(2) : '' }} -->
                            </button>
                        </div>
                        <button v-if="removeItem" class="btn removeBtn " :class="!addEnable ? 'disabled' : ''"
                            @click="removeCartItemAction()" id="popup_product__action--positive">{{ $t('remove')
                            }}</button>
                    </div>
                    <button v-if="false" class="btn " :class="!addEnable ? 'disabled' : ''" @click="addCheckout()"
                        id="popup_product__action--negative">{{
            $t('checkout_now') }}</button>
                </div>
            </div>
        </div>
    </div>
    <Loader_component :loading="showLoader" :isFullPage="true" />
    <AddonUnavailable v-if="itemAddonRemove != null" :popupData="itemAddonRemove" @close="updateAddonBasket()" />
</template>

<script>
import { displayPrice } from '@/js/app.js'
import Loader_component from "@/include/loader_component.vue";
import { getAPI, getGuestID } from '@/js/general';
import { addCartItemCartPage, addCartItem, deleteCartItem, updateCartItemBundle, updateCartItemVariant } from "@/js/cart";
import AddonUnavailable from '@/components/menu/AddonUnavailable.vue';

export default {
    name: 'MenuProduct',
    props: {
        popupData: {},
        editItem: {}
    },
    data() {
        return {
            selectedOption: null,
            checkedOptions: [],
            total_price: 0,
            display_total_price: 0,
            total_addon_price: 0,
            productId: '',
            showLoader: false,
            productItem: [],
            addOns: [],
            addEnable: false,
            showAll: false,
            quantity: 1,
            productRemark: '',

            addOnSelected: [],

            updateCartCondition: false,
            itemReadFromCart: '',
            selectedProductVariant: null,
            selected_bundle: "",
            removeItem: false,
            removeCartItem: '',

            selectedAddon: [],

            itemAddonRemove: null,
            backgroundImageUrl: ''
        };
    },
    computed: {
        visibleProducts() {
            // If showAll is true, return all products, otherwise return the first three
            return this.showAll ? this.addOns : this.addOns.slice(0, 3);
        },
        backgroundStyle() {
            return {
                backgroundImage: `url(${this.backgroundImageUrl})`,
            };
        }
    },
    async created() {
        this.showLoader = true;
        this.productId = this.popupData;

        await this.loadProductDetails();
        await this.getQuantityFromCart();

        this.calculateTotal();
        this.showLoader = false;
    },
    mounted() {
        if (this.popupData != null) {
            document.getElementById('popup-product').style.display = 'block';
        }
    },
    components: {
        Loader_component,
        AddonUnavailable
    },
    methods: {
        async updateVariation() {

            var updateVariation = '';
            if (this.productItem.product_type == 'configurable') {
                updateVariation = this.itemReadFromCart.variants.find((item) => item.variant_id == this.editItem);    
                this.addOnSelected = [];
                this.addOns.forEach(option => {
                    if (option.quantityAdded > 0) {

                        if (option.product_type == 'configurable') {
                            this.addOnSelected.push({
                                addon_product_id: option.id,
                                addon_variant_id: option.upsell_variant_id,
                                addon_quantity: option.quantityAdded,
                                outlet_inventory_id: option.outlet_inventory_id,
                                replace: 1,
                            });
                        } else {
                            this.addOnSelected.push({
                                addon_product_id: option.id,
                                addon_quantity: option.quantityAdded,
                                outlet_inventory_id: option.outlet_inventory_id,
                                replace: 1,
                            });
                        }
                    } else {
                        if (option.isRemoved) {
                            if (option.product_type == 'configurable') {
                                this.addOnSelected.push({
                                    addon_product_id: option.id,
                                    addon_variant_id: option.upsell_variant_id,
                                    outlet_inventory_id: option.outlet_inventory_id,
                                    remove_addon: true,
                                });
                            } else {
                                this.addOnSelected.push({
                                    addon_product_id: option.id,
                                    outlet_inventory_id: option.outlet_inventory_id,
                                    remove_addon: true,
                                });
                            }
                        }
                    }
                });
            
                await updateCartItemVariant(updateVariation.cart_item_id, this.selectedOption.variant_id, this.quantity, this.addOnSelected)
            }

            if (this.productItem.product_type == 'configurable_bundle') {
                updateVariation = this.itemReadFromCart.bundle_types.find((item) => item.bundle_type_id == this.editItem);
                this.addOnSelected = [];
                this.addOns.forEach(option => {
                    if (option.quantityAdded > 0) {

                        if (option.product_type == 'configurable') {
                            this.addOnSelected.push({
                                addon_product_id: option.id,
                                addon_variant_id: option.upsell_variant_id,
                                addon_quantity: option.quantityAdded,
                                outlet_inventory_id: option.outlet_inventory_id,
                                replace: 1,
                            });
                        } else {
                            this.addOnSelected.push({
                                addon_product_id: option.id,
                                addon_quantity: option.quantityAdded,
                                outlet_inventory_id: option.outlet_inventory_id,
                                replace: 1,
                            });
                        }
                    } else {
                        if (option.isRemoved) {
                            if (option.product_type == 'configurable') {
                                this.addOnSelected.push({
                                    addon_product_id: option.id,
                                    addon_variant_id: option.upsell_variant_id,
                                    outlet_inventory_id: option.outlet_inventory_id,
                                    remove_addon: true,
                                });
                            } else {
                                this.addOnSelected.push({
                                    addon_product_id: option.id,
                                    outlet_inventory_id: option.outlet_inventory_id,
                                    remove_addon: true,
                                });
                            }
                        }
                    }
                });

                await updateCartItemBundle(updateVariation.cart_item_id, this.selectedOption.bundle_type_id, this.quantity, this.addOnSelected)
            }

            this.$emit('close');
        },
        openAddonUnavailable(item) {
            this.itemAddonRemove = item;
        },
        async updateAddonBasket() {
            this.itemAddonRemove = null;
            await this.addToCart();
        },
        getVariantID() {
            var selectedItem = null;
            if (this.productItem.product_type == "configurable" && this.selectedOption != null) {
                selectedItem = this.selectedOption.variant_id
            }

            return selectedItem;
        },
        async addToCart() {
            this.showLoader = true;

            var product = this.productItem;
            var add_cart_error = false;
            var add_cart_error_message = "Unexpected error occurs.";

            var product_id = product.id;
            var variant_id = this.getVariantID();
            var bundle_type_id = this.selected_bundle;


            if (product.product_type == "configurable" && variant_id == null) {
                add_cart_error = true;
                add_cart_error_message = "Please select a valid variant.";
            }

            if (product.product_type == "configurable_bundle" && (bundle_type_id == null || String(bundle_type_id).trim() == "")) {
                add_cart_error = true;
                add_cart_error_message = "Please select a valid bundle.";
            }

            if (add_cart_error) {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: add_cart_error_message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                })

            } else {

                if (String(bundle_type_id) == "") {
                    bundle_type_id = null;
                }

                var response;

                this.addOnSelected = [];
                this.addOns.forEach(option => {
                    if (option.quantityAdded > 0) {

                        if (option.product_type == 'configurable') {
                            this.addOnSelected.push({
                                addon_product_id: option.id,
                                addon_variant_id: option.upsell_variant_id,
                                addon_quantity: option.quantityAdded,
                                outlet_inventory_id: option.outlet_inventory_id,
                                replace: 1,
                            });
                        } else {
                            this.addOnSelected.push({
                                addon_product_id: option.id,
                                addon_quantity: option.quantityAdded,
                                outlet_inventory_id: option.outlet_inventory_id,
                                replace: 1,
                            });
                        }
                    } else {
                        if (option.isRemoved) {
                            if (option.product_type == 'configurable') {
                                this.addOnSelected.push({
                                    addon_product_id: option.id,
                                    addon_variant_id: option.upsell_variant_id,
                                    outlet_inventory_id: option.outlet_inventory_id,
                                    remove_addon: true,
                                });
                            } else {
                                this.addOnSelected.push({
                                    addon_product_id: option.id,
                                    outlet_inventory_id: option.outlet_inventory_id,
                                    remove_addon: true,
                                });
                            }
                        }
                    }
                });

                // this.checkedOptions.forEach((addOn) => {
                //     this.addOnSelected.push({
                //         addon_product_id: addOn.id,
                //         addon_variant_id: addOn.upsell_variant_id,
                //         addon_quantity: 1,
                //         outlet_inventory_id: addOn.outlet_inventory_id
                //     });
                // });


                if (this.updateCartCondition == true) {
                    response = await addCartItemCartPage(product_id, variant_id, bundle_type_id, this.quantity, localStorage.outlet_id, this.productRemark, this.addOnSelected);
                } else {
                    response = await addCartItem(product_id, variant_id, bundle_type_id, this.quantity, localStorage.outlet_id, this.productRemark, this.addOnSelected);
                }


                if (response.code == 'success') {
                    // await this.addAddOn();

                    this.$emit('close');
                }

                this.getQuantityFromCart();

            }
            this.showLoader = false;
        },
        addAddOn() {
            if (this.addOnSelected != '') {

                this.selectedAddon = [];
                this.addOnSelected.forEach(async (addOn) => {
                    this.selectedAddon.push({
                        addon_product_id: addOn.id,
                        addon_variant_id: addOn.selected_variant.variant_id,
                        addon_bundle_type_id: addOn.selected_bundle.bundle_type_id,
                        addon_quantity: 1,
                    });
                });
            }
        },
        async getQuantityFromCart() {
            this.updateCartCondition = false;
            var cartUser = {
                product_id: this.productItem.id,
            }

            if (this.$store.state.isLoggedIn && !this.$store.state.access_token) {
                this.$store.state.is_new = 1;
            }

            if (localStorage.outlet_id) {
                cartUser['outlet_id'] = localStorage.outlet_id;
            }

            if (!this.$store.state.isLoggedIn) {
                if (!localStorage.guest_id) {
                    this.$store.state.is_new = 1;
                }
                cartUser['guest_id'] = getGuestID();
            }

            if (this.$store.state.is_new == 1) {
                cartUser['is_new'] = 1;
                this.$store.state.is_new = 0;
            }

            var cartItemInit = await getAPI('/cart/item/initialize', cartUser);
            if (cartItemInit.code == 'success') {
                this.itemReadFromCart = cartItemInit.data;
                this.itemExistCart();
            }
        },
        increment() {
            this.quantity += 1;
            this.removeItem = false;
            this.quantityCheck(this.quantity);
            this.calculateTotalPrice();
        },
        calculateTotalPrice() {
            var displayTotal = 0;

            if (this.productItem.product_type == 'configurable' || this.productItem.product_type == 'configurable_bundle') {

                if (this.selectedProductVariant == null) {
                    displayTotal = 0;
                } else {
                    displayTotal = this.selectedProductVariant.price;
                }
            } else {
                displayTotal = this.productItem.price;
            }
            displayTotal *= this.quantity;

            if (this.total_addon_price !== '') {
                displayTotal = displayTotal + this.total_addon_price;
            }

            this.display_total_price = displayPrice(displayTotal);
        },
        async decrement() {

            if (this.quantity > 0) {
                this.quantity -= 1;
                this.quantityCheck(this.quantity);
            }

            if (this.updateCartCondition && this.quantity <= 0) {
                this.removeItem = true;

                if (this.itemReadFromCart.status == "added_to_cart") {
                    if (this.itemReadFromCart.product_type == 'configurable' && this.getVariantID()) {
                        this.removeCartItem = this.itemReadFromCart.variants.find(item => item.variant_id == this.getVariantID());
                    } else if (this.itemReadFromCart.product_type == 'configurable_bundle' && this.selected_bundle) {
                        this.removeCartItem = this.itemReadFromCart.bundle_types.find(item => item.bundle_type_id == this.selected_bundle);
                    } else {
                        this.removeCartItem = this.itemReadFromCart;
                    }
                }
            }

            this.calculateTotalPrice();
        },
        async removeCartItemAction() {
            var removeCartItemResponse = await deleteCartItem(this.removeCartItem.cart_item_id);
            if (removeCartItemResponse.code == "success") {
                this.$emit('close');
            }
        },
        variantSelect(variant) {
            // this.quantity = 1;
            this.selectedProductVariant = variant;

            if (this.selectedProductVariant.stock_availability == '0' || this.selectedProductVariant.quantity <= 0) {
                this.selectedProductVariant.outofstock = true;
            }
            this.removeItem = false;
        },
        bundleSelect(bundle) {
            // this.quantity = 1;
            this.selected_bundle = bundle.bundle_type_id;
            this.selectedProductVariant = bundle;
        },
        quantityCheck(event) {
            var noVariantSelect = false;
            var availableQuantity = 0;
            var availableSalesQuantity = 0;
            var maxPurchase = 0;
            var minPurchase = 0;
            var requestedQuantity = event; // Quantity requested by the customer
            if (this.productItem.product_type == 'simple' || this.productItem.product_type == 'bundle') {
                availableQuantity = this.productItem.quantity; // Available quantity in stock
                availableSalesQuantity = this.productItem.quantity - this.productItem.quantity_turn_out_of_stock; // Available sales quantity
                maxPurchase = this.productItem.max_purchase; // Maximum purchase allowed
                minPurchase = this.productItem.min_purchase; // Minimum purchase required
                noVariantSelect = false;
            } else {
                maxPurchase = this.productItem.max_purchase; // Maximum purchase allowed

                if (this.selectedProductVariant != null) {
                    noVariantSelect = false;
                    availableQuantity = this.selectedProductVariant.quantity;
                    availableSalesQuantity = this.selectedProductVariant.quantity - this.selectedProductVariant.quantity_turn_out_of_stock;
                } else {
                    noVariantSelect = true;
                    availableQuantity = 0;
                    availableSalesQuantity = 0;
                }
            }

            // var capPerCustomerEnabled = true; // Whether cap per customer is enabled
            // var capPerCustomer = 8; // Cap per customer

            // Adjust quantity to the smallest of available quantity, max purchase, available sales quantity, cap per customer, and requested quantity itself
            if (maxPurchase > 0) {
                requestedQuantity = Math.min(requestedQuantity, maxPurchase, availableSalesQuantity);
            } else {
                requestedQuantity = Math.min(requestedQuantity, availableSalesQuantity);
            }

            // if (minPurchase > 0) {
            //     requestedQuantity = Math.max(requestedQuantity, minPurchase);
            // }
            // if (capPerCustomerEnabled) {
            // 	requestedQuantity = Math.min(requestedQuantity, capPerCustomer);
            // }

            // Ensure requested quantity is not less than the minimum purchase

            var msg = '';
            // Check if requested quantity exceeds available quantity
            if (requestedQuantity >= availableQuantity) {
                availableSalesQuantity = availableQuantity;
                msg = this.$t('quantity.sales_quantity');
            } else if (maxPurchase && maxPurchase > 0 && (requestedQuantity >= maxPurchase)) {
                availableSalesQuantity = maxPurchase;
                msg = this.$t('quantity.max_purchase');
            } else if (minPurchase && minPurchase > 0 && (requestedQuantity < minPurchase)) {
                availableSalesQuantity = minPurchase;
                msg = this.$t('quantity.min') + minPurchase + this.$t('quantity.purchase');
            } else if (requestedQuantity > availableSalesQuantity) {
                requestedQuantity = availableSalesQuantity;
                msg = this.$t('quantity.sales_quantity');
            }

            if (availableSalesQuantity <= 0) {
                if (noVariantSelect) {
                    msg = this.$t('quantity.no_variant');
                } else {
                    msg = this.$t('quantity.out_of_stock');

                }
            }

            // else if (capPerCustomerEnabled && requestedQuantity >= capPerCustomer) {
            // 	msg = "Requested quantity exceeds cap per customer.";
            // }

            this.quantity = requestedQuantity;
            if (msg != '') {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: msg,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            }
        },
        itemExistCart() {
            var variantCartItem;
            if (this.itemReadFromCart.status == "added_to_cart") {
                if (this.itemReadFromCart.product_type == 'configurable' && this.getVariantID()) {
                    variantCartItem = this.itemReadFromCart.variants.find(item => item.variant_id == this.getVariantID());
                } else if (this.itemReadFromCart.product_type == 'configurable_bundle' && this.selected_bundle) {
                    variantCartItem = this.itemReadFromCart.bundle_types.find(item => item.bundle_type_id == this.selected_bundle);
                } else {
                    variantCartItem = this.itemReadFromCart;
                }

                if (variantCartItem) {
                    if (variantCartItem.status == 'added_to_cart') {
                        if (this.getVariantID() || this.selected_bundle) {
                            this.quantity = variantCartItem.cart_item_quantity;
                            this.updateCartCondition = true;
                        } else {
                            this.updateCartCondition = false;
                        }

                        if (this.productItem.product_type == 'simple' || this.productItem.product_type == 'bundle') {
                            this.quantity = variantCartItem.cart_item_quantity ? variantCartItem.cart_item_quantity : 1;
                            this.updateCartCondition = true;
                        }
                    } else {
                        this.updateCartCondition = false;
                    }
                }

                if (variantCartItem.cart_item_remark) {
                    this.productRemark = variantCartItem.cart_item_remark;
                } else {
                    this.productRemark = '';
                }


                if (!this.editItem) {
                    this.addOns.forEach((product_addon) => {
                        product_addon.quantityAdded = 0;
                    });
                }

                if (variantCartItem.cart_item_addons) {
                    this.checkedOptions = [];
                    var triggerAddonUnavailable = false;
                    variantCartItem.cart_item_addons.forEach((cart_item_addon) => {
                        cart_item_addon.waitUpdate = false;

                        if (cart_item_addon.quantity <= 0) {
                            triggerAddonUnavailable = true;
                            cart_item_addon.waitingUpdate = true;
                        }

                        if (cart_item_addon.cart_item_addon_quantity > cart_item_addon.quantity) {
                            triggerAddonUnavailable = true;
                            cart_item_addon.waitingUpdate = true;
                        }

                        if (cart_item_addon.cart_item_addon_quantity > cart_item_addon.upsell_max_purchase_qty) {
                            triggerAddonUnavailable = true;
                            cart_item_addon.waitingUpdate = true;
                        }

                        var findSelectedAddon = this.productItem.upsell_products.find((upsell_product) => upsell_product.id == cart_item_addon.id);

                        if (findSelectedAddon) {
                            this.checkedOptions.push(findSelectedAddon);

                            this.addOns.forEach((product_addon) => {
                                if (product_addon.id == cart_item_addon.id) {
                                    product_addon.quantityAdded = cart_item_addon.cart_item_addon_quantity;

                                    if (cart_item_addon.waitingUpdate) {
                                        if (cart_item_addon.cart_item_addon_quantity > cart_item_addon.quantity) {
                                            product_addon.quantityAdded = cart_item_addon.quantity;
                                        }

                                        if (cart_item_addon.quantity <= 0) {
                                            product_addon.isRemoved = true;
                                        }

                                        if (cart_item_addon.cart_item_addon_quantity > cart_item_addon.upsell_max_purchase_qty) {
                                            product_addon.quantityAdded = cart_item_addon.upsell_max_purchase_qty;
                                            product_addon.isRemoved = true;
                                        }
                                        
                                    }
                                }
                            });
                        }
                    });

                    if (this.productItem.product_type == 'configurable' || this.productItem.product_type == 'configurable_bundle') {
                        if (this.selectedProductVariant.quantity <= 0) {
                            triggerAddonUnavailable = false;
                        }
                    }

                    if (triggerAddonUnavailable) {
                        this.openAddonUnavailable(variantCartItem.cart_item_addons);
                    }
                }

                this.calculateAddonTotal();
            }
        },
        async loadProductDetails() {
            var productResponse = await getAPI("/product/get", {
                slug: this.productId,
                // language_id: this.$store.state.language_id[this.$i18n.locale],
                outlet_id: localStorage.outlet_id ? localStorage.outlet_id : null,
            });

            if (productResponse.code == "success") {
                this.productItem = productResponse.data;
                if (this.productItem.thumbnail_image == null) {
                    this.backgroundImageUrl = require('/public/images/sample/no-image.png');
                } else {
                    this.backgroundImageUrl = this.productItem.thumbnail_image;
                }

                if (this.editItem) {
                    if (this.productItem.product_type == 'configurable') {
                        this.productItem.changeVariation = false;

                        this.productItem.variants.forEach((item) => {
                            if (item.variant_id == this.editItem) {
                                this.variantSelect(item);
                                this.selectedOption = item;
                            }
                        })
                    }

                    if (this.productItem.product_type == 'configurable_bundle') {
                        this.productItem.changeVariation = false;

                        this.productItem.bundle_types.forEach((item) => {
                            if (item.bundle_type_id == this.editItem) {
                                this.bundleSelect(item);
                                this.selectedOption = item;
                            }
                        })
                    }

                } else {
                    if (this.productItem.product_type == 'configurable') {
                        this.selectFirstAvailableVariantOrBundle(this.productItem.variants, this.variantSelect);
                    }

                    if (this.productItem.product_type == 'configurable_bundle') {
                        this.selectFirstAvailableVariantOrBundle(this.productItem.bundle_types, this.bundleSelect);
                    }
                }

                this.addOns = this.productItem.upsell_products;

                this.addOns.forEach((addOn) => {
                    addOn.quantityAdded = 0;
                    addOn.isRemoved = false;

                    if (addOn.product_type == 'configurable') {
                        addOn.quantity = addOn.selected_variant.quantity - addOn.selected_variant.quantity_turn_out_of_stock;
                    }
                })

                if (this.productItem.product_type == 'simple' || this.productItem.product_type == 'bundle') {
                    this.addEnable = true;
                }
            } else {
                this.$emit('close');
            }
        },
        selectFirstAvailableVariantOrBundle(itemList, selectFunction) {
            for (let i = 0; i < itemList.length; i++) {
                if (itemList[i].quantity > 0) {
                    selectFunction(itemList[i]);
                    this.selectedOption = itemList[i];
                    return;
                }
            }
        },
        calculateAddonQuantity(addOn, increment) {
            if (increment) {
                addOn.quantityAdded += 1;

            } else {
                if (addOn.quantityAdded > 0) {
                    addOn.quantityAdded -= 1;
                }

                if (addOn.quantityAdded <= 0) {
                    addOn.isRemoved = true;
                }
            }

            var availableQuantity = 0;
            var availableSalesQuantity = 0;
            var maxPurchase = 0;
            var minPurchase = 0;
            var requestedQuantity = addOn.quantityAdded; // Quantity requested by the customer
            availableQuantity = addOn.quantity; // Available quantity in stock
            availableSalesQuantity = addOn.quantity - addOn.quantity_turn_out_of_stock; // Available sales quantity
            maxPurchase = addOn.upsell_max_purchase_qty; // Maximum purchase allowed
            minPurchase = addOn.upsell_min_purchase_qty; // Minimum purchase required

            // Adjust quantity to the smallest of available quantity, max purchase, available sales quantity, cap per customer, and requested quantity itself
            if (maxPurchase > 0) {
                requestedQuantity = Math.min(requestedQuantity, maxPurchase, availableSalesQuantity);
            } else {
                requestedQuantity = Math.min(requestedQuantity, availableSalesQuantity);
            }

            // Ensure requested quantity is not less than the minimum purchase
            var msg = '';
            // Check if requested quantity exceeds available quantity
            if (requestedQuantity >= availableQuantity) {
                availableSalesQuantity = availableQuantity;
                msg = this.$t('quantity.available_quantity');
            } else if (maxPurchase && maxPurchase > 0 && (requestedQuantity >= maxPurchase)) {
                availableSalesQuantity = maxPurchase;
                msg = this.$t('quantity.max_purchase');
            } else if (minPurchase && minPurchase > 1 && (requestedQuantity < minPurchase)) {
                availableSalesQuantity = minPurchase;
                msg = this.$t('quantity.min') + minPurchase + this.$t('quantity.purchase');
            } else if (requestedQuantity > availableSalesQuantity) {
                requestedQuantity = availableSalesQuantity;
                msg = this.$t('quantity.sales_quantity');
            }

            if (availableSalesQuantity <= 0) {
                msg = this.$t('quantity.out_of_stock');
            }

            addOn.quantityAdded = requestedQuantity;
            if (msg != '') {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: msg,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            }
            this.calculateAddonTotal();
        },
        calculateAddonTotal() {
            this.total_addon_price = 0;
            this.checkedOptions = [];
            this.addOns.forEach(option => {

                if (option.quantityAdded > 0) {
                    this.checkedOptions.push(option);
                }

                if (option.quantityAdded > 0) {
                    this.total_addon_price += parseFloat(option.quantityAdded * option.price);
                }
            });
            this.calculateTotalPrice();
        },
        calculateTotal() {
            this.total_price = 0;

            if (this.selectedOption) {
                this.addEnable = true;
                this.itemExistCart();
                this.quantityCheck(this.quantity);
                if (this.editItem) {
                    var itemStatus = '';
                    if (this.productItem.product_type == 'configurable' && this.getVariantID()) {
                        itemStatus = this.itemReadFromCart.variants.find(item => item.variant_id == this.getVariantID()).status;
                        this.productItem.changeVariation = itemStatus != 'added_to_cart' ? true : false;
                    }

                    if (this.productItem.product_type == 'configurable_bundle' && this.selected_bundle) {
                        itemStatus = this.itemReadFromCart.bundle_types.find(item => item.bundle_type_id == this.selected_bundle).status;
                        this.productItem.changeVariation = itemStatus != 'added_to_cart' ? true : false;
                    }
                }
                this.total_price += parseFloat(this.selectedOption.price);
            }

            if (this.productItem.product_type == 'simple' || this.productItem.product_type == 'bundle') {
                this.total_price += parseFloat(this.productItem.price);
            }

            if (this.quantity > 0) {
                this.total_price *= this.quantity;
            }

            if (this.total_addon_price !== '') {
                this.total_price = this.total_price + this.total_addon_price;
            }
            this.display_total_price = displayPrice(this.total_price);
        },
        backToMenu() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
.popup_product {
    bottom: 0;
    animation: slideIn 0.5s ease-in-out forwards;
}
</style>

import { baseApiURL } from "@/config/env.js";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'
import store from '../store/index.js';
import i18n from './i18n.js';

let emergencyStopAll = false;

const getAPI = async (path, data = {}, with_token = false) => {

	if (emergencyStopAll) {
		return { "code": "invalid_token", "message": "The last session has expired. Please refresh your browser." };
	}

	var headers = {};

	var passed_access_token = false;
	if ((store.state.isLoggedIn && store.state.access_token && store.state.access_token.trim()) || with_token) {
		headers['Authorization'] = `Bearer ${store.state.access_token}`;
		passed_access_token = true;
	}

	data.language_id = store.state.language_id[i18n.global.locale];

	
	if (!store.state.outlet_id || store.state.outlet_id == null) {
		store.state.outlet_id = localStorage.outlet_id
	}
	
	data.outlet_id = store.state.outlet_id;

	var response = await axios.get(`${baseApiURL}${path}`, {
		params: data,
		headers: headers
	}).catch((response) => response.response);

	if (passed_access_token && response.data.code == 'invalid_token') {

		emergencyStopAll = true;

		Swal.fire({
			icon: 'error',
			text: response.data.message,
			timer: 5000,
			showConfirmButton: false,
			timerProgressBar: true,
		}).then(() => {
			window.location = `${store.state.multiLanguage}` + 'login';
		});
	}

	return response.data;
}

const postAPI = async (path, data = {}, with_token = false) => {
	var headers = {};

	if (emergencyStopAll) {

		return { "code": "invalid_token", "message": "The last session has expired. Please refresh your browser." };

	}
	
	data.language_id = store.state.language_id[i18n.global.locale];
	data.outlet_id = store.state.outlet_id;
	
	var passed_access_token = false;
	if ((store.state.isLoggedIn && store.state.access_token && store.state.access_token.trim()) || with_token) {
		headers['Authorization'] = `Bearer ${store.state.access_token}`;
		passed_access_token = true;
	}
	var response = await axios({
		method: 'post',
		url: `${baseApiURL}${path}`,
		headers: headers,
		params: data,
	}).catch((response) => response.response);

	if (passed_access_token && response.data.code == 'invalid_token') {

		emergencyStopAll = true;

		Swal.fire({
			icon: 'error',
			text: response.data.message,
			timer: 5000,
			showConfirmButton: false,
			timerProgressBar: true,
		}).then(() => {
			window.location = `${store.state.multiLanguage}` + 'login';
		});
	}

	return response.data;
}

const getGuestID = () => {
	if (!localStorage.guest_id) {
		localStorage.guest_id = uuidv4();
	}
	return localStorage.guest_id;
}

export {
	getAPI,
	postAPI,
	getGuestID
};
<template>
    <div class="popup_full" id="popup-full" :style="backgroundStyle">
        <div class="popup_full__close" @click="closepopup()"><img src="/images/icons/close.png"></div>
        <div class="popup_full__box">
            <div class="popup_full__header">
                <label>{{ title }}</label>
            </div>
            <div class="popup_full__body">
                {{ info }}
            </div>
            <div class="popup_full__action" style="">
                <button class="btn" id="popup_full__action--positive" @click="triggerSelect()">{{ selected ? $t('event.step_four.service.remove') : $t('event.step_four.service.interested') }}</button>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: 'PopupEventFull',
    props: {
        popupData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            title: '',
            info: '',
            selected: false,
            backgroundImageUrl: ''
        }
    },
    computed: {
        backgroundStyle() {
            return {
                backgroundImage: `url(${this.backgroundImageUrl})`,
            };
        }
    },
    created() {
    },
    mounted() {

        if (this.popupData != null) {
            document.getElementById('popup-full').style.display = 'block';
            document.getElementById('popup-full').style.backgroundSize = '70vh';
            this.title = this.popupData.name;
            this.info = this.popupData.info;
            this.selected = this.popupData.selected;
            this.backgroundImageUrl = this.popupData.image;
        }
    },
    methods: {
        closepopup() {
            this.$emit('close');
        },
        triggerSelect() {
            this.$emit('checkSelect');
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
.popup_full {
    bottom: 0;
    animation: slideIn 0.5s ease-in-out forwards;
}
</style>
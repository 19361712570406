<template>
    <div class="frame">
        <div class="page page-history">
            <h1>{{ $t('order_history.title') }}</h1>
            <section class="history__listing" v-if="onlineOrders != ''">
                <div class="history__box" v-for="(item, index) in onlineOrders" :key="index">
                    <div class="history__box_title">
                        {{ item.display_status }} #{{ item.id }}
                    </div>
                    <div class="history__box_body">
                        <div class="history__box_details">
                            <div class="history__box_details--destination">
                                {{ item.is_pickup == 1 ? '' : ($t('order_history.delivered_to') +
                (item.billing_address_line_1 ? (' ' + item.billing_address_line_1) : '-')) }}
                            </div>
                            <div class="history__box_details--datetime">
                                {{ item.display_date }}
                            </div>
                        </div>
                        <div class="history__box_items" v-if="!item.isExpanded">
                            <div  @click="openDetail(item.hash_id)" class="history__box_items-view" v-if="!item.checkProgress">
                                <span>{{ $t('view_more') }}</span>
                                <img src="/images/icons/arrow-right.png" />
                            </div>
                            <div class="history__box_item" v-if="item.items.length > 0">
                                <div class="history__box_thumbnail">
                                    <img :src="item.items[0].product_image != null ? item.items[0].product_image : require('/public/images/sample/no-image.png')">
                                </div>
                                <div class="history__box_desc">
                                    <label>{{ item.items[0].product_description_name != null ? item.items[0].product_description_name : item.items[0].product_name }}</label>
                                    <div v-if="item.items[0].product_type == 'configurable'">
                                        <span>{{ item.items[0].variantItem }}</span>
                                    </div>
                                    <div v-if="item.items[0].product_type == 'configurable_bundle'">
                                        <span v-for="(bundle, index) in item.items[0].bundle_items" :key="index">
                                            {{ '- ' + bundle.name + ' x' + bundle.bundle_quantity }}
                                        </span>
                                    </div>
                                    <div v-if="item.items[0].product_type == 'bundle'">
                                        <span v-for="(bundle, index) in item.items[0].bundle_items" :key="index">
                                            {{ '- ' + bundle.product_description_name + ' x' + bundle.bundle_quantity }}<br />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="history__box_item--loadmore" v-if="item.items.length > 1"
                                @click="loadMoreProduct(item)">
                                {{ $t('order_history.more_item1') }} {{ item.items.length - 1 }} {{
                $t('order_history.more_item') }}</div>
                        </div>
                        <div class="history__box_items" v-if="item.isExpanded">
                            <div @click="openDetail(item.hash_id)" class="history__box_items-view" v-if="!item.checkProgress">
                                <span>{{ $t('view_more') }}</span>
                                <img src="/images/icons/arrow-right.png" />
                            </div>
                            <div class="history__box_item" v-for="(product, index) in item.items" :key="index">
                                <div class="history__box_thumbnail">
                                    <img :src="product.product_image != null ? product.product_image : require('/public/images/sample/no-image.png')">
                                </div>
                                <div class="history__box_desc">
                                    <label>{{ product.product_description_name != null ? product.product_description_name : product.product_name }}</label>
                                    <div v-if="product.product_type == 'configurable'">
                                        <span>{{ product.variantItem }}</span>
                                    </div>
                                    <div v-if="product.product_type == 'configurable_bundle'">
                                        <span v-for="(bundle, index) in product.bundle_items" :key="index">
                                            {{ '- ' + bundle.name + ' x' + bundle.bundle_quantity }}
                                        </span>
                                    </div>
                                    <div v-if="product.product_type == 'bundle'">
                                        <span v-for="(bundle, index) in product.bundle_items" :key="index">
                                            {{ '- ' + bundle.product_description_name + ' x' + bundle.bundle_quantity }}<br />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="history__box_action">
                        <div class="history__box_summary">
                            <span>{{ $t('order_history.total') }}</span>
                            <label>{{ item.display_total }}</label>
                        </div>
                        <div class="history__box_buttons">
                            <!-- <router-link v-if="item.reOrderButton == true"
                                :to="this.$store.state.multiLanguage + 'menu'" class="btn btn-secondary">{{
                $t('order_history.reorder') }}</router-link> -->
                            <router-link v-if="item.checkProgress"
                                :to="this.$store.state.multiLanguage + 'order_details?order_id=' + item.hash_id"
                                class="btn btn-primary">{{ $t('order_history.check_progress') }}</router-link>

                            <button v-if="!item.checkProgress && item.status != 'cancelled' && item.status != 'failed'"
                @click="reviewOrder(item)" :class="'btn btn-secondary' + (item.is_reviewed ? ' reviewed' : '')">{{ $t('order_details.rate') }}</button>
                            <div v-if="item.reOrderButton == true" @click="reorderItem(item)"
                                :to="this.$store.state.multiLanguage + 'menu'" class="btn btn-primary">{{
                $t('order_history.reorder') }}</div>
                        </div>
                    </div>
                </div>
                <p class="points__history-loadmore" v-if="allDataLoaded == false" @click="loadMore()">{{ $t('load_more')
                    }}
                </p>
            </section>
            <section class="history__listing" v-if="onlineOrders == ''">
                <p>{{ $t('order_history.no_order_history') }}</p>
            </section>

        </div>
    </div>

    <Loader_component :loading="showLoader" :isFullPage="true" />
    <ReviewView v-if="orderData != null" :popupData="orderData" @close="closeReview()" />
</template>

<script>
import ReviewView from '@/views/ReviewView.vue';
import Loader_component from "@/include/loader_component.vue";
import { getAPI, postAPI } from "@/js/general";
import moment from "moment";
import { displayPrice } from "@/js/app";

export default {
    name: 'HistoryView',
    data() {
        return {
            orderData: null,
            showLoader: false,
            totalOrders: 0,

            limit: 10,
            allDataLoaded: false,

            onlineOrders: '',
        }
    },
    async created() {
        this.$store.state.showFooter = true;
        this.showLoader = true;
        await this.getOrderHistory();
        if (this.limit >= this.totalOrders) {
            this.allDataLoaded = true;
        }

        this.showLoader = false;
    },
    mounted() {

    },
    components: {
        Loader_component,
        ReviewView
    },
    methods: {
        reviewOrder(item) {
            this.orderData = item;
        },
        closeReview() {
            this.orderData = null;
        
        },
        async clearCart() {
            var clearResponse = await getAPI('/cart/clear');
            if (clearResponse.code == 'success') {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('success_clear_cart'),
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            }
        },
        async reorderItem(order) {
            this.$swal.fire({
                position: "top",
                text: this.$t('reorder.text'),
                icon: 'error',
                showCancelButton: true,
                confirmButtonText: this.$t('reorder.yes'),
                cancelButtonText: this.$t('reorder.no'),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await this.clearCart();
                    var UserUpdateOrderResponse = await postAPI("/user/order/update", {
                        order_id: order.id,
                        action: 'reorder',
                        outlet_id: order.outlet_id,
                        // language_id: this.$store.state.language_id[this.$i18n.locale],
                    });

                    if (UserUpdateOrderResponse.code == 'success') {
                        localStorage.order = order.is_pickup == 1 ? 'pickup' : 'delivery';
                        localStorage.outlet_id = order.outlet_id;
                        this.$router.push(`${this.$store.state.multiLanguage}` + 'order-confirmation');
                    } else {
                        this.$swal.fire({
                            toast: true,
                            position: "top",
                            text: UserUpdateOrderResponse.message,
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 2000,
                            didOpen: (toast) => {
                                toast.addEventListener('click', () => {
                                    this.$swal.close();
                                });
                            }
                        });
                    }
                }
            });
        },
        async loadMoreProduct(order) {
            order.isExpanded = true;
        },
        openDetail(hash) {
            this.$router.push(this.$store.state.multiLanguage + 'order_details?order_id=' + hash);
        },
        loadMore() {
            this.limit += 4;
            this.getOrderHistory();

            if (this.limit >= this.totalOrders) {
                this.allDataLoaded = true;
            }
        },
        async getOrderHistory() {
            this.showLoader = true;
            var UserOrderResponse = await getAPI('/user/orders', {
                limit: this.limit,
                from_platform: 'miniapp'
                // language_id: this.$store.state.language_id[this.$i18n.locale],
            });

            if (UserOrderResponse.code == 'success') {
                this.onlineOrders = UserOrderResponse.data.orders;

                this.onlineOrders.forEach((item) => {
                    item.reOrderButton = false;
                    item.checkProgress = false;
                    if (item.status === 'delivered' || item.status === 'cancelled' || item.status === 'failed' || item.status === 'collected' || item.status == 'closed') {
                        item.reOrderButton = true;
                        item.checkProgress = false;
                    } else {
                        item.reOrderButton = false;
                        item.checkProgress = true;
                    }

                    item.display_date = moment(item.created_at).format("YYYY/MM/DD, h:mmA");
                    item.display_total = displayPrice(item.paid_amount);
                    item.isExpanded = false;
                    item.displayOrders = [];

                    item.items.forEach((product) => {
                        product.variantItem = '';
                        var selectedVariant = [];
                        if (product.product_type == 'configurable') {
                            product.selected_variant.forEach((variant) => {
                                selectedVariant.push(variant.variant_description_name);
                            });
                            product.variantItem = selectedVariant.join(", ");
                        }
                    })
                });
                this.totalOrders = UserOrderResponse.data.orders_count;
            }
            this.showLoader = false;
        },
    }
}
</script>
<template>
	<div class="frame page-landing overlay">
		<section class="selected" section-step="1">
			<div class="page-container">
				<div class="page-row text-center">
					<img src="/images/logo.png" class="page-landing__logo">
				</div>
				<div class="page-row text-center">
					<label>您已达到法定饮酒年龄吗?</label>
					<label>Are you of legal drinking age?</label>
				</div>
				<div class="page-row row-even">
					<button @click="grantAccess()" class="btn btn-secondary">Yes</button>
					<button class="btn btn-secondary">No</button>
				</div>
			</div>
		</section>
	</div>
</template>

<script>

export default {
	name: 'LandingView',
	data() {
		return {
		};
	},
	created() {
		this.$store.state.showFooter = false;
		this.$store.state.legalAccept = localStorage.getItem('legalCondition');
	},
	mounted() {

	},
	components: {

	},
	methods: {
		grantAccess() {
			this.$store.state.legalAccept = true;
			localStorage.setItem('legalCondition', this.$store.state.legalAccept);
			this.$store.state.legalAccept = localStorage.getItem('legalCondition');
			// this.$router.push(`${this.$store.state.multiLanguage}`);
		}
	}
}
</script>

<style scoped>
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0px;
	margin: auto;
	max-width: 414px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	/* Ensure the overlay is on top of other content */
	overflow-y: auto;
}

@media screen and (max-width: 500px) {
	.overlay {
		max-width: 100%;
	}
}
</style>
<template>
    <div class="frame page-register">
        <div class="page-container" v-if="!showOTP">
            <div class="page-row text-center">
                <img src="/images/logo.png" class="page-register__logo">
            </div>
            <div class="page-row text-center">
                <label>{{ $t('register.create') }}</label>
            </div>
            <div class="page-row">
                <div class="page-row-subtitle">{{ $t('register.full_name') }}</div>
                <input type="text" v-model="username" class="input-primary"
                    :placeholder="$t('register.full_name_placeholder')">
            </div>
            <div class="page-row">
                <div class="page-row-subtitle">{{ $t('register.gender') }}</div>
                <div class="input-group">
                    <button @click="genderSelected($event)" :class="genderType == 'm' ? 'selected' : ''"
                        otp-select="gender" value="m">{{ $t('register.male') }}</button>
                    <button @click="genderSelected($event)" :class="genderType == 'f' ? 'selected' : ''"
                        otp-select="gender" value="f">{{ $t('register.female') }}</button>
                </div>
            </div>
            <div class="page-row">
                <div class="page-row-subtitle">{{ $t('register.birthday') }}</div>
                <v-date-picker v-model="dob" :masks="masks">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input class="bg-white border px-2 py-1 rounded input-primary" :placeholder="$t('dob_label')"
                            :value="inputValue" v-on="inputEvents" />
                    </template>
                </v-date-picker>
            </div>
            <div class="page-row">
                <div class="page-row-subtitle">{{ $t('register.mobile_no') }}</div>
                <div class="input-primary number_input">
                    <vue-tel-input :defaultCountry="'MY'" v-on:keypress="phoneOnly"
                        :inputOptions="{ placeholder: $t('phone_label'), type: 'tel' }" v-on:country-changed="countryChanged"
                        v-model="phoneNumber" @input="handlePhoneInput" :autoFormat="false"></vue-tel-input>
                </div>
            </div>
            <div v-if="referral_code != ''" class="page-row">
                <div class="page-row-subtitle">Referral Code</div>
                <input type="text" v-model="referral_code" class="input-primary"
                    placeholder="Referral Code" disabled>
            </div>
            <div class="page-row padding-top-primary">
                <button class="btn btn-primary" @click="register()">{{ $t('register.submit') }}</button>
            </div>
        </div>
        <div class="page-container page-otp" v-if="showOTP">
            <div class="page-row text-center">
                <label>{{ $t('login.otp_sent') + ' ' + tempPhone }}</label>
            </div>
            <div class="page-row">
                <div class="page-row-subtitle">{{ $t('login.enter_otp') }}</div>
                <div class="input-group">
                    <input type="text" v-on:keypress="phoneOnly" maxlength="1" v-model="verifycode1" @input="next"
                        @paste="autofill" class="text-center" otp-number="1" pattern="[0-9]*" inputmode="numeric" textContentType="oneTimeCode">
                    <input type="text" v-on:keypress="phoneOnly" maxlength="1" v-model="verifycode2" @input="next"
                        @paste="autofill" class="text-center" otp-number="2" pattern="[0-9]*" inputmode="numeric" textContentType="oneTimeCode">
                    <input type="text" v-on:keypress="phoneOnly" maxlength="1" v-model="verifycode3" @input="next"
                        @paste="autofill" class="text-center" otp-number="3" pattern="[0-9]*" inputmode="numeric" textContentType="oneTimeCode">
                    <input type="text" v-on:keypress="phoneOnly" maxlength="1" v-model="verifycode4" @input="next"
                        @paste="autofill" class="text-center" otp-number="4" pattern="[0-9]*" inputmode="numeric" textContentType="oneTimeCode">
                </div>
            </div>
            <div class="page-row">
                <label class="text-right">
                    {{ $t('login.not_receive') }}
                    <span v-if="countStart" class="page-login__send-otp">{{ $t('resend_in') + ' ' + this.minutes + ' : ' + this.seconds
                        }}</span>
                    <span v-if="!countStart" class="page-login__send-otp" @click.prevent="resendOtp()">{{
            $t('login.resend_code') }}</span>
                </label>
                <label class="page-otp-logout" v-if="logOutOption">Click <span @click="logOut()">here</span> to log
                    out</label>
            </div>
        </div>
    </div>
</template>


<script>
import { registerAPI, verifyaccount } from '@/js/auth';
import { postAPI } from '@/js/general';
import { logoutAPI } from '@/js/auth';

export default {
    name: 'RegisterView',
    data() {
        return {
            genderType: '',
            username: '',
            dob: '',
            masks: {
                input: 'DD/MM/YYYY',
            },

            verifycode1: '',
            verifycode2: '',
            verifycode3: '',
            verifycode4: '',
            verifycode5: '',
            verifycode6: '',

            phoneNumber: '',
            countrycode: '',
            display_phone: '',
            timerCount: 80,
            minutes: '',
            seconds: '',
            countStart: false,

            login_error: '',
            showOTP: false,
            logOutOption: false,
            tempPhone: '',
            referral_code: null
        }
    },
    created() {
        this.referral_code = window.location.hash.substring(1);

        this.$store.state.showFooter = false;

        if (this.$store.state.isLoggedIn && (this.$store.state.user && this.$store.state.user.phone_verified_at != null)) {
            this.$router.push(`${this.$store.state.multiLanguage}` + 'dashboard');
        }

        if ((this.$store.state.user && this.$store.state.user.phone_verified_at == null)) {
            this.showOTP = true;
            this.tempPhone = this.$store.state.user.phone_new;
            this.logOutOption = true;
        }
    },
    computed: {
    },
    mounted() {
        if (localStorage.temp_register_number && localStorage.temp_register_number != '') {
           this.phoneNumber = localStorage.temp_register_number;
           let numString = this.phoneNumber;
            numString = numString.replace(/\D/g, '');
            let target_phone = parseInt(numString, 10);
            this.display_phone = this.countrycode + target_phone;
        } 
    },
    methods: {
        countryChanged(country) {
            this.countrycode = country.dialCode
        },
        handlePhoneInput(event) {
            // Remove all characters except numbers and the plus sign (+)
            const cleanedValue = event.target.value.replace(/[^0-9+]/g, '');
            this.phoneNumber = cleanedValue;
        },
        countDownTimer() {
            if (this.timerCount > 0) {
                setTimeout(() => {
                    this.timerCount -= 1
                    this.minutes = Math.floor((this.timerCount / 60) % 60);
                    this.seconds = Math.floor((this.timerCount) % 60);
                    if (this.seconds < "10") {
                        this.seconds = "0" + this.seconds;
                    }
                    this.countDownTimer()
                }, 1000)
            } else {
                this.countStart = false;
                this.timerCount = 80;
            }
        },
        async logOut() {
            await logoutAPI();
            window.location = `${this.$store.state.multiLanguage}` + 'login';
        },
        async resendOtp() {
            this.countStart = true;
            this.countDownTimer();
            var submitPhone = '';
            submitPhone = this.tempPhone == '' ? this.display_phone : this.tempPhone;

            var phoneVerifyCode = await postAPI("/user/requestUpdatePhone", {
                phone: submitPhone,
            });

            if (phoneVerifyCode.code == "success") {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('login.get_otp'),
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                }).then(() => {
                    this.$swal.close();
                });
            }
        },
        phoneOnly(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        genderSelected(e) {
            this.genderType = e.target.value;
        },
        async register() {

            if (this.phoneNumber != "") {

                let numString = this.phoneNumber;
                numString = numString.replace(/\D/g, '');
                let target_phone = parseInt(numString, 10);
                this.display_phone = this.countrycode + target_phone;
                this.tempPhone = this.display_phone;

                if (this.dob != '' && this.genderType != '') {

                    if (this.display_phone.trim()) {
                        this.login_error = '';
                        this.showLoader = true;

                        var registerResponse = await registerAPI(
                            this.username,
                            this.genderType,
                            this.display_phone,
                            this.dob,
                        );

                        if (registerResponse.code == 'success') {
                            this.countStart = false;
                            this.showOTP = true;
                            // this.phoneStep = 2;
                            // this.$swal.fire({
                            //     toast: true,
                            //     position: "top",
                            //     text: 'Register Success',
                            //     icon: 'success',
                            //     showConfirmButton: false,
                            //     timer: 2000,
                            // }).then(() => {
                            //     window.location = `${this.$store.state.multiLanguage}`;
                            // });
                        } else {
                            this.login_error = registerResponse.message ? registerResponse.message : 'Invalid Credentials.';
                        }
                        this.showLoader = false;
                    }
                } else {
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: this.$t('register.empty_dob_gender'),
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                }

            } else {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('login.empty_phone'),
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
                // $toast.open({
                //     message: 'Phone number cannot be empty',
                //     type: 'error',
                //     position: 'bottom-left'
                // });
            }
        },
        next(e) {
            if (!this.verifycode1 || !this.verifycode2 || !this.verifycode3 || !this.verifycode4) {
                if (e.inputType == 'insertText') {
                    e.target?.nextSibling?.focus();
                }

                if (e.inputType == 'deleteContentBackward') {
                    e.target?.previousSibling?.focus();
                }
            }
            if (this.verifycode1 && this.verifycode2 && this.verifycode3 && this.verifycode4) {
                this.verifyOTP();
            }
        },
        autofill(event) {
            event.preventDefault();
            const pasteData = event.clipboardData.getData('text');
            if (pasteData) {
                this.verifycode1 = pasteData[0];
                this.verifycode2 = pasteData[1];
                this.verifycode3 = pasteData[2];
                this.verifycode4 = pasteData[3];
            }

            this.verificationCode = this.verifycode1 + this.verifycode2 + this.verifycode3 + this.verifycode4;

            if (this.verifycode1 && this.verifycode2 && this.verifycode3 && this.verifycode4) {
                this.verifyOTP(this.$store.state.verifyType);
            }
        },
        async verifyOTP() {
            this.register_error = '';
            this.showLoader = true;
            this.registration_type = 'phone';
            var registerResponse;
            let verification_code = this.verifycode1 + this.verifycode2 + this.verifycode3 + this.verifycode4;
            if (this.registration_type == 'phone') {

                let numString = this.phoneNumber;
                numString = numString.replace(/\D/g, '');
                let target_phone = parseInt(numString, 10);

                this.display_phone = this.countrycode + target_phone;
                var submitPhone = '';
                submitPhone = this.tempPhone == '' ? this.display_phone : this.tempPhone;

                registerResponse = await verifyaccount(submitPhone, this.registration_type, verification_code);
            } else {
                registerResponse = await verifyaccount(this.fieldDataEmail, this.registration_type, verification_code);
            }

            if (registerResponse.code == 'success') {
                if (window.location.hash) {
                    window.location = '/#' + this.referral_code;
                } else {
                    window.location = '/';
                }
                this.verifyError = false;
            } else {
                this.register_error = this.$t('verification.wrong_authenticator');
                this.verifyError = true;
            }
            this.showLoader = false;
        },
    }
}
</script>

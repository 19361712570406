<template>

    <div class="frame">
        <div class="page page-add-address" v-show="!addressConfirm">
            <router-link :to="this.$store.state.multiLanguage + 'menu'" class="link-back"><img
                    src="/images/icons/back.png"></router-link>
            <section class="address__map">
                <h1>{{ $t('add_address.add_address') }}</h1>
                <div class="address__search">
                    <div class="address__searchbox">
                        <input type="text" id="input-search-address" v-model="input_search_address">
                    </div>
                </div>
                <div class="address__map_action--top">
                    <button class="btn" @click="locate()">{{ $t('add_address.current_location') }}
                        <img :class="{ ' fade-out': !addressMapLoadingVisible }" id="address-map-loading"
                            src="/images/loading.png" class="loading float-right">
                    </button>
                </div>
                <div id="map-default" class="address__google_map"></div>
                <!-- 					<div class="address__map_info">
						<div class="address__map_info--address">
							Selected Address: <span id="display-address"></span>
						</div>
						<div class="address__map_info--geoinfo" style="display: none;">
							Latitude / Longitude: <span id="display-geoinfo"></span>
						</div>
					</div> -->
                <div class="address__map_action">
                    <button class="btn btn-primary " @click="confirmAddress()"
                        :class="this.currentAddress == null ? 'disabled' : ''">{{ $t('add_address.confirm_location')
                        }}<img id="address-confirm-loading" src="/images/loading.png"
                            class="loading float-right"></button>
                </div>
            </section>
        </div>
        <div class="page page-add-addressinfo" :class="addressConfirm ? ' visible' : ''">
            <a href="javascript:void(0);" @click="backToAddMapPage()" class="link-back" v-if="!showPopUp"><img
                    src="/images/icons/back.png"></a>
            <section class="address__info">
                <h1>{{ $t('add_address.confirm_address') }}</h1>
                <div class="address__info_form">
                    <div class="page-container">
                        <div class="page-row">
                            <div class="address__info_preview_map" id="preview-map"></div>
                        </div>
                        <div class="page-row">
                            <div class="page-row-subtitle">{{ $t('add_address.address_name') }}</div>
                            <input type="text" class="input-primary" id="confirm-name" v-model="addressName">
                        </div>
                        <div class="page-row row-even">
                            <div class="margin-right-5">
                                <div class="page-row-subtitle">{{ $t('add_address.unit_no') }}</div>
                                <input type="text" class="input-primary" id="confirm-unit" v-model="unitNo">
                            </div>
                            <div class="margin-left-5">
                                <div class="page-row-subtitle">{{ $t('add_address.floor') }}</div>
                                <input type="text" class="input-primary" id="confirm-floor" v-model="floor">
                            </div>
                        </div>
                        <div class="page-row">
                            <div class="page-row-subtitle">{{ $t('add_address.street_name') }}</div>
                            <input type="text" v-model="streetName" class="input-primary" id="confirm-street">
                        </div>
                        <div class="page-row">
                            <div class="page-row-subtitle">{{ $t('add_address.city_town') }}</div>
                            <input type="text" v-model="city" class="input-primary" id="confirm-city" disabled>
                        </div>
                        <div class="page-row row-even">
                            <div class="margin-right-5">
                                <div class="page-row-subtitle">{{ $t('add_address.post_code') }}</div>
                                <input type="text" v-model="postcode" class="input-primary" id="confirm-postalcode">
                            </div>
                            <div class="margin-left-5">
                                <div class="page-row-subtitle">{{ $t('add_address.state') }}</div>
                                <input type="text" v-model="state" class="input-primary" id="confirm-state" disabled>
                            </div>
                        </div>
                        <div class="page-row">
                            <div class="page-row-subtitle">{{ $t('add_address.remark') }}</div>
                            <textarea class="input-primary" rows="3" v-model="remark"></textarea>
                        </div>
                        <div class="address__map_action">
                            <button class="btn btn-primary" @click="openPopUp()">{{ $t('add_address.add_address')
                                }}</button>
                        </div>
                    </div>
                </div>
                <div class="popup" id="popup">
                    <div class="popup__box">
                        <div class="popup__header">
                            <label class="popup__header_label_center">{{ $t('add_address.confirm_address') }}</label>
                        </div>
                        <div class="popup__body">
                            <img class="popup__body_confirm_address_icon" src="/images/icons/location-on.svg"/>
                            <p class="popup__body_confirm_address_text">{{ $t('add_address.description') }}</p>
                        </div>
                        <div class="popup__action">
                            <button class="btn" id="popup__action--positive" @click="addAddress()">{{ $t('add_address.confirm') }}</button>
                            <button class="btn" id="popup__action--negative" @click="closePopup()">{{ $t('add_address.edit') }}</button>
                        </div>
                    </div>
                </div>
               
            </section>
        </div>
    </div>

    <Loader_component :loading="showLoader" :isFullPage="true" />
</template>


<script>
import { postAPI } from '@/js/general';
import Loader_component from "@/include/loader_component.vue";

export default {
    name: 'AddAddress',
    data() {
        return {
            map: null,
            previewMap: null,
            marker: null,
            previewMarker: null,
            autocomplete: null,
            geocoder: null,
            currentAddress: null,
            previewLatitude: null,
            previewLongitude: null,
            addressMapLoadingVisible: true,
            addressConfirm: false,
            showLoader: false,

            streetName: '',
            city: '',
            postcode: '',
            stateId: '',
            countryId: '',

            state: '',
            country: '',
            states: [],
            countries: [],

            remark: '',
            floor: '',
            unitNo: '',
            addressName: '',

            input_search_address: '',
            deniedLocationError: '',
            positionGet: '',
            showPopUp: false
        }
    },
    created() {
        this.$store.state.showFooter = false;
    },
    computed: {
    },
    components: {
        Loader_component
    },
    async mounted() {
        this.showLoader = true;
        this.loadGoogleMaps(() => {
            this.initMap();
        });
        this.states = this.$store.state.states;
        this.countries = this.$store.state.countries;

        try {
            const position = await this.getPosition();
            this.positionGet = position;
        } catch (error) {
            console.error('Error getting location:', error);
            this.deniedLocationError = error.message;
        }
        this.showLoader = false;
    },
    methods: {
        loadGoogleMaps(callback) {
            if (!window.google) {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAb-w4mJq1Bh6ZmEqkGmX5BynbAF2XEAvk&libraries=places';
                script.onload = callback;
                document.body.appendChild(script);
            } else {
                callback();
            }
        },
        initMap() {
            const mapOptions = {
                zoom: 13,
                scrollwheel: true,
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                center: { lat: 1.5067307134187238, lng: 110.35908447725053 },
                mapTypeId: window.google.maps.MapTypeId.ROADMAP
            };

            this.map = new window.google.maps.Map(document.getElementById('map-default'), mapOptions);

            const previewMapOptions = {
                draggable: false,
                zoomControl: false,
                scrollwheel: false,
                disableDoubleClickZoom: true,
                mapTypeControl: false,
                streetViewControl: false,
                mapTypeId: window.google.maps.MapTypeId.ROADMAP
            };

            this.previewMap = new window.google.maps.Map(document.getElementById("preview-map"), previewMapOptions);

            const input = document.getElementById("input-search-address");
            const options = {
                fields: ["formatted_address", "geometry", "name"],
                strictBounds: false
            };

            this.autocomplete = new window.google.maps.places.Autocomplete(input, options);
            this.autocomplete.bindTo("bounds", this.map);

            this.geocoder = new window.google.maps.Geocoder();

            window.google.maps.event.addListener(this.map, 'click', this.mapclickhandler);
            window.google.maps.event.addListener(this.previewMap, 'click', this.mapclickhandler);

            this.marker = new window.google.maps.Marker({
                position: { lat: 1.5067307134187238, lng: 110.35908447725053 },
                map: this.map,
                animation: window.google.maps.Animation.DROP,
                draggable: true
            });

            this.previewMarker = new window.google.maps.Marker({
                position: { lat: 1.5067307134187238, lng: 110.35908447725053 },
                map: this.previewMap,
                animation: window.google.maps.Animation.DROP,
                draggable: true
            });

            window.google.maps.event.addListener(this.marker, 'dragend', this.draghandler);
            window.google.maps.event.addListener(this.previewMarker, 'dragend', this.draghandler);

            this.map.setCenter(this.marker.position);
            this.previewMap.setCenter(this.previewMarker.position);

            this.autocomplete.addListener("place_changed", this.placeChangedHandler);

            this.locate();
        },
        mapclickhandler(e) {
            this.findlocation(e);
        },
        draghandler(e) {
            this.findlocation(e);
            this.map.panTo(e.latLng);
        },
        findlocation(e) {
            this.geocoder.geocode({ 'location': e.latLng }, (results, status) => {
                this.evtcallback(results, status, e.latLng);
            });
        },
        evtcallback(results, status) {
            if (status == window.google.maps.GeocoderStatus.OK) {
                const _address = results[0].formatted_address;
                const _location = results[0].geometry.location;
                this.currentAddress = results[0];

                this.marker.address = _address;
                this.marker.location = _location;
                this.previewMarker.address = _address;
                this.previewMarker.location = _location;

                const lat = _location.lat();
                const lng = _location.lng();

                this.populate(lat, lng, _address);

                const latlng = new window.google.maps.LatLng(lat, lng);
                this.marker.setPosition(latlng);
                this.previewMarker.setPosition(latlng);
                this.map.setCenter(latlng);
                this.previewMap.setCenter(latlng);
            }
        },
        placeChangedHandler() {
            this.marker.setVisible(false);
            this.previewMarker.setVisible(false);

            const place = this.autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
                window.alert("No details available for input: '" + place.name + "'");
                return;
            }

            if (place.geometry.viewport) {
                this.map.fitBounds(place.geometry.viewport);
            } else {
                this.map.setCenter(place.geometry.location);
                this.map.setZoom(17);
            }

            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();

            this.previewLatitude = lat;
            this.previewLongitude = lng;

            this.marker.setPosition(place.geometry.location);
            this.marker.setVisible(true);

            const latlng = new window.google.maps.LatLng(lat, lng);

            this.geocoder.geocode({ 'location': latlng }, (results, status) => {
                this.evtcallback(results, status, latlng);
            });
        },
        populate(lat, lng, addr) {
            this.input_search_address = addr;
            this.previewLatitude = lat;
            this.previewLongitude = lng;
        },
        async getPosition() {
            return new Promise((resolve, reject) => {
                const success = (position) => {
                    resolve(position.coords);
                };
                const error = (err) => {
                    reject(err);
                };
                navigator.geolocation.getCurrentPosition(success, error);
            });
        },
        locate() {
            this.addressMapLoadingVisible = false;

            if (this.deniedLocationError == 'User denied Geolocation') {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('location_denied'),
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 5000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            }

            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(position => {
                    const currentLatitude = position.coords.latitude;
                    const currentLongitude = position.coords.longitude;

                    const lat = parseFloat(currentLatitude);
                    const lng = parseFloat(currentLongitude);
                    const latlng = new window.google.maps.LatLng(lat, lng);
                    const geocoder = new window.google.maps.Geocoder();

                    geocoder.geocode({ 'latLng': latlng }, (results, status) => {
                        if (status == window.google.maps.GeocoderStatus.OK) {
                            if (results[1]) {
                                const addr = results[0].formatted_address;
                                this.currentAddress = results[0];

                                // Update your map and marker here
                                // Assuming you have access to this.map and this.marker
                                this.map.setCenter({ lat: currentLatitude, lng: currentLongitude });
                                this.map.setZoom(17);
                                const markerLatLng = new window.google.maps.LatLng(lat, lng);
                                this.marker.setPosition(markerLatLng);

                                // Update other variables as needed
                                this.previewLatitude = lat;
                                this.previewLongitude = lng;

                                // Update input value
                                this.$nextTick(() => {
                                    if (addr != null) {
                                        this.input_search_address = addr;
                                    }
                                });
                            }
                        }
                    });

                    setTimeout(() => {
                        this.addressMapLoadingVisible = true;
                    }, 1000);
                });
            }
        },
        openPopUp() {
            var display_addresses = [];
            if (this.unitNo !== '') display_addresses.push(this.unitNo);
            if (this.floor !== '') display_addresses.push(this.floor);
            if (this.streetName !== '') display_addresses.push(this.streetName);

            if (this.addressName != '' && this.postcode != '' && this.streetName != '') {
                this.showPopUp = true;
                document.getElementById('popup').style.display = 'block';
            } else {
                if (this.addressName == '') {
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: this.$t('add_address.address_name_empty'),
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                } else  if (this.streetName == '') {
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: this.$t('add_address.street_name_empty'),
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                } else if (this.postcode == '') {
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: this.$t('add_address.invalid_postcode'),
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                }
            }
        },
        closePopup() {
            this.showPopUp = false;
            document.getElementById('popup').style.display = 'none';
        },
        confirmAddress() {
            this.addressConfirm = true;

            const previewMapOptions = {
                zoom: 14,
                center: new window.google.maps.LatLng(this.previewLatitude, this.previewLongitude),
                disableDefaultUI: true,
                mapTypeId: window.google.maps.MapTypeId.ROADMAP
            };

            this.previewMap = new window.google.maps.Map(document.getElementById("preview-map"), previewMapOptions);

            this.previewMarker = new window.google.maps.Marker({
                position: { lat: this.previewLatitude, lng: this.previewLongitude, },
                map: this.previewMap,
                animation: window.google.maps.Animation.DROP,
                draggable: false
            });
            this.previewMap.setCenter(this.previewMarker.position);


            var display_address = '';
            var display_address2 = '';
            var display_address3 = '';
            var display_town = '';
            var display_postcode = '';
            var display_country = '';
            var display_state = '';

            this.currentAddress.address_components.forEach((address) => {
                address.types.forEach((_value) => {
                    if (_value == 'locality') {
                        display_town = address.long_name;
                    } else if (_value == 'route') {
                        display_address = address.long_name;
                    } else if (_value == 'sublocality') {
                        display_address2 = address.long_name;
                    } else if (_value == 'street_number') {
                        display_address3 = address.long_name;
                    } else if (_value == 'administrative_area_level_1') {
                        display_state = address.long_name;
                    } else if (_value == 'postal_code') {
                        display_postcode = address.long_name;
                    } else if (_value == 'country') {
                        display_country = address.long_name;
                    }
                })
            })

            var display_addresses = [];
            if (display_address3 !== '') display_addresses.push(display_address3);
            if (display_address !== '') display_addresses.push(display_address);
            if (display_address2 !== '') display_addresses.push(display_address2);

            this.streetName = display_addresses.join(", ");
            this.postcode = display_postcode;
            this.city = display_town;
            this.country = display_country;
            this.state = display_state;

            if (this.state == '' || this.country == '') {
                //
            } else {
                this.stateId = this.states.find((state) => state.name == this.state).id;
                this.countryId = this.states.find((state) => state.name == this.state).country_id;
            }

        },
        backToAddMapPage() {
            this.addressConfirm = false;
        },
        async addAddress() {
            var display_addresses = [];
            var addressLine = '';
            if (this.unitNo !== '') display_addresses.push(this.unitNo);
            if (this.floor !== '') display_addresses.push(this.floor);
            if (this.streetName !== '') display_addresses.push(this.streetName);

            addressLine = display_addresses.join(", ");

            var firstname = this.$store.state.user.firstname;

            if (this.addressName != '' && this.postcode != '' && this.streetName != '') {
                const data = {
                    label: this.addressName,
                    default_billing_address: 0,
                    default_shipping_address: 0,
                    firstname: firstname,
                    lastname: "",
                    email: "",
                    phone: this.$store.state.user.phone,
                    address_line_1: addressLine,
                    address_line_2: "",
                    postal_code: this.postcode,
                    city: this.city,
                    state_id: this.stateId ? this.stateId : null,
                    country_id: this.countryId ? this.countryId : null,
                    remark: this.remark,
                    latitude: this.previewLatitude,
                    longitude: this.previewLongitude
                }

                // this.$swal.fire({
                //     position: "top",
                //     text: this.$t('add_address.confirm_correct'),
                //     icon: 'info',
                //     showCancelButton: true,
                //     confirmButtonText: this.$t('add_address.yes'),
                //     cancelButtonText: this.$t('add_address.no')
                // }).then(async (result) => {
                //     if (result.isConfirmed) {
                        var response = await postAPI('/user/address/add', data);

                        if (response.code == 'success') {
                            // localStorage.addressSelected.id = JSON.stringify(response.data);
                            this.$router.push(this.$store.state.multiLanguage + 'menu');
                            this.$swal.fire({
                                toast: true,
                                position: "top",
                                text: this.$t('add_address.success_added'),
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 2000,
                                didOpen: (toast) => {
                                    toast.addEventListener('click', () => {
                                        this.$swal.close();
                                    });
                                }
                            });
                        } else {
                            this.$swal.fire({
                                toast: true,
                                position: "top",
                                text: response.message,
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 2000,
                                didOpen: (toast) => {
                                    toast.addEventListener('click', () => {
                                        this.$swal.close();
                                    });
                                }
                            });
                        }
                //     }
                // });
            } else {
                if (this.addressName == '') {
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: this.$t('add_address.address_name_empty'),
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                } else  if (this.streetName == '') {
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: this.$t('add_address.street_name_empty'),
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                } else if (this.postcode == '') {
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: this.$t('add_address.invalid_postcode'),
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                }
            }


        }
    }
}
</script>

<style scoped>
.fade-out {
    display: block;
    transition: 0.5 0.5s ease;
    /* Adjust timing and easing as needed */
}
</style>
<template>
    <div class="frame">
        <div class="page page-home">
            <section class="home__banner">
                <swiper :navigation="true" :modules="modules" :loop="true" :autoplay="{ delay: 3000 }" class="mySwiper">
                    <swiper-slide v-for="(item, index) in home_top" :key="index">
                        <a :href="item.url">
                        <img :src="item.image" alt="Banner Image" />
                        </a>
                    </swiper-slide>
                </swiper>
            </section>
            <section class="home__action">
                <div class="home__action_frame">
                    <div class="home__action_buttons">
                        <div class="home__action_button">
                            <router-link :to="this.$store.state.multiLanguage + 'menu?tab=delivery'">
                                <img :src="require('/public/images/icons/delivery.png')">
                                <label>{{ $t('order_beer') }}</label>
                                <span>{{ $t('enjoy_beer') }}</span>
                            </router-link>
                        </div>
                        <div class="home__action_button">
                            <router-link :to="this.$store.state.multiLanguage + 'event'">
                                <img :src="require('/public/images/icons/event.png')">
                                <label>{{ $t('plan_event') }}</label>
                                <span>{{ $t('experience_the_best') }}</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="home__action_label" v-if="false">
                        {{ $t('free_delivery') }}
                    </div>
                </div>
            </section>
            <div class="popup" id="popup" v-show="home_popup">
                <div class="popup-image">
                    <div class="popup-image-close" @click="closepopup()"><img src="/images/icons/close.png"></div>
                    <a :href="item.url" v-for="(item, index) in home_popup" :key="index">
                        <img :src="item.image">
                    </a>
                </div>
            </div>
            <section class="home__profile">
                <div class="home__profile_frame">
                    <div class="home__profile_thumbnail">
                        <div class="home__profile_thumbnail_frame">
                            <img :src="require('/public/images/sample/profile.png')">
                        </div>
                    </div>
                    <div class="home__profile_name">
                        <label>{{ this.$store.state.user != null ? this.$store.state.user.firstname : '-' }}</label>
                        <span>{{ memberTier }}</span>
                    </div>
                    <div class="home__profile_stats">
                        <div class="home__profile_stats--points">
                            <label>{{ pointAvailable }} pts</label>
                            <span>{{ $t('points') }}</span>
                        </div>
                        <div class="home__profile_stats--voucher">
                            <label>{{ couponCount }}</label>
                            <span>{{ $t('coupons') }}</span>
                        </div>
                    </div>
                </div>
            </section>
            <section class="home__featured">
                <div class="home__featured_banner" v-for="(home_banner, index) in home_banners" :key="index">
                    <a :href="home_banner.url">
                        <img :src="home_banner.image">
                    </a>
                </div>>
            </section>
            <section class="home__footer">
                <img :src="require('/public/images/logo.png')">
            </section>
        </div>
    </div>
	
    <Loader_component :loading="showLoader" :isFullPage="true" />
</template>

<script>
import { getAPI, postAPI } from '@/js/general';
import Loader_component from "@/include/loader_component.vue";
import { initializeApp } from "firebase/app";
import { firebaseConfig, vapidKey } from "@/config/env.js";
import { getMessaging, getToken } from "firebase/messaging";

import { Swiper, SwiperSlide } from 'swiper/vue';


import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules'; 

export default {
    name: 'HomeView',
    data() {
        return {
            pointAvailable: 0,
            couponCount: 0,
            showLoader: false,
            memberTier: '',
            showPopup: false,
            blocks: null,
            home_top: null,
            home_banners: [],
            home_popup: null,
        }
    },
    components: {
        Loader_component,
        Swiper,
        SwiperSlide
    },
    setup() {
      return {
        modules: [Navigation, Autoplay],
      };
    },
    async created() {
        this.$store.state.showFooter = true;
        this.showLoader = true;
        await this.getBlock();
        if (this.$store.state.isLoggedIn == true) {
            await this.getUserPoint();
            await this.getUserCoupon();
            await this.getUserExp();
        }
        this.showLoader = false;
    },
    computed: {
    },
    async mounted() {
        this.referral_code = window.location.hash.substring(1); 
        localStorage.setItem('referral_code', this.referral_code);
        this.getDeviceToken();
    },
    methods: {
        closepopup () {
            document.getElementById('popup').style.display = 'none';
        },
        async shouldShowPopup() {
            const lastOpened = localStorage.getItem('popupLastOpened');
            if (lastOpened) {
                const now = new Date().getTime();
                const tenMinutes = 10 * 60 * 1000;

                if (now - lastOpened > tenMinutes) {
                    return true;
                } else {
                    return false;
                }
            }  
            return true;
        },
        async openPopup() {
          
            if (this.home_popup !== null) {
                
                document.getElementById('popup').style.display = 'block';  
                localStorage.setItem('popupLastOpened', new Date().getTime());
            }
        },
        async getBlock() {
            const response = await fetch('https://static.1602craftbeer.com/lib/block-home-en.json');
            const BlockResponse = await response.json();
            if (BlockResponse.code == "success") {
                this.showLoader = false;
                this.blocks = BlockResponse.data;
                if (this.blocks && this.blocks.HOME_TOP && this.blocks.HOME_TOP.length > 0 && this.blocks.HOME_TOP[0]) {
                    this.home_top = this.blocks.HOME_TOP;
                }

                if (this.blocks && this.blocks.HOME_BANNERS && this.blocks.HOME_BANNERS.length > 0) {
                    this.home_banners = this.blocks.HOME_BANNERS;
                }

                if (this.blocks && this.blocks.HOME_POPUP && this.blocks.HOME_POPUP.length > 0) {
                // const randomIndex = Math.floor(Math.random() * this.blocks.HOME_POPUP.length);
                // this.home_popup = this.blocks.HOME_POPUP[randomIndex];
                    this.home_popup = this.blocks.HOME_POPUP
                    if (await this.shouldShowPopup()) {
                        await this.openPopup();
                    }
                }
            }
        },
        async getDeviceToken() {
            initializeApp(firebaseConfig);
            const messaging = getMessaging();
            
            try {
                const currentToken = await getToken(messaging, { vapidKey: vapidKey });
                
                if (currentToken) {
                    console.log('Device Token Retrieved: ', currentToken);
                    const data = { device_token: currentToken };
                    await postAPI('/user/notification/add', data);
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            } catch (err) {
                console.log('An error occurred while retrieving token. ', err);
            }
        },

        async getUserExp() {
            var userExpResponse = await getAPI('/user/exp');

            if (userExpResponse.code == 'success') {
                this.memberTier = userExpResponse.data.member_tier;
            }
        },
        async getUserPoint() {
            var userResponse = await getAPI('/user/point');

            var pointData = [];
            if (userResponse.code == 'success') {
                pointData = userResponse.data;
                this.pointAvailable = parseInt(pointData.point_balance).toLocaleString();
            }
        },
        async getUserCoupon() {
            var UserCouponResponse = await getAPI("/user/coupon", {
                limit: 1,
                // type: 'exchange',
                // language_id: this.$store.state.language_id[this.$i18n.locale],
                // redeemableOnly: 1,
                availableOnly: 1,
            });

            var couponData = [];
            if (UserCouponResponse.code == 'success') {
                couponData = UserCouponResponse.data;
                this.couponCount = parseInt(couponData.count).toLocaleString();
            }
        },
    }
}
</script>
<template>
    <div class="frame">
        <div class="page page-rrhistory" v-if="showHistory">
            <div class="link-back"><img src="/images/icons/back.png" @click="closeHistory()"></div>
            <h1>{{ $t('referral_history.title') }}</h1>
            <section class="rrhistory__histories">
                <div v-if="referralHistories != ''">
                    <div class="rrhistory__history" v-for="(referralHistory, index) in referralHistories" :key="index">
                        <label>{{ referralHistory.caption }}</label>
                        <div class="rrhistory__history_ref">{{ referralHistory.referral_member_name }}</div>
                        <div class="rrhistory__history_datetime">{{ referralHistory.created_at }}</div>
                    </div>
                </div>
                <div class="rrhistory__histories-no_record" v-if="referralHistories == ''">
                    <p>{{ $t('referral_reward.no_record') }}</p>
                </div>
            </section>
        </div>
        <div class="page page-referral-reward" v-else>
            <div class="link-back"><img src="/images/icons/back.png" @click="returnDashboard()"></div>
            <h1>{{ $t('referral_reward.title') }} <img src="/images/icons/info.png"></h1>  
            <h2>{{ $t('referral_reward.share_text') }}</h2>
            <div class="referral_reward_action__container">
                <section class="referral_reward__copy">
                    <div class="referral_reward__copy-box" @click="copyCode(referralCode)">{{ referralCode }}</div>
                </section>
                <router-link :to="this.$store.state.multiLanguage + 'referral_qrcode'">
                    <div class="referral_reward__qr">
                        <img src="../../public/images/icons/qr-code.svg"/>
                    </div>
                </router-link>
            </div>
            <section class="referral_reward__leader">
                <div class="referral_reward__row">
                    <div :class="'referral_reward__box ' + (referralReward.tier == '5' || referralReward.tier == '10' ? 'referral_reward__box2' : 'referral_reward__box') + (referralReward.status == '1' ? '--redeemed' : '') + (referralReward.claim_status == 'pending_claim' ? '--pending' : '')"
                        v-for="(referralReward, index) in referralRewards" :key="index" @click="collectCoupon()">
                        <span>{{ referralReward.tier }}</span>
                        <div v-for="(reward, index) in referralReward.rewards" :key="index">
                            <label>{{ reward.caption }}</label><br>
                        </div>
                    </div>
                </div>
                <div class="referral_reward__note">{{ $t('referral_reward.rewards_claimed') }}</div>
                <div class="referral_reward__info">
                    <label>{{ $t('referral_reward.how_works') }}</label>
                    <div class="referral_reward__info_boxes">
                        <div class="referral_reward__info_box">
                            <img src="/images/referral_reward/friends.png">
                            <label>{{ $t('referral_reward.share_code') }}</label>
                        </div>
                        <div class="referral_reward__info_box">
                            <img src="/images/referral_reward/beer.png">
                            <label>{{ $t('referral_reward.friend_signup') }} {{ $t('referral_reward.make_purchase')
                                }}</label>
                        </div>
                        <div class="referral_reward__info_box">
                            <img src="/images/referral_reward/reward.png">
                            <label>{{ $t('referral_reward.collect_voucher') }}</label>
                        </div>
                    </div>
                </div>
                <div class="referral_reward__actions">
                    <a @click="shareCode(referralCode)" target="_blank" class="referral_reward__action">
                        <button class="btn btn-primary">{{ $t('referral_reward.invite_now') }}</button>
                    </a>
                    <div class="referral_reward__action">
                        <button @click="redirectReferral()" class="btn btn-secondary">{{
            $t('referral_reward.referral_history') }}</button>
                    </div>
                </div>
                <label class="referral_reward__question">{{ $t('referral_reward.have_question') }} <router-link :to="this.$store.state.multiLanguage + 'faq'">{{ $t('referral_reward.view_our_faq') }}</router-link></label>
            </section>
        </div>
    </div>

    <Loader_component :loading="showLoader" :isFullPage="true" />
</template>


<script>
import Loader_component from "@/include/loader_component.vue";
import { getAPI, postAPI } from '@/js/general';
import moment from "moment";
export default {
    name: 'ReferralReward',
    data() {
        return {
            showLoader: false,
            referralRewards: [],
            referralCode: '',
            showHistory: false,
            referralHistories: [],
        }
    },
    async created() {
        await this.getReferralData();
        this.$store.state.showFooter = false;

        this.showLoader = true;
        var userResponse = await getAPI('/referral');

        if (userResponse.code == 'success') {
            this.referralRewards = userResponse.data.referral;
            this.referralCode = userResponse.data.referral_code;
            this.referralHistories = userResponse.data.referral_history;

            if (this.referralHistories) {
                this.referralHistories.forEach((history) => {
                    history.created_at = moment(history.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY, h:mmA");
                });
            }

        }
        this.showLoader = false;
    },
    components: {
        Loader_component
    },
    computed: {
    },
    mounted() {
    },
    methods: {
        shareCode(code) {
            // const baseUrl = 'https://api.whatsapp.com/send?text=';
            const message = this.$t('whatsapp.get_rewards');
            // const message2 = this.$t('whatsapp.code');
            const shop_url = "https://shop.1602craftbeer.com/#" + code;
            const combinedMessage = `${message}\n\n${shop_url}`;
            // const encodedMessage = encodeURIComponent(combinedMessage);

            if (!navigator.share) {
                alert('Web Share API not supported on this browser');
            }

            navigator.share({
                text: combinedMessage,
                title: "1602 Referral Program",
            });

            // localStorage.setItem('referral_code', code);

            // return `${baseUrl}${encodedMessage}`;
        },
        returnDashboard() {
            this.$router.push(`${this.$store.state.multiLanguage}` + 'dashboard');
        },
        async getReferralData() {
            var userResponse = await getAPI('/referral');

            if (userResponse.code == 'success') {
                this.referralRewards = userResponse.data.referral;
                this.referralCode = userResponse.data.referral_code;
                this.referralHistories = userResponse.data.referral_history;
                if (this.referralHistories.length > 0) {
                    this.referralHistories.forEach((history) => {
                        history.created_at = moment(history.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY, h:mmA");
                    });
                }
            }
        },
        redirectReferral() {
            this.showHistory = true;
        },
        closeHistory() {
            this.showHistory = false;
        },
        copyCode(referralCode) {
            const tempInput = document.createElement("input");
            tempInput.value = "https://shop.1602craftbeer.com/#" + referralCode;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);

            this.$swal.fire({
                toast: true,
                position: "top",
                text: this.$t('referral_reward.code_copied'),
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                didOpen: (toast) => {
                    toast.addEventListener('click', () => {
                        this.$swal.close();
                    });
                }
            });
        },
        async collectCoupon() {
            var userResponse = await postAPI('/referral/claim');
            if (userResponse.code == 'success') {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: 'Coupon(s) claimed and added to account.',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
                await this.getReferralData();
            } else {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: userResponse.message,
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            }
        }
    }
}
</script>

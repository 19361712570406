<template>
    <div class="frame">
        <div class="page page-points">
            <h1>{{ $t('point_history.title') }}</h1>
            <router-link :to="this.$store.state.multiLanguage + 'dashboard'" class="link-back"><img
                    src="/images/icons/back.png"></router-link>
            <section class="points__balance">
                <div class="points__balance_display">{{ userPoint.displayPoint }} Pts</div>
                <span>{{ userPoint.displayExpireSoonPoint }} {{ $t('rewards.point_expiry1') }} {{
                userPoint.displayExpiryDate }} {{ $t('rewards.point_expiry') }}</span>
            </section>
            <section class="points__histories">
                <h2>{{ $t('point_history.transaction_history') }}</h2>


                <div class="points__history_no" v-if="this.pointHistory == ''">
                    <p>{{ $t('point_history.no_transaction_history') }}</p>
                </div>

                <div class="points__history-container">
                    <div class="points__history" v-for="point in pointHistory" :key="point.id">     
                        <div class="points__history_left">
                            <label>{{ point.amount < 0 ?  $t('points_deducted') : $t('points_earned') }}</label>
                            <div class="points__history_ref">ID: #{{ point.id }}</div>
                            <div class="points__history_msg">{{ point.remark }}</div>
                            <div class="points__history_datetime">{{ point.displayDate }}</div>
                        </div>
                        <div class="points__history_right">
                            <div class="points__history_points "
                                :class="point.amount < 0 ? 'points__history_points--deduct' : ''">{{ point.amount < 0 ?
                    point.displayAmount : ('+ ' + point.displayAmount) }} Pts</div>
                            </div>
                        </div>
                    </div>
                    <p class="points__history-loadmore" v-if="allDataLoaded == false" @click="loadMore()">{{
                $t('load_more') }}</p>
            </section>
            <!-- <PaginationComponent :totalPages="totalPages" :perPage="limit" :currentPage="page"
                @pagechangedNext="onPageChangeNext" @pagechangedPrevious="onPageChangePrevious"
                @pagechanged="onPageChanged">
            </PaginationComponent> -->
        </div>
    </div>

    <Loader_component :loading="showLoader" :isFullPage="true" />
</template>


<script>
import { getAPI } from '@/js/general';
import moment from "moment";
// import PaginationComponent from "@/include/pagination-component.vue";
import Loader_component from "@/include/loader_component.vue";

export default {
    name: 'PointHistory',
    data() {
        return {
            showLoader: false,
            userPoint: '',
            pointHistory: [],
            allDataLoaded: false,
            limit: 4,
            offset: 0
        }
    },
    async created() {
        this.$store.state.showFooter = false;
        this.showLoader = true;

        await this.loadData();
        if (this.limit >= this.userPoint.points_count) {
            this.allDataLoaded = true;
        }
        this.showLoader = false;
    },
    components: {
        // PaginationComponent,
        Loader_component
    },
    computed: {
    },
    mounted() {
    },
    methods: {
        async loadData() {

            this.showLoader = true;

            var userResponse = await getAPI('/user/point', {
                limit: this.limit,
            });

            if (userResponse.code == 'success') {
                this.userPoint = userResponse.data;
                this.userPoint.displayExpiryDate = this.userPoint.expiry_soon_date != null ? moment(this.userPoint.expiry_soon_date).format("YYYY/MM/DD") : '-';
                this.userPoint.displayPoint = parseInt(this.userPoint.point_balance).toLocaleString();
                this.userPoint.displayExpireSoonPoint = parseInt(this.userPoint.expiry_soon_points).toLocaleString();

                this.userPoint.points.forEach((point) => {
                    point.displayDate = moment(point.created_at).format("YYYY/MM/DD, h:mmA");
                    point.displayAmount = parseInt(point.amount).toLocaleString();
                });

                this.pointHistory = this.userPoint.points;

                this.offset = this.limit;
            }
            this.showLoader = false;
        },
        loadMore() {
            this.limit += 4;
            this.loadData();

            if (this.limit >= this.userPoint.points_count) {
                this.allDataLoaded = true;
            }
        }

    }
}
</script>

<template>
    <div class="frame">
        <div class="page page-event fade-in">
            <a href="javascript:void(0)" @click="loadScreen(-1)" class="link-back"><img
                    src="/images/icons/back.png"></a>
            <section class="event__agreement" v-if="screenPage == 1" data-screen="1">
                <img src="/images/background-event-word.png" />

                <div class="event__agreement_box">
                    <span>{{ $t('event.agreement') }}</span>
                    <button class="btn btn-primary" @click="loadScreen()">{{ $t('event.start') }}</button>
                </div>
            </section>
            <section class="event__particular" :class="screenPage == 2 ? ' visible slideInAnimation' : ''"
                data-screen="2">
                <div class="event__particular_title">
                    <h2>1/3</h2>
                    <label>{{ $t('event.step_one.title') }}</label>
                </div>
                <div class="event__particular_box">
                    <div class="page-row">
                        <div class="page-row-subtitle">{{ $t('add_address.address_name') }}*</div>
                        <input type="text" class="input-primary" id="confirm-name" v-model="addressName"
                            :class="!requiredField && this.addressName == '' ? ' empty-input' : ''">
                    </div>
                    <div class="page-row row-even">
                        <div class="margin-right-5">
                            <div class="page-row-subtitle">{{ $t('add_address.unit_no') }}</div>
                            <input type="text" class="input-primary" id="confirm-unit" v-model="unitNo">
                        </div>
                        <div class="margin-left-5">
                            <div class="page-row-subtitle">{{ $t('add_address.floor') }}</div>
                            <input type="text" class="input-primary" id="confirm-floor" v-model="floor">
                        </div>
                    </div>
                    <div class="page-row">
                        <div class="page-row-subtitle">{{ $t('add_address.street_name') }}*</div>
                        <input type="text" v-model="streetName" class="input-primary" id="confirm-street"
                            :class="!requiredField && this.streetName == '' ? ' empty-input' : ''">
                    </div>
                    <div class="page-row row-even">
                        <div class="margin-right-5">
                            <div class="page-row-subtitle">{{ $t('add_address.city_town') }}*</div>
                            <input type="text" v-model="city" class="input-primary" id="confirm-city"
                                :class="!requiredField && this.city == '' ? ' empty-input' : ''">
                        </div>
                        <div class="margin-left-5">
                            <div class="page-row-subtitle">{{ $t('add_address.post_code') }}*</div>
                            <input type="text" v-on:paste="phoneOnly" v-on:keypress="phoneOnly" v-model="postcode" class="input-primary"
                                id="confirm-postalcode"
                                :class="!requiredField && this.postcode == '' ? ' empty-input' : ''" pattern="[0-9]*" inputmode="numeric">
                        </div>

                    </div>
                    <div class="page-row row-even page-country">
                        <div class="margin-right-5">
                            <div class="page-row-subtitle">{{ $t('add_address.state') }}*</div>
                            <v-select placeholder="States" class="input-primary"
                                :class="!requiredField && this.stateSelected == null ? ' empty-input' : ''"
                                id="confirm-state" label="name" :options="filteredStates('newAddress')"
                                v-model="stateSelected" :clearable="false"></v-select>
                        </div>
                        <div class="margin-left-5">
                            <div class="page-row-subtitle">{{ $t('add_address.country') }}*</div>
                            <v-select placeholder="Country" class="input-primary" label="display_name"
                                :options="filteredCountries" @input="search($event)" v-model="countrySelected"
                                :clearable="false"></v-select>
                        </div>
                    </div>
                    <div class="page-row">
                        <div class="page-row-subtitle">{{ $t('remarks') }}</div>
                        <textarea class="input-primary" rows="3" v-model="remark"></textarea>
                    </div>
                    <div class="page-row">
                        <div class="page-row-subtitle">{{ $t('event.step_one.date') }}</div>
                        <v-date-picker mode="dateTime" v-model="dateTime" :min-date="new Date()" />
                    </div>
                    <div class="event__datetime_action">
                        <button class="btn btn-primary" @click="locationTime()">{{ $t('event.next') }}</button>
                    </div>
                </div>

            </section>
            <section class="event__type" :class="screenPage == 3 ? ' visible slideInAnimation' : ''" data-screen="3">
                <div class="event__type_title">
                    <h2>2/3</h2>
                    <label>{{ $t('event.step_two.title') }}</label>
                </div>
                <div class="event__type_box">
                    <div class="page-row">
                        <div class="event__type_selection">
                            <div class="event__type_selection_box "
                                :class="{ 'selected': selectedEventType === 'wedding' }"
                                @click="selectEventType('wedding')">
                                <img src="/images/icons/wedding.png">
                                <span>{{ $t('event.step_two.wedding') }}</span>
                            </div>
                            <div class="event__type_selection_box "
                                :class="{ 'selected': selectedEventType === 'private_party' }"
                                @click="selectEventType('private_party')">
                                <img src="/images/icons/party.png">
                                <span>{{ $t('event.step_two.private') }}</span>
                            </div>
                            <div class="event__type_selection_box "
                                :class="{ 'selected': selectedEventType === 'business' }"
                                @click="selectEventType('business')">
                                <img src="/images/icons/business.png">
                                <span>{{ $t('event.step_two.business') }}</span>
                            </div>
                            <div class="event__type_selection_box "
                                :class="{ 'selected': selectedEventType === 'seminar' }"
                                @click="selectEventType('seminar')">
                                <img src="/images/icons/meeting.png">
                                <span>{{ $t('event.step_two.seminar') }}</span>
                            </div>
                            <div class="event__type_selection_box "
                                :class="{ 'selected': selectedEventType === 'gathering' }"
                                @click="selectEventType('gathering')">
                                <img src="/images/icons/gathering.png">
                                <span>{{ $t('event.step_two.house_party') }}</span>
                            </div>
                            <div class="event__type_selection_box "
                                :class="{ 'selected': selectedEventType === 'birthday' }"
                                @click="selectEventType('birthday')">
                                <img src="/images/icons/birthday.png">
                                <span>{{ $t('event.step_two.birthday') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="page-row">
                        <div class="page-row-subtitle">{{ $t('event.step_two.estimate_num') }}</div>
                        <select class="input-primary" v-model="eventNoPax">
                            <option class="" value="" selected>- {{ $t("event.step_two.select_one") }} -</option>
                            <option class="" value="1">10 - 30 {{ $t('pax') }}</option>
                            <option class="" value="2">30 - 80 {{ $t('pax') }}</option>
                            <option class="" value="3">80 - 150 {{ $t('pax') }}</option>
                            <option class="" value="4">150+ {{ $t('pax') }}</option>
                        </select>
                    </div>
                </div>
                <div class="event__type_action">
                    <button class="btn btn-primary" @click="pickEventType()">{{ $t('event.next') }}</button>
                </div>
            </section>
            <section v-if="false" class="event__promoter" :class="screenPage == 4 ? ' visible' : ''" data-screen="4">
                <div class="event__promoter_title">
                    <h2>3/4</h2>
                    <label>{{ $t('event.step_three.title') }}</label>
                </div>
                <div class="event__promoter_box">
                    <img src="/images/icons/waiter.png">
                    <label>{{ $t('event.step_three.interested_promoter') }}</label>
                    <span>{{ $t('event.step_three.help_service') }}</span>
                    <div class="event__promoter_action">
                        <div @click="loadScreen()">{{ $t('event.step_three.interested') }}</div>
                        <div @click="loadScreen(2)">{{ $t('event.step_three.not_interested') }}</div>
                    </div>
                </div>
            </section>
            <section v-if="false" class="event__promoter_selection" :class="screenPage == 5 ? ' visible' : ''"
                data-screen="5">
                <div class="event__promoter_selection_title">
                    <h2>4/4</h2>
                    <label>{{ $t('event.step_four.promoter.title') }}</label>
                </div>
                <div class="event__promoter_selection_box">
                    <div class="event__promoter_selection_box_title">
                        <label>{{ $t('event.step_four.promoter.hire_again') }}</label>
                        <span>
                            {{ $t('event.step_four.promoter.list_promoter') }}<br>
                            {{ $t('event.step_four.promoter.pick_promoter') }}
                        </span>
                    </div>
                    <div class="event__promoter_selection_items">
                        <div class="event__promoter_selection_item">
                            <img src="/images/sample/allura.png">
                            <label>Allura</label>
                            <div class="event__promoter_rating"><span>5.0</span></div>
                        </div>
                        <div class="event__promoter_selection_item">
                            <img src="/images/sample/charline.png">
                            <label>Charline</label>
                            <div class="event__promoter_rating"><span>3.5</span></div>
                        </div>
                        <div class="event__promoter_selection_item">
                            <img src="/images/sample/ming.png">
                            <label>Ming</label>
                            <div class="event__promoter_rating"><span>4.0</span></div>
                        </div>
                        <div class="event__promoter_selection_item">
                            <img src="/images/sample/alan.png">
                            <label>Alan</label>
                            <div class="event__promoter_rating"><span>5.0</span></div>
                        </div>
                        <div class="event__promoter_selection_item">
                            <img src="/images/sample/haoyun.png">
                            <label>Hao Yun</label>
                            <div class="event__promoter_rating"><span>5.0</span></div>
                        </div>
                        <div class="event__promoter_selection_item">
                            <img src="/images/sample/jess.png">
                            <label>Jess</label>
                            <div class="event__promoter_rating"><span>5.0</span></div>
                        </div>
                    </div>
                    <div class="event__promoter_selection_input">
                        <div class="page-row">
                            <div class="page-row-subtitle">{{ $t('event.step_four.promoter.number_promoter') }}</div>
                            <div class="page-row-subtitle2">{{ $t('event.step_four.promoter.extra_promoter') }}</div>
                            <select class="input-primary">
                                <option>1-5</option>
                                <option>6-10</option>
                                <option>11-20</option>
                                <option>21-50</option>
                                <option>50-100</option>
                                <option>Above 100</option>
                            </select>
                        </div>
                        <div class="page-row">
                            <div class="page-row-subtitle">{{ $t('event.step_four.promoter.extra_time') }}</div>
                            <select class="input-primary">
                                <option>1-5</option>
                                <option>6-10</option>
                                <option>11-20</option>
                                <option>21-50</option>
                                <option>50-100</option>
                                <option>Above 100</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="event__promoter_selection_action">
                    <span>{{ $t('event.step_four.promoter.unavailable_promoter') }}</span>
                    <button class="btn btn-primary" @click="loadScreen()">{{ $t('event.next') }}</button>
                </div>
            </section>
            <section class="event__menu" :class="screenPage == 4 ? ' visible slideInAnimation' : ''" data-screen="4">
                <div class="event__menu_title">
                    <h2>3/3</h2>
                    <label>{{ $t('event.step_four.service.title') }}</label>
                </div>
                <div class="event__menu_box">
                    <div class="event__menu__categories">
                        <div class="event__menu__category" data-category="1"
                            :class="{ 'selected': selectedCategory === 'event' }" @click="scrollToSection('event')">
                            <img src="/images/sample/product.png">
                            <span>{{ $t('event.step_four.service.event_services') }}</span>
                        </div>
                        <div v-if="false" class="event__menu__category" data-category="2"
                            :class="{ 'selected': selectedCategory === 'package' }" @click="scrollToSection('package')">
                            <img src="/images/sample/product.png">
                            <span>{{ $t('event.step_four.service.package') }}</span>
                        </div>
                    </div>
                    <div class="event__menu__display">
                        <div class="event__menu__products" id="event_section" data-category="1">
                            <label>{{ $t('event.step_four.service.event_services') }}</label>
                            <div class="event__menu__product_wrap">
                                <div v-for="(event) in eventService" :key="event.id" class="event__menu__product_box"
                                    :data-product="event.id" @click="loadProduct(event)"
                                    :class="{ 'selected': event.selected === true }">
                                    <div class="event__menu__product_image">
                                        <img :src="event.image"
                                            alt="Service Image">
                                        <div class="event__menu__product_add"></div>
                                    </div>
                                    <div class="event__menu__product_name">
                                        {{ event.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="false" class="event__menu__products" id="package_section" data-category="2">
                            <label>{{ $t('event.step_four.service.package') }}</label>
                            <div class="event__menu__product_wrap">
                                <div class="event__menu__product_box" data-product="7" @click="loadProduct(7)">
                                    <div class="event__menu__product_image">
                                        <img src="/images/sample/product.png">
                                        <div class="event__menu__product_add"></div>
                                    </div>
                                    <div class="event__menu__product_name">
                                        蟹棒
                                    </div>
                                </div>
                                <div class="event__menu__product_box" data-product="8" @click="loadProduct(8)">
                                    <div class="event__menu__product_image">
                                        <img src="/images/sample/product.png">
                                        <div class="event__menu__product_add"></div>
                                    </div>
                                    <div class="event__menu__product_name">
                                        香肠
                                    </div>
                                </div>
                                <div class="event__menu__product_box" data-product="9" @click="loadProduct(9)">
                                    <div class="event__menu__product_image">
                                        <img src="/images/sample/product.png">
                                        <div class="event__menu__product_add"></div>
                                    </div>
                                    <div class="event__menu__product_name">
                                        香肠
                                    </div>
                                </div>
                                <div class="event__menu__product_box" data-product="10" @click="loadProduct(10)">
                                    <div class="event__menu__product_image">
                                        <img src="/images/sample/product.png">
                                        <div class="event__menu__product_add"></div>
                                    </div>
                                    <div class="event__menu__product_name">
                                        香肠
                                    </div>
                                </div>
                                <div class="event__menu__product_box" data-product="11" @click="loadProduct(11)">
                                    <div class="event__menu__product_image">
                                        <img src="/images/sample/product.png">
                                        <div class="event__menu__product_add"></div>
                                    </div>
                                    <div class="event__menu__product_name">
                                        香肠
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="event__menu_action">
                    <button class="btn btn-primary" @click="pickEventService()">{{ $t('event.next') }}</button>
                </div>
            </section>
            <section class="event__confirmation" :class="screenPage == 5 ? ' visible slideInAnimationConfirm' : ''"
                data-screen="5">
                <h1>{{ $t('event.confirmation.title') }}</h1>
                <div class="event__confirmation__contact">
                    <label>{{ $t('order_confirmation.contact_info') }}</label>
                    <div class="event__confirmation__contact_box">
                        <div class="event__confirmation__contact--thumbnail">
                            <img src="/images/sample/profile.png">
                        </div>
                        <div class="event__confirmation__contact--name">
                            <label>{{ this.$store.state.user.firstname }}</label>
                            <span>{{ this.$store.state.user.phone ? this.$store.state.user.phone : '-' }}</span>
                        </div>
                    </div>
                </div>
                <div class="event__confirmation_info">
                    <label>{{ $t('event.confirmation.event_info') }}</label>
                    <div class="event__confirmation_row">
                        <label>{{ $t('event.confirmation.location') }}</label>
                        <div class="event__confirmation_row-data">
                            <span class="edit_data">{{ locationAddress != null ? locationAddress : '-' }}</span>
                            <span class="edit_button" @click="editEvent(2)">{{ $t('order_confirmation.edit') }}</span>
                        </div>
                    </div>
                    <div class="event__confirmation_row">
                        <label>{{ $t('event.confirmation.type') }}</label>
                        <div class="event__confirmation_row-data">
                            <span class="edit_data">{{ service_type != '' ? $t(`event.step_two.${service_type}`) : '-' }}</span>
                            <span class="edit_button" @click="editEvent(3)">{{ $t('order_confirmation.edit') }}</span>
                        </div>
                    </div>
                    <div class="event__confirmation_row">
                        <label>{{ $t('event.confirmation.date') }}</label>
                        <div class="event__confirmation_row-data">
                            <span class="edit_data">{{ displayDateTime }}</span>
                            <span class="edit_button" @click="editEvent(2)">{{ $t('order_confirmation.edit') }}</span>
                        </div>
                    </div>
                </div>
                <div class="event__confirmation__items">
                    <div class="event__confirmation__items-services">
                        <label>{{ $t('event.confirmation.event_list') }}</label>
                        <span class="edit_button" @click="editEvent(4)">{{ $t('order_confirmation.edit') }}</span>
                    </div>
                    <div class="event__confirmation__item" v-for="service in this.selectedEventService"
                        :key="service.id">
                        <div class="event__confirmation__item--thumbnail">
                            <img :src="service.image"
                                alt="Service Image">
                        </div>
                        <div class="event__confirmation__item--details">
                            <label class="event__confirmation__item--details_name">{{ service.name }}</label>
                            <!-- <span class="event__confirmation__item--details_desc">项目 A</span> -->
                        </div>
                    </div>
                </div>
                <div class="event__confirmation__promoters" v-if="false">
                    <label>{{ $t('event.confirmation.promoter') }} (2 hours)</label>
                    <div class="event__confirmation__promoter">
                        <div class="event__confirmation__promoter--thumbnail">
                            <img src="/images/sample/charline.png">
                        </div>
                        <div class="event__confirmation__promoter--details">
                            <label class="event__confirmation__promoter--name">Charline</label>
                        </div>
                    </div>
                    <div class="event__confirmation__promoter">
                        <div class="event__confirmation__promoter--thumbnail">
                            <img src="/images/sample/ming.png">
                        </div>
                        <div class="event__confirmation__promoter--details">
                            <label class="event__confirmation__promoter--name">Ming</label>
                        </div>
                    </div>
                    <div class="event__confirmation__promoter">
                        <div class="event__confirmation__promoter--thumbnail">
                            <img src="">
                        </div>
                        <div class="event__confirmation__promoter--details">
                            <label class="event__confirmation__promoter--name">Random x 2</label>
                        </div>
                    </div>
                </div>
                <div class="event__confirmation__remark">
                    <label>{{ $t('remarks') }}</label>
                    <textarea class="input-primary" rows="1" v-model="remark" :placeholder='$t("add_your_request")'></textarea>
                </div>
                <div class="event__confirmation__action">
                    <div class="event__confirmation__action_button">
                        <button class="btn btn-primary" @click="getQuote()">{{ $t('event.confirmation.get_quote')
                            }}</button>
                    </div>
                </div>
            </section>
        </div>
    </div>

    <PopupEventFull v-if="popupData != null" :popupData="popupData" @close="closePopup()"
        @checkSelect="checkSelect()" />
</template>


<script>
import PopupEventFull from '@/components/event/PopupEventFull.vue';
import moment from 'moment';
import vSelect from "vue-select";
import { postAPI, getAPI } from '@/js/general';

export default {
    name: 'EventView',
    data() {
        return {
            popupData: null,
            selectedCategory: 'event',
            screenPage: 1,
            eventService: [],

            remark: "",
            stateSelected: null,
            countrySelected: {
                currency_code: "MYR",
                display_name: "MALAYSIA",
                id: 129,
                iso3: "MYS",
            },
            postcode: "",
            city: "",
            streetName: "",
            floor: "",
            unitNo: "",
            addressName: "",
            requiredField: true,

            locationAddress: null,

            selectedEventService: [],
            dateTime: "",
            displayDateTime: "", 
            displayTime: "",
            displayDate: "",
            eventNoPax: "",

            selectedId: "",
            selectedEventType: "",
            service_type: "",
            states: [],
            countries: [],
            filteredCountries: [],
            stateId: null,
            countryId: null,
        }
    },
    created() {
        this.$store.state.showFooter = false;
    },
    components: {
        PopupEventFull,
        vSelect
    },
    computed: {
    },
    watch: {
        countrySelected(newCountry, oldCountry) {
        if (newCountry !== oldCountry) {
            this.stateSelected = null;
        }
        }
    },
    mounted() {
        this.states = this.$store.state.states;
        this.countries = this.$store.state.countries;
        this.filteredCountries = this.$store.state.countries;
        this.getServices();
    },
    methods: {
        async getServices() {
            var getSevicesResponse = await getAPI('/lead/services');
            if (getSevicesResponse.code == 'success') {
                this.eventService = getSevicesResponse.data.services;
            }
        },
        filteredStates(type = 'address') {
            var findCountryId = type == 'address' ? this.countryId.id : this.countrySelected.id;
            var filterStates = this.states;
            if (findCountryId) {
                filterStates = this.states.filter(function (item) {
                    return item.country_id == findCountryId;
                });
            }
            return filterStates;
        },
        editEvent(page) {
            this.screenPage = page;
        },
        search(event) {
            if (event.target.value) {
                var filtered = this.countries.filter(item => String(item.display_name.toLowerCase()).startsWith(event.target.value.toLowerCase()));
                this.filteredCountries = filtered;
            }
            if (event.target.value == '' || event.target.value == undefined) {
                this.filteredCountries = this.countries;
            }
        },
        closePopup() {
            this.popupData = null;
        },
        checkSelect() {
            this.eventService.forEach((item) => {
                if (item.id == this.selectedId) {
                    item.selected = !item.selected;
                }
            });
        },
        loadProduct(item) {
            this.selectedId = item.id;
            this.popupData = {
                name: item.name,
                info: item.description,
                selected: item.selected,
                type: item.service_type,
                image: item.image
            }
        },
        scrollToSection(category) {
            this.selectedCategory = category;
            const targetSection = document.getElementById(`${category}_section`);
            if (targetSection) {
                targetSection.scrollIntoView({ behavior: 'smooth' });
            }
        },
        loadScreen(e) {
            if (e == -1) {
                if (this.screenPage <= 1) {
                    this.$router.push(`${this.$store.state.multiLanguage}`);
                } else {
                    this.screenPage--;
                }
            } else if (e == 2) {
                this.screenPage += 2;
            } else {
                this.screenPage++;
            }
        },

        phoneOnly(event) {
           // Handle paste event
            if (event.type === 'paste') {
                event.preventDefault();
                const pastedData = event.clipboardData.getData('text');
                const numericData = pastedData.replace(/[^0-9]/g, '');
                
                // Update the model with only numeric data
                this.postcode = (this.postcode + numericData).slice(0, 10); // Limit to 10 digits
                return;
            }
            
            // Handle keypress event
            const charCode = event.charCode || event.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                event.preventDefault();
            }
        },
        locationTime() {

            var msg = "";
            var addressDetailCheck = true;
            var dateTimeCheck = true;
            if (this.addressName == "" || this.streetName == "" || this.city == "" || this.postcode == "" || this.stateSelected == null) {
                this.requiredField = false;
                dateTimeCheck = false;
                msg = this.$t('event.form.address_error');
            } else if (this.dateTime == '' || this.dateTime == null) {
                msg = this.$t('event.form.date_time_error');
                dateTimeCheck = false;
            } else {
                msg = "";
            }

            if (msg != "" || !addressDetailCheck || !dateTimeCheck) {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: msg,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            } else {
                this.displayDateTime = moment(this.dateTime).format("YYYY/MM/DD, h:mmA");
                this.displayTime = moment(this.dateTime, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");
                this.displayDate = moment(this.dateTime).format("YYYY/MM/DD");
                var display_addresses = [];
                if (this.addressName !== '') display_addresses.push(this.addressName);
                if (this.unitNo !== '') display_addresses.push(this.unitNo);
                if (this.floor !== '') display_addresses.push(this.floor);

                if (this.streetName !== '') display_addresses.push(this.streetName);
                if (this.postcode !== '') display_addresses.push(this.postcode);

                if (this.city !== '') display_addresses.push(this.city);
                if (this.stateSelected !== null) display_addresses.push(this.stateSelected.name);
                if (this.countrySelected !== null) display_addresses.push(this.countrySelected.display_name);

                this.locationAddress = display_addresses.join(", ");

                this.loadScreen();
            }
        },
        selectEventType(eventType) {
            switch (eventType) {
                case 'private_party':
                    this.service_type = 'private';
                    break;
                case 'gathering':
                    this.service_type = 'house_party';
                    break;
                case 'wedding':
                    this.service_type = 'wedding';
                    break;
                case 'business':
                    this.service_type = 'business';
                    break;
                case 'seminar':
                    this.service_type = 'seminar';
                    break;
                case 'birthday':
                    this.service_type = 'birthday';
                    break;
            }

            this.selectedEventType = eventType;
        },
        pickEventType() {

            if (this.eventNoPax == "" || this.selectedEventType == "") {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('event.form.empty_event_pax'),
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            } else {
                this.loadScreen();
            }
        },
        pickEventService() {
            this.selectedEventService = [];
            this.eventService.forEach((service) => {
                if (service.selected == true) {
                    this.selectedEventService.push(service);
                }
            })
            if (this.selectedEventService == "") {

                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('event.form.empty_event'),
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            } else {

                this.loadScreen();
            }
        },
        loadPreviewOrder() {
            this.screenPage++;
        },
        async getQuote() {
            var display_addresses = [];
            var addressLine;

            if (this.unitNo !== '') display_addresses.push(this.unitNo);
            if (this.floor !== '') display_addresses.push(this.floor);
            if (this.streetName !== '') display_addresses.push(this.streetName);

            addressLine = display_addresses.join(", ");

            var eventFormData = [];
            eventFormData = {
                'first_name': this.$store.state.user.firstname,
                'phone': this.$store.state.user.phone,
                'type': this.selectedEventType,
                'pax': this.eventNoPax,
                'date': this.displayDate,
                'time': this.displayTime,
                "address_line_1": addressLine,
                "address_line_2": "",
                "country_id": this.countrySelected.id,
                "address_name": this.addressName,
                "description": this.remark,
                "postal_code": this.postcode,
                "city": this.city,
                "source": "miniapp",
                "state_id": this.stateSelected.id,
            };

            eventFormData['services'] = [];

            // Loop through the selectedEventService array and push each service object into the services array
            for (var i = 0; i < this.selectedEventService.length; i++) {
                eventFormData['services'].push({ id: this.selectedEventService[i].id });
            }

            const submitResponse = await postAPI('/lead', eventFormData);

            if (submitResponse.code == "success") {
                this.$swal.fire({
                    position: "bottom",
                    text: "Submit success",
                    icon: 'success',
                    showConfirmButton: false,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$router.push(`${this.$store.state.multiLanguage}`);
                            this.$swal.close();
                        });
                    },
                    willClose: () => {
                        this.$router.push(`${this.$store.state.multiLanguage}`);
                    },
                });
            } else {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: submitResponse.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            }
        }
    }
}
</script>

<style scoped>
.slideInAnimation {
    bottom: 0;
    animation: slideIn 0.5s ease-in-out forwards;
}

.slideInAnimationConfirm {
    bottom: 0;
    animation: slideInConfirmation 0.5s ease-in-out forwards;
}

.fade-in {
    /* Define your animation properties */
    animation: fadeIn 1s ease-in-out forwards;
}
</style>
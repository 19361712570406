<template>
    <div class="frame">
        <div class="page page-profile">
            <router-link :to="this.$store.state.multiLanguage + 'dashboard'" class="link-back"><img
                    src="/images/icons/back.png"></router-link>
            <section class="profile_acount">
                <h1>{{ $t('my_profile.title') }}</h1>
                <div class="profile_acount__box">
                    <div class="profile_acount__thumbnail">
                        <img src="/images/sample/profile.png">
                    </div>
                    <table class="table">
                        <tr>
                            <td class="td--label">{{ $t('my_profile.name') }}</td>
                            <td class="td--value td--to-right">{{ userData.firstname }}</td>
                        </tr>
                        <tr>
                            <td class="td--label">{{ $t('my_profile.phone') }}</td>
                            <td class="td--value td--to-right">{{ userData.displayPhone }}</td>
                        </tr>
                        <tr>
                            <td class="td--label">{{ $t('my_profile.gender') }}</td>
                            <td class="td--value td--to-right">{{ userData.displayGender != '-' ? $t(`register.${userData.displayGender}`) : '-' }}</td>
                        </tr>
                        <tr>
                            <td class="td--label">{{ $t('my_profile.dob') }}</td>
                            <td class="td--value td--to-right">{{ userData.displayDOB }}</td>
                        </tr>
                        <tr v-if="false">
                            <td class="td--label">{{ $t('my_profile.email') }}</td>
                            <td class="td--value td--to-right">{{ userData.displayEmail }}</td>
                        </tr>
                    </table>
                </div>
            </section>
        </div>
    </div>
</template>


<script>
import moment from "moment";

export default {
    name: 'ProfileView',
    data() {
        return {
            userData: ''
        }
    },
    created() {
        this.$store.state.showFooter = false;
    },
    computed: {
    },
    mounted() {
        this.userData = this.$store.state.user;
        this.userData.displayPhone = !this.userData.phone ? '-' : this.userData.phone;
        this.userData.displayEmail = !this.userData.email ? '-' : this.userData.email;
        this.userData.displayGender = !this.userData.gender ? '-' : (this.userData.gender == 'f' ? 'female' : 'male' );

        this.userData.displayDOB = moment(this.userData.dob).format("DD-MM-YYYY");
    },
    methods: {
    }
}
</script>

<template>
	<div class="frame">
		<div class="page page-faq">
			<h1>{{ $t('faq.title') }}</h1>
			<router-link :to="this.$store.state.multiLanguage + 'dashboard'" class="link-back"><img
					src="/images/icons/back.png"></router-link>
			<section class="faq">
				<!-- <label>FAQ</label> -->
				<div class="faq__question" :class="general.expand ? ' selected' : ''" @click="triggerExpand(general)"
					v-for="(general, index) in faqSelected" :key="index">
					{{ general.question }}
					<div class="faq__answer">
						{{ general.answer }}
					</div>
				</div>
			</section>
		</div>
	</div>
</template>
<script>

export default {
	name: 'FaqView',
	data() {
		return {
			faqSelected: [],
			faqEn: [
				{
					question: 'What types of beer can I order through the Mini App?',
					answer: 'We offer four flavors of craft beer: Lager, Pale Ale, Extra Dark, and Wheat Beer.'
				},
				{
					question: 'Can I order beer for same-day delivery using the Mini App?',
					answer: 'Absolutely! Our Mini App allows you to place orders for same-day delivery within our service areas in Sarawak (Kuching, Sibu, Miri, Bintulu) and Sabah. Enjoy the convenience of having your favourite craft beers delivered to your doorstep.'
				},
				{
					question: 'Can I book events or private parties through the Mini App?',
					answer: 'Yes! You can conveniently submit event bookings through our Mini App. Whether it\'s a private party or a corporate event, we\'ll handle your booking request promptly.'
				},
				{
					question: 'Do you offer international shipping through the Mini App?',
					answer: 'Currently, our Mini App services are limited to Sarawak and Sabah regions. We plan to expand to other areas in the future.'
				},
				{
					question: 'How can I receive updates on new beer releases and events via the Mini App?',
					answer: 'Stay informed by following us on our Mini App. We regularly update users on upcoming beer releases, events, and exclusive promotions directly through the app.'
				},
				{
					question: 'What are the storage and consumption recommendations for beers ordered through the Mini App?',
					answer: 'Please ensure continuous refrigeration at a temperature range of 2°C to 4°C at all times. Consume the product within 7 days from the bottling date for optimal freshness. Once opened, finish the contents promptly.'
				},
				{
					question: 'Are your beers available in local bars or restaurants through the Mini App?',
					answer: 'Yes, you can locate our beers at various bars and restaurants using our Beer Locator feature in the Mini App. Discover nearby establishments serving our brews.'
				},
				{
					question: 'How can I provide feedback or share my experience with the Mini App service?',
					answer: 'We value your feedback! Feel free to share your experience through the Contact Us section within the Mini App or leave a review on popular platforms.'
				},
			],
			faqCn: [
				{
					question: '我可以通过 Mini App 订购哪些类型的啤酒？',
					answer: '我们提供四种口味的精酿啤酒：Lager、Pale Ale 、Extra Dark 、Wheat Beer。'
				},
				{
					question: '我可以使用 Mini App 下单进行当天配送吗？',
					answer: '当然可以！我们的 Mini App 允许您在砂拉越（古晋、诗巫、美里、民都鲁）和沙巴地区进行当天配送的订单。享受将您喜爱的精酿啤酒送到家门口的便利服务。'
				},
				{
					question: '我可以通过 Mini App 预订活动或私人派对吗？',
					answer: '可以！您可以通过我们的 Mini App 方便地提交活动预订。无论是私人派对还是企业活动，我们将尽快处理您的预订请求。'
				},
				{
					question: '您是否通过 Mini App 提供国际配送服务？',
					answer: '目前，我们的 Mini App 服务仅限于砂拉越和沙巴地区。未来我们计划扩展到其他地区。'
				},
				{
					question: '我如何通过 Mini App 接收关于新啤酒发布和活动的更新？',
					answer: '通过关注我们的 Mini App，您可以随时获取关于新品发布、活动和独家促销的最新消息。'
				},
				{
					question: '对于通过 Mini App 订购的啤酒，有哪些存储和消费建议？',
					answer: '请确保始终保持在2°C至4°C的温度范围内持续冷藏。为了保持最佳新鲜度，请在出瓶日期后的7天内消费完毕。开启后，请尽快饮用完毕。'
				},
				{
					question: '您的啤酒是否可以在当地酒吧或餐厅购买？',
					answer: '是的，您可以通过我们 Mini App 的“啤酒定位器”功能找到当地供应我们酿造啤酒的各种酒吧和餐厅。'
				},
				{
					question: '我如何向 Mini App 服务提供反馈或分享我的体验？',
					answer: '我们重视您的反馈！欢迎通过 Mini App 的“联系我们”部分分享您的体验或在主流评价平台上留下评价。'
				}
			]
		}
	},
	created() {
		this.$store.state.showFooter = false;
		if (localStorage.language ) {
			this.faqSelected = localStorage.language == 'en' ? this.faqEn : this.faqCn;
		}
	},
	computed: {
	},
	mounted() {
		this.faqSelected.forEach((general) => general.expand = false);
	},
	methods: {
		triggerExpand(item) {
			item.expand = !item.expand;
		}
	}
}
</script>

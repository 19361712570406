<template>
    <footer v-if="this.$store.state.showFooter">
        <div class="footer__frame">
            <div class="footer__menu">
                <router-link :to="this.$store.state.multiLanguage">
                    <div class="footer__menu_icon footer__menu_icon--home"></div>
                    <span>{{ $t('navigation.home') }}</span>
                </router-link>
            </div>
            <div class="footer__menu">
                <router-link :to="this.$store.state.multiLanguage + 'menu'">
                    <div class="footer__menu_icon footer__menu_icon--menu"></div>
                    <span>{{ $t('navigation.menu') }}</span>
                </router-link>
            </div>
            <div class="footer__menu">
                <router-link :to="this.$store.state.multiLanguage + 'history'">
                    <div class="footer__menu_icon footer__menu_icon--history"></div>
                    <span>{{ $t('navigation.orders') }}</span>
                </router-link>
            </div>
            <div class="footer__menu">
                <router-link :to="this.$store.state.multiLanguage + 'voucher'">
                    <div class="footer__menu_icon footer__menu_icon--voucher"></div>
                    <span>{{ $t('navigation.rewards') }}</span>
                </router-link>
            </div>
            <div class="footer__menu">
                <router-link :to="this.$store.state.multiLanguage + 'dashboard'">
                    <div class="footer__menu_icon footer__menu_icon--profile"></div>
                    <span>{{ $t('navigation.account') }}</span>
                </router-link>
            </div>
        </div>
    </footer>
</template>
<template>
    <div class="frame page-login">
        <section class="dashboard__language selected">

            <div class="dashboard__language-dropdown">
                <p :class="this.$i18n.locale == 'en' ? 'active disabled' : ''" @click="switchLanguage('en')">EN</p>
                <p :class="this.$i18n.locale == 'cn' ? 'active disabled' : ''" @click="switchLanguage('cn')">中文</p>
            </div>
        </section>
        <section v-if="phoneStep == 1">
            <div class="page-container">
                <div class="page-row text-center">
                    <label>{{ $t('login.login_with_phone') }}</label>
                </div>
                <div class="page-row">
                    <div class="page-row-subtitle">{{ $t('login.mobile_no') }}</div>
                    <div class="input-primary number_input">
                        <vue-tel-input :defaultCountry="'MY'" v-on:keypress="phoneOnly"  :inputOptions="{ placeholder: $t('phone_label'), type: 'tel' }"
                            v-on:country-changed="countryChanged" v-model="phoneNumber" @input="handlePhoneInput"
                            :autoFormat="false"></vue-tel-input>
                    </div>
                </div>
                <div class="page-row">
                    <button class="btn btn-primary" @click="submitPhone()">{{ $t('login.submit') }}</button>
                </div>
            </div>
        </section>
        <section v-if="phoneStep == 2">
            <a href="javascript:void(0)" @click="backPhone()" class="link-back">
                {{ '<' }} {{ $t('login.back') }}</a>
                    <div class="page-container">
                        <div class="page-row text-center">
                            <label>{{ $t('login.otp_sent') + ' ' + tempPhone }}</label>
                        </div>
                        <div class="page-row">
                            <div class="page-row-subtitle">{{ $t('login.enter_otp') }}</div>
                            <div class="input-group">
                                <input type="text" v-on:keypress="phoneOnly" maxlength="1" v-model="verifycode1"
                                    @input="next" @paste="autofill" class="text-center" otp-number="1" pattern="[0-9]*" inputmode="numeric" textContentType="oneTimeCode">
                                <input type="text" v-on:keypress="phoneOnly" maxlength="1" v-model="verifycode2"
                                    @input="next" @paste="autofill" class="text-center" otp-number="2" pattern="[0-9]*" inputmode="numeric" textContentType="oneTimeCode">
                                <input type="text" v-on:keypress="phoneOnly" maxlength="1" v-model="verifycode3"
                                    @input="next" @paste="autofill" class="text-center" otp-number="3" pattern="[0-9]*" inputmode="numeric" textContentType="oneTimeCode">
                                <input type="text" v-on:keypress="phoneOnly" maxlength="1" v-model="verifycode4"
                                    @input="next" @paste="autofill" class="text-center" otp-number="4" pattern="[0-9]*" inputmode="numeric" textContentType="oneTimeCode">
                                <!-- <input type="text" v-on:keypress="phoneOnly" maxlength="1" v-model="verifycode5"
                                    @input="next" @paste="autofill" class="text-center" otp-number="5">
                                <input type="text" v-on:keypress="phoneOnly" maxlength="1" v-model="verifycode6"
                                    @input="next" @paste="autofill" class="text-center" otp-number="6"> -->
                            </div>
                            <label class="invalid_code">
                                {{ this.login_error }}
                            </label>
                        </div>
                        <div class="page-row">
                            <label class="text-right">
                                {{ $t('login.not_receive') }}
                                <span v-if="countStart" class="page-login__send-otp">{{ $t('resend_in') + ' ' + this.minutes + ' : ' + this.seconds
                        }}</span>
                                <span v-if="!countStart" class="page-login__send-otp" @click.prevent="requestOTP()">{{
                    $t('login.resend_code') }}</span>
                            </label>
                        </div>
                    </div>
        </section>
    </div>
</template>


<script>

import { loginOtpAPI, verifyLoginOtpAPI } from '@/js/auth.js';

export default {
    name: 'LoginView',
    data() {
        return {
            phoneStep: 1,

            verifycode1: '',
            verifycode2: '',
            verifycode3: '',
            verifycode4: '',
            // verifycode5: '',
            // verifycode6: '',

            phoneNumber: '',
            countrycode: '',
            display_phone: '',

            login_error: '',
            loginType: 'phone',
            tempPhone: '',
            timerCount: 80,
            minutes: '',
            seconds: '',
            countStart: false,

        }
    },
    watch: {
        '$route.params.lang': {
            handler(newLang) {
                // Update content or perform language-specific actions based on newLang
                localStorage.setItem("language", newLang != 'cn' ? "en" : 'cn');
                this.$i18n.locale = localStorage.getItem("language");
                this.$store.state.multiLanguage = this.$i18n.locale == 'en' ? '/' : `/${this.$i18n.locale}/`;
                // this.multiLanguage = this.$i18n.locale == 'en' ? '/' : `/${this.$i18n.locale}/`;
            },
        },
    },

    created() {
        this.$store.state.showFooter = false;

        if (this.$store.state.isLoggedIn) {
            this.$router.push(`${this.$store.state.multiLanguage}` + 'dashboard');
        }
    },
    computed: {
    },
    mounted() {
    },
    methods: {
        async switchLanguage(language) {

            if (language != this.$i18n.locale) {
                localStorage.setItem("language", language);
                this.$i18n.locale = localStorage.getItem("language");
                this.$store.state.multiLanguage = this.$i18n.locale == 'en' ? '/' : `/${this.$i18n.locale}/`;

                // this.multiLanguage = this.$i18n.locale == 'en' ? '/' : `/${this.$i18n.locale}/`;

                const currentPath = this.$route.path;
                var newPath = '';
                var languageArray = ['cn', 'en'];

                if (languageArray.includes(language)) {
                    newPath = '';

                    if (language == 'en') {
                        newPath = currentPath.replace(/^\/(cn)/, '');

                        this.$router.push(newPath);
                    } else {
                        newPath = `/${language}${currentPath}`;
                        this.$router.push(newPath);
                    }
                }
            }
        },
        countryChanged(country) {
            this.countrycode = country.dialCode
        },
        handlePhoneInput(event) {
            // Remove all characters except numbers and the plus sign (+)
            const cleanedValue = event.target.value.replace(/[^0-9+]/g, '');
            this.phoneNumber = cleanedValue;
        },
        phoneOnly(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        async submitPhone() {

            if (this.phoneNumber != "") {

                let numString = this.phoneNumber;
                numString = numString.replace(/\D/g, '');
                let target_phone = parseInt(numString, 10);
                this.display_phone = this.countrycode + target_phone;
                this.tempPhone = this.display_phone;

                if (this.display_phone.trim()) {
                    this.login_error = '';
                    this.showLoader = true;

                    var loginResponse = await loginOtpAPI(this.display_phone, 'phone', true);

                    if (loginResponse.code == 'success') {
                        this.phoneStep = 2;
                    } else if (loginResponse.code == 'member_not_found') {
                        localStorage.temp_register_number = this.phoneNumber;
                        this.$router.push(`${this.$store.state.multiLanguage}` + 'register');
                    } else {
                        this.login_error = loginResponse.message ? loginResponse.message : 'Invalid Credentials.';
                    }
                    // this.showLoader = false;
                }
            } else {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('login.empty_phone'),
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            }

        },
        backPhone() {
            this.phoneStep = 1;
        },
        countDownTimer() {
            if (this.timerCount > 0) {
                setTimeout(() => {
                    this.timerCount -= 1
                    this.minutes = Math.floor((this.timerCount / 60) % 60);
                    this.seconds = Math.floor((this.timerCount) % 60);
                    if (this.seconds < "10") {
                        this.seconds = "0" + this.seconds;
                    }
                    this.countDownTimer()
                }, 1000)
            } else {
                this.countStart = false;
                this.timerCount = 80;
            }
        },
        async requestOTP() {
            this.countStart = true;
            this.countDownTimer();

            var phoneVerifyCode = loginOtpAPI(this.display_phone, 'phone', true);

            if (phoneVerifyCode.code == "success") {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('login.get_otp'),
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                }).then(() => {
                    this.$swal.close();
                });
            }
        },
        next(e) {
            if (!this.verifycode1 || !this.verifycode2 || !this.verifycode3 || !this.verifycode4) {
                if (e.inputType == 'insertText') {
                    e.target?.nextSibling?.focus();
                }

                if (e.inputType == 'deleteContentBackward') {
                    e.target?.previousSibling?.focus();
                }
            }

            this.verificationCode = this.verifycode1 + this.verifycode2 + this.verifycode3 + this.verifycode4;

            if (this.verifycode1 && this.verifycode2 && this.verifycode3 && this.verifycode4) {
                this.verifyLoginCode();
            }
        },
        autofill(event) {
            event.preventDefault();
            const pasteData = event.clipboardData.getData('text');
            if (pasteData) {
                this.verifycode1 = pasteData[0];
                this.verifycode2 = pasteData[1];
                this.verifycode3 = pasteData[2];
                this.verifycode4 = pasteData[3];
                // this.verifycode5 = pasteData[4];
                // this.verifycode6 = pasteData[5];
            }

            this.verificationCode = this.verifycode1 + this.verifycode2 + this.verifycode3 + this.verifycode4;

            if (this.verifycode1 && this.verifycode2 && this.verifycode3 && this.verifycode4) {
                this.verifyLoginCode(this.$store.state.verifyType);
            }
        },
        async verifyLoginCode() {
            this.showLoader = true;
            if (this.loginType == 'phone') {
                let numString = this.phoneNumber;
                numString = numString.replace(/\D/g, '');
                let target_phone = parseInt(numString, 10);
                this.display_phone = this.countrycode + target_phone;
                this.login_username = this.display_phone;
            }

            var submitUpdateEmail = await verifyLoginOtpAPI(this.login_username, this.loginType, this.verificationCode);

            if (submitUpdateEmail.code == "success") {
                this.login_error = "";
                this.verifycode1 = "";
                this.verifycode2 = "";
                this.verifycode3 = "";
                this.verifycode4 = "";
                window.location = '/';
            } else {
                this.login_error = this.$t('login.wrong_otp');
                this.verifycode1 = "";
                this.verifycode2 = "";
                this.verifycode3 = "";
                this.verifycode4 = "";
                this.verifyError = true;

                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('login.wrong_otp'),
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            }
            this.showLoader = false;
        },
    }
}
</script>

<style scoped>
.page-login {
    background: url('/public/images/login/background.png') no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    .dashboard__language {
        position: relative;
    }

    section {
        display: block;
    }

    .invalid_code {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: var(--color-pink);
        line-height: 30px;
        margin-top: 10px;
    }

    .selected {
        display: block;
    }

    label {
        font-size: var(--font-size-primary);
        font-weight: 500;
        font-family: var(--current-font-family);
        color: var(--color-white);
        line-height: 30px;
    }

    .number_input {
        padding: 0px;
        display: flex;

        .input-primary {
            &:focus {
                outline: none;
                border: none;
            }
        } 
    }

    .vue-tel-input {
        border: none;
        border-radius: var(--border-radius-primary);
        

        &:focus-within {
            box-shadow: none;
            border: none;
        }

        .vti__input, .vti__dropdown {
            border-radius: var(--border-radius-primary);
        }


        .vti__dropdown-list {
            width: 300px;
        }

        .vti__dropdown-list.below {
            top: 45px;
        }
    }
}
    .page-login .page-container {
        padding-top: 20vh;
    }

        .page-login .page-row {
            margin-bottom: var(--margin-primary);
        }

        .page-login .page-row-subtitle {
            margin-bottom: var(--margin-secondary);
        }

    .page-login__send-otp {
       color: var(--color-pink);
       cursor: pointer;
       white-space: nowrap;
    }
</style>
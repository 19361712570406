<template>
	<div class="reward_popup" id="reward_popup">
		<div class="reward_popup__box">
			<img class="reward_popup__box_image" :src="popupData.prize_image">
			<div class="reward_popup__action">
				<button class="btn" id="reward_popup__action--positive " @click="claimReward()" v-if="popupData.is_reward == '1'">{{ $t('rewards.claim') }}</button>
				<button class="btn" id="reward_popup__action--positive " @click="claimReward()" v-else>{{ $t('rewards.close') }}</button>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'RewardPopup',
	props: {
		popupData: {}
	},
	data() {
		return {
		}
	},
	created() {

	},
	async mounted() {
		if (this.popupData != null) {
			document.getElementById('reward_popup').style.display = 'block';
		}
	},
	methods: {
		claimReward() {
			this.$emit('close', this.popupData.is_reward);
		}
	}
}
</script>

<style scoped>
.reward_popup {
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 500;

    .reward_popup__box {
        position: absolute;
        max-width: 380px;
        width: 100%;
        height: 400px;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%); /* Center the box */

        .reward_popup__box_image {
            width: 100%;
            max-height: 400px;
        }

        .reward_popup__action {
            padding: var(--padding-secondary) var(--padding-primary);
            text-align: center;

            button {
                margin: 0 5px;

                &:disabled {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }
        }
    }
}
</style>
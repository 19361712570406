<template>
  <div class="frame page_qr">
			<div class="page-container">
				<div class="page-row text-center">
					<img src="/images/logo.png" class="page_qr__logo">
				</div>
				<div class="link-back"><img src="/images/icons/back.png" @click="returnReferralReward()"></div>
        <div class="page_qr__content">
          <p class="page_qr__title">{{ $t('referral_qr.title') }}</p>
          <qrcode-vue
            :value="qrCodeValue"
            :size="250"
            level="H"
            class="canvas"
            margin="3"
            render-as="svg"
          />
          <div class="page_qr__referral_code">
            <strong class="page_qr__code">{{ $t('referral_qr.code') }}</strong> {{ referralCode }}
          </div>
        </div>
			</div>
	</div>
  <Loader_component :loading="showLoader" :isFullPage="true" />
</template>
  
<script>
import QrcodeVue from "qrcode.vue";
import { getAPI } from '@/js/general';
import Loader_component from "@/include/loader_component.vue";

export default {
  name: "QRCodePage",
  components: { QrcodeVue, Loader_component },
  data() {
    return {
      referralCode: "",
    };
  },
  async created() {
      this.$store.state.showFooter = false;

      this.showLoader = true;
      var userResponse = await getAPI('/referral');

      if (userResponse.code == 'success') {
          this.referralRewards = userResponse.data.referral;
          this.referralCode = userResponse.data.referral_code;
          this.referralHistories = userResponse.data.referral_history;
      }
      this.showLoader = false;
  },
  computed: {
    qrCodeValue() {
      return `https://shop.1602craftbeer.com/#${this.referralCode}`;
    },
  },
  methods: {
    returnReferralReward() {
          this.$router.push(`${this.$store.state.multiLanguage}` + 'referral_reward');
      },
  }
};
</script>

<style scoped>

</style>
  
import { postAPI } from "./general";
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'
import store from '../store/index.js';
import i18n from '../js/i18n';


const verifyLoginAPI = async () => {
    return await postAPI('/verify', {}, true);
}

const loginOtpAPI = async (username, type) => {
    var data = {
        target: username,
        type: type,
    };

    var response = await postAPI('/login/phone', data);
    if (response.code == 'success') {

        Swal.fire({
            toast: true,
            position: "top",
            text: i18n.global.t('login.get_otp'),
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            didOpen: (toast) => {
                toast.addEventListener('click', () => {
                    Swal.close();
                });
            }
        });
    } else {
        Swal.fire({
            toast: true,
            position: "top",
            text: response.message,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            didOpen: (toast) => {
                toast.addEventListener('click', () => {
                    Swal.close();
                });
            }
        })
    }

    return response;
}

const verifyaccount = async (target, type, code) => {
    var data = {
        target: target,
        type: type,
        code: code,
    };


    var response = await postAPI('/accountverification', data);
    if (response.code == 'success') {
        if (type == 'email') {

            Swal.fire({
                toast: true,
                position: "top",
                text: i18n.global.t('verification.email_verified'),
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                didOpen: (toast) => {
                    toast.addEventListener('click', () => {
                        Swal.close();
                    });
                }
            })
        } else {

            Swal.fire({
                toast: true,
                position: "top",
                text: i18n.global.t('verification.phone_verified'),
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                didOpen: (toast) => {
                    toast.addEventListener('click', () => {
                        Swal.close();
                    });
                }
            })
        }
    } else {
        if (type == 'phone') {

            Swal.fire({
                toast: true,
                position: "top",
                text: i18n.global.t('verification.phone_verified_failed'),
                icon: 'error',
                showConfirmButton: false,
                timer: 2000,
                didOpen: (toast) => {
                    toast.addEventListener('click', () => {
                        Swal.close();
                    });
                }
            })
        }
    }
    return response;
}

const registerAPI = async (firstname, gender, phone, dob) => {

    var data = {
        firstname: firstname,
        gender: gender,
        phone: phone,
        dob: dob,
        registration_type: 'phone'
    };

    // if (mergeCart) {
    //     data['merge_cart'] = '1';
    //     data['guest_id'] = getGuestID();
    // }

    var response = await postAPI('/register', data);
    if (response.code == 'success') {
        Swal.fire({
            toast: true,
            position: "top",
            text: i18n.global.t('login.get_otp'),
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            didOpen: (toast) => {
                toast.addEventListener('click', () => {
                    Swal.close();
                });
            }
        })
        store.state.isLoggedIn = true;
        localStorage.access_token = response.data.token;
        store.state.access_token = response.data.token;
        store.state.user = response.data;
    } else {
        var errorMessage = 'Registration Failed.';

        if (response.code == 'invalid_phone') {
            response.message = i18n.global.t('verification.invalid_phone');

        } else if (response.code == 'invalid_username_input') {
            response.message = i18n.global.t('verification.invalid_username');

        } else if (response.code == 'invalid_gender_input') {
            response.message = i18n.global.t('verification.invalid_gender');

        } else if (response.code == 'invalid_dob_input') {
            response.message = i18n.global.t('verification.invalid_dob');

        } else if (response.code == 'phone_exist') {
            response.message = i18n.global.t('verification.phone_exists');
        }

        if (response.message) {
            errorMessage = response.message;
        }

        Swal.fire({
            toast: true,
            position: "top",
            text: errorMessage,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            didOpen: (toast) => {
                toast.addEventListener('click', () => {
                    Swal.close();
                });
            }
        })
    }
    return response;
}

const logoutAPI = async () => {
    await postAPI('/logout');

    store.state.isLoggedIn = false;
    localStorage.access_token = '';
    localStorage.outletSelected = '';
    localStorage.addressSelected = '';
    localStorage.outlet_id = '';
    store.state.access_token = null;
    store.state.outlet_id = null;
    store.state.user = null;

    Swal.fire({
        toast: true,
        position: "top",
        text: i18n.global.t('login.logged_out'),
        icon: 'success',
        showConfirmButton: false,
        timer: 2000,
        didOpen: (toast) => {
            toast.addEventListener('click', () => {
                Swal.close();
            });
        }
    })
}

const verifyLoginOtpAPI = async (username, type, verification_code, mergeCart = false) => {
    var data = {
        target: username,
        type: type,
        code: verification_code,
    };

    if (mergeCart) {
        data['merge_cart'] = '1';
        // data['guest_id'] = getGuestID();
    }
    var response = await postAPI('/login/phone/verify', data);
    if (response.code == 'success') {
        store.state.isLoggedIn = true;
        localStorage.access_token = response.data.token;
        store.state.access_token = response.data.token;
        store.state.user = response.data;
        Swal.fire({
            toast: true,
            position: "top",
            text: i18n.global.t('login.logged_in'),
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            didOpen: (toast) => {
                toast.addEventListener('click', () => {
                    Swal.close();
                });
            }
        })
    } else {
        Swal.fire({
            toast: true,
            position: "top",
            text: response.message,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            didOpen: (toast) => {
                toast.addEventListener('click', () => {
                    Swal.close();
                });
            }
        })
    }

    return response;
}

export {
    verifyaccount,
    verifyLoginAPI,
    loginOtpAPI,
    registerAPI,
    logoutAPI,
    verifyLoginOtpAPI
};
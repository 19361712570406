import { createStore } from 'vuex'

export default createStore({
  state: {
    legalAccept: 'false',
    showFooter: false,
    ageLegal: false,
    multiLanguage: '/',
    isLoggedIn: false,
    access_token: null,
    user: null,
    categories: null,
    products: [],
    payment_methods: [],
    selectedAddress: [],
    addressLabel: '',
    outlet_id: null,
    countries: [],
    states: [],
    carts: {
      items: [],
      point_earned: 0,
      promocodes: [],
      shipping: 0,
      subtotal: 0,
      tax: 0,
      total: 0,
      display_shipping_fee: 'RM 0.00',
      display_subtotal: 'RM 0.00',
      display_tax: 'RM 0.00',
      display_total: 'RM 0.00',
    },
    language_id: {
      'en': 1,
      'cn': 2
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})

<template>
    <div class="frame">
        <div class="page page-dashboard">
            <section class="dashboard__banner">
                <img src="/images/dashboard/banner.png">
            </section>
            <section class="dashboard__language">

                <div class="dashboard__language-dropdown">
                    <p :class="this.$i18n.locale == 'en' ? 'active disabled' : ''" @click="switchLanguage('en')">EN</p>
                    <p :class="this.$i18n.locale == 'cn' ? 'active disabled' : ''" @click="switchLanguage('cn')">中文</p>
                </div>
            </section>
            <section class="dashboard__member">
                <div class="dashboard__profile">
                    <div class="dashboard__profile--left">
                        <div class="dashboard__profile_thumbnail">
                            <img src="/images/sample/profile.png">
                        </div>
                    </div>
                    <div class="dashboard__profile--right">
                        <label>{{ userData.firstname }}!</label>
                        <span>{{ userData.displayPhone }}</span>
                    </div>
                </div>
                <div class="dashboard__member_frame dashboard__member_frame--gold">
                    <div class="dashboard__member_tier">
                        <div class="status__member status__member--gold">{{ memberTier }}</div>
                    </div>
                    <div class="dashboard__member_stats">
                        <div class="dashboard__member_stats--figure">
                            <span class="dashboard__member_stats--current">{{ userExp }}</span>/<span
                                class="dashboard__member_stats--max">{{ totalExp }} exp</span>
                        </div>
                        <div class="dashboard__member_stats--bar">
                            <div class="dashboard__member_stats--bar-value" :style="'--bar-percentage: ' + (userExp/totalExp)*100 + '%'"></div>
                        </div>
                    </div>
                    <div class="dashboard__member_points">
                        <div class="dashboard__member_points--box">
                            <span>{{ $t('account.points') }}</span>
                            <label>{{ pointAvailable }} pts</label>
                        </div>
                    </div>
                </div>
            </section>
            <section class="dashboard__checkin">
                <div class="dashboard__checkin_frame">
                    <label>{{ $t('daily_check_in.title') }}</label>
                    <div class="dashboard__checkin_frame-gauge " :class="checkInReward ? 'reward-spacing' : ''">
                        <div class="check_reward">
                            <div class="normal_check">
                                <img class="normal_check-background" src="/images/check_in/no_check.png" />
                                <div class="checked_in" v-if="dailyCheckCount >= 1">
                                    <img class="checked_in-background" src="/images/check_in/check_in.png" />
                                    <img class="checked_in-tick" src="/images/check_in/tick.png" />
                                </div>
                            </div>

                        </div>
                        <div class="link_check_in">
                            <img src="/images/check_in/line.png" />
                        </div>
                        <div class="check_reward">
                            <div class="normal_check">
                                <img class="normal_check-background" src="/images/check_in/no_check.png" />
                                <div class="checked_in" v-if="dailyCheckCount >= 2">
                                    <img class="checked_in-background" src="/images/check_in/check_in.png" />
                                    <img class="checked_in-tick" src="/images/check_in/tick.png" />
                                </div>
                            </div>

                        </div>
                        <div class="link_check_in">
                            <img src="/images/check_in/line.png" />
                        </div>
                        <div class="check_reward">
                            <div class="normal_check">
                                <img class="normal_check-background" src="/images/check_in/no_check.png" />
                                <div class="checked_in" v-if="dailyCheckCount >= 3">
                                    <img class="checked_in-background" src="/images/check_in/check_in.png" />
                                    <img class="checked_in-tick" src="/images/check_in/tick.png" />
                                </div>
                            </div>

                        </div>
                        <div class="link_check_in">
                            <img src="/images/check_in/line.png" />
                        </div>
                        <div class="check_reward">
                            <div class="normal_check">
                                <img class="normal_check-background" src="/images/check_in/no_check.png" />
                                <div class="checked_in" v-if="dailyCheckCount >= 4">
                                    <img class="checked_in-background" src="/images/check_in/check_in.png" />
                                    <img class="checked_in-tick" src="/images/check_in/tick.png" />
                                </div>
                            </div>
                            <!-- <div class="check_reward-detail" v-if="true" @click="spinReward()"> -->
                            <div class="check_reward-detail" v-if="true">
                                <!-- <p>{{ $t('daily_check_in.wheel_spin') }}</p> -->
                                <p>10 {{ $t('account.points') }}</p>
                                <div class="arrow"></div>
                            </div>
                        </div>
                        <div class="link_check_in">
                            <img src="/images/check_in/line.png" />
                        </div>
                        <div class="check_reward">
                            <div class="normal_check">
                                <img class="normal_check-background" src="/images/check_in/no_check.png" />
                                <div class="checked_in" v-if="dailyCheckCount >= 5">
                                    <img class="checked_in-background" src="/images/check_in/check_in.png" />
                                    <img class="checked_in-tick" src="/images/check_in/tick.png" />
                                </div>
                            </div>

                        </div>
                        <div class="link_check_in">
                            <img src="/images/check_in/line.png" />
                        </div>
                        <div class="check_reward">
                            <div class="normal_check">
                                <img class="normal_check-background" src="/images/check_in/no_check.png" />
                                <div class="checked_in" v-if="dailyCheckCount >= 6">
                                    <img class="checked_in-background" src="/images/check_in/check_in.png" />
                                    <img class="checked_in-tick" src="/images/check_in/tick.png" />
                                </div>
                            </div>

                        </div>
                        <div class="link_check_in">
                            <img src="/images/check_in/line.png" />
                        </div>
                        <div class="check_reward">
                            <div class="normal_check">
                                <img class="normal_check-background" src="/images/check_in/no_check.png" />
                                <div class="checked_in" v-if="dailyCheckCount >= 7">
                                    <img class="checked_in-background" src="/images/check_in/check_in.png" />
                                    <img class="checked_in-tick" src="/images/check_in/tick.png" />
                                </div>
                            </div>
                            <!-- <div class="check_reward-detail" v-if="true" @click="spinReward()"> -->
                            <div class="check_reward-detail" v-if="true">
                                <!-- <p>{{ $t('daily_check_in.wheel_spin') }}</p> -->
                                <p>20 {{ $t('account.points') }}</p>
                                <div class="arrow"></div>
                            </div>

                        </div>
                    </div>
                    <button @click="checkIn()" :disabled="!this.allowCheckIn">{{ this.allowCheckIn ?
                        $t('daily_check_in.check_in') : $t('daily_check_in.checked') }}</button>
                </div>
            </section>
            <section class="dashboard__shortcut">
                <div class="dashboard__shortcut_frame">
                    <label>{{ $t('account.feature') }}</label>
                    <div class="dashboard__shortcut_menu">
                        <router-link :to="this.$store.state.multiLanguage + 'points'">
                            <img src="/images/dashboard/icon-points.png">
                            <span>{{ $t('account.point_history') }}</span>
                        </router-link>
                    </div>
                    <div class="dashboard__shortcut_menu">
                        <router-link :to="this.$store.state.multiLanguage + 'faq'">
                            <img src="/images/dashboard/icon-faq.png">
                            <span>{{ $t('account.faq') }}</span>
                        </router-link>
                    </div>
                    <div class="dashboard__shortcut_menu">
                        <router-link :to="this.$store.state.multiLanguage + 'profile'">
                            <img src="/images/dashboard/icon-profile.png">
                            <span>{{ $t('account.my_profile') }}</span>
                        </router-link>
                    </div>
                    <!-- 						<div class="dashboard__shortcut_menu">
							<a href="review.html">
								<img src="/images/dashboard/icon-review.png">
								<span>评价</span>
							</a>
						</div> -->
                    <div class="dashboard__shortcut_menu dashboard__shortcut_menu--logout">
                        <a @click="logOut">
                            <img src="/images/dashboard/icon-logout.png">
                            <span>{{ $t('account.log_out') }}</span>
                        </a>
                    </div>
                </div>
            </section>
            <section class="dashboard__vip" v-if="false">
                <img class="dashboard__vip-card" src="/images/vip/vip_card.png" />
                <div class="dashboard__vip-card_item">
                    <img class="dashboard__vip-logo" src="/images/logo.png" />
                    <div class="dashboard__vip-content">
                        <p class="dashboard__vip-content-title">{{ $t('vip.title') }}</p>
                        <p class="dashboard__vip-content-desc">{{ $t('vip.desc') }}</p>
                        <button @click="subscribe()">{{ $t('vip.subscribe') }}</button>
                    </div>
                </div>
            </section>
            <section class="dashboard__banners">
                <router-link :to="this.$store.state.multiLanguage + 'referral_reward'"><img
                    :src="require('/public/images/dashboard/' + $t('referral_reward.banner'))"></router-link>
            </section>
        </div>
    </div>
</template>

<script>
// import { getAPI } from '@/js/general';
import { logoutAPI } from '@/js/auth';
import { getAPI, postAPI } from '@/js/general';
import Swal from 'sweetalert2/dist/sweetalert2';

export default {
    name: 'DashboardView',
    data() {
        return {
            userData: '',
            dailyCheckCount: 0,
            allowCheckIn: true,
            checkInReward: false,

            userExp: 0,
            memberTier: '',
            nextTierExp: 0,
            totalExp: 0,
            pointAvailable: 0
        }
    },
    async created() {
        this.$store.state.showFooter = true;

    },
    computed: {
    },
    async mounted() {
        await this.getUserExp();
        await this.checkDailyCheckIn();
        await this.getUserPoint();
        this.userData = this.$store.state.user;
        if (this.userData) {
            this.userData.displayPhone = !this.userData.phone ? '-' : this.userData.phone.slice(0, 4) + '*******';
        }
    },
    methods: {
        async spinReward () {
            var spinResponse = await postAPI('/dailycheckin/spin');
            if (spinResponse.code == 'success' || spinResponse.code == 'reward_added_to_account') {
                Swal.fire({
                    toast: true,
                    position: "top",
                    text: spinResponse.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            Swal.close();
                        });
                    }
                })
            } else {
                Swal.fire({
                    toast: true,
                    position: "top",
                    text: spinResponse.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            Swal.close();
                        });
                    }
                })
            }
        },
        async getUserPoint() {
            var userResponse = await getAPI('/user/point');

            var pointData = [];
            if (userResponse.code == 'success') {
                pointData = userResponse.data;
                this.pointAvailable = parseInt(pointData.point_balance).toLocaleString();
            }
        },
        async getUserExp() {
            var userExpResponse = await getAPI('/user/exp');

            if (userExpResponse.code == 'success') {
                this.userExp = parseInt(userExpResponse.data.exp);
                this.memberTier = userExpResponse.data.member_tier;
                this.nextTierExp = parseInt(userExpResponse.data.next_tier_exp); //remaining exp to reach next tier
                this.totalExp = this.userExp + this.nextTierExp;
            }
        },
        async checkDailyCheckIn() {
            var dailyResponse = await getAPI('/dailycheckin');

            if (dailyResponse.code == 'success') {
                this.dailyCheckCount = dailyResponse.data.count;
                this.allowCheckIn = dailyResponse.data.allow_checkin;
            } else {
                Swal.fire({
                    toast: true,
                    position: "top",
                    text: dailyResponse.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            Swal.close();
                        });
                    }
                })
            }
        },
        async checkIn() {
            var checkInResponse = await postAPI('/dailycheckin/claim');

            if (checkInResponse.code == 'success') {
                await this.checkDailyCheckIn();
                await this.getUserPoint();

                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('daily_check_in.checked'),
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                        this.$swal.close();
                        });
                    }
                });
            } else {
                Swal.fire({
                    toast: true,
                    position: "top",
                    text: checkInResponse.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            Swal.close();
                        });
                    }
                })
            }
        },
        // subscribe() {
        //     this.$router.push(this.$store.state.multiLanguage + 'vip-membership');
        // },
        async switchLanguage(language) {

            if (language != this.$i18n.locale) {
                localStorage.setItem("language", language);
                this.$i18n.locale = localStorage.getItem("language");
                this.$store.state.multiLanguage = this.$i18n.locale == 'en' ? '/' : `/${this.$i18n.locale}/`;

                // this.multiLanguage = this.$i18n.locale == 'en' ? '/' : `/${this.$i18n.locale}/`;

                const currentPath = this.$route.path;
                var newPath = '';
                var languageArray = ['cn', 'en'];

                if (languageArray.includes(language)) {
                    newPath = '';

                    if (language == 'en') {
                        newPath = currentPath.replace(/^\/(cn)/, '');

                        this.$router.push(newPath);
                    } else {
                        newPath = `/${language}${currentPath}`;
                        this.$router.push(newPath);
                    }
                }
            }
        },
        async logOut() {
            await logoutAPI();
            window.location = `${this.$store.state.multiLanguage}` + 'login';
        }
    }
}
</script>
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import MenuView from '@/views/MenuView.vue'
import HistoryView from '@/views/HistoryView.vue'
import VoucherView from '@/views/VoucherView.vue'
import DashboardView from '@/views/DashboardView.vue'
import AddAddress from '@/views/AddAddress.vue'
import PointHistory from '@/views/PointHistory.vue'
import FaqView from '@/views/FaqView.vue'
import ProfileView from '@/views/ProfileView.vue'
import ReferralReward from '@/views/ReferralReward.vue'
import QRCodePage from '@/views/QRCodePage.vue'
import OrderDetails from '@/views/OrderDetails.vue'
import LandingView from '@/views/LandingView.vue'
import LoginView from '@/views/LoginView.vue'
import RegisterView from '@/views/RegisterView.vue'
import OrderConfirmation from '@/views/OrderConfirmation.vue'
import EventView from '@/views/EventView.vue'
import store from '@/store'
import i18n from '../js/i18n.js';
// import VipMembership from '@/views/VipMembership.vue'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
    children: [
      {
        path: '/:lang',
        name: 'HomeView',
        component: HomeView,
      }
    ]
  },
  {
    path: '/en',
    redirect: '/',
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: '/',
  },
  {
    path: '/menu',
    name: 'MenuView',
    component: MenuView,
    children: [
      {
        path: '/:lang/menu',
        name: 'MenuView',
        component: MenuView,
      }
    ]
  },
  {
    path: '/history',
    name: 'HistoryView',
    component: HistoryView,
    children: [
      {
        path: '/:lang/history',
        name: 'HistoryView',
        component: HistoryView,
      }
    ]
  },
  {
    path: '/voucher',
    name: 'VoucherView',
    component: VoucherView,
    children: [
      {
        path: '/:lang/voucher',
        name: 'VoucherView',
        component: VoucherView,
      }
    ]
  },
  {
    path: '/dashboard',
    name: 'DashboardView',
    component: DashboardView,
    children: [
      {
        path: '/:lang/dashboard',
        name: 'DashboardView',
        component: DashboardView,
      }
    ]
  },
  {
    path: '/addaddress',
    name: 'AddAddress',
    component: AddAddress,
    children: [
      {
        path: '/:lang/addaddress',
        name: 'AddAddress',
        component: AddAddress,
      }
    ]
  },
  {
    path: '/points',
    name: 'PointsView',
    component: PointHistory,
    children: [
      {
        path: '/:lang/points',
        name: 'PointsView',
        component: PointHistory,
      }
    ]
  },
  {
    path: '/faq',
    name: 'FaqView',
    component: FaqView,
    children: [
      {
        path: '/:lang/faq',
        name: 'FaqView',
        component: FaqView,
      }
    ]
  },
  {
    path: '/profile',
    name: 'ProfileView',
    component: ProfileView,
    children: [
      {
        path: '/:lang/profile',
        name: 'ProfileView',
        component: ProfileView,
      }
    ]
  },
  {
    path: '/referral_reward',
    name: 'ReferralReward',
    component: ReferralReward,
    children: [
      {
        path: '/:lang/referral_reward',
        name: 'ReferralReward',
        component: ReferralReward,
      }
    ]
  },
  {
    path: '/referral_qrcode',
    name: 'QRCodePage',
    component: QRCodePage,
    children: [
      {
        path: '/:lang/referral_qrcode',
        name: 'QRCodePage',
        component: QRCodePage,
      }
    ]
  },
  {
    path: '/order_details',
    name: 'OrderDetails',
    component: OrderDetails,
    children: [
      {
        path: '/:lang/order_details',
        name: 'OrderDetails',
        component: OrderDetails,
      }
    ]
  },
  {
    path: '/landing',
    name: 'LandingView',
    component: LandingView,
    children: [
      {
        path: '/:lang/landing',
        name: 'LandingView',
        component: LandingView,
      }
    ]
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
    children: [
      {
        path: '/:lang/login',
        name: 'LoginView',
        component: LoginView,
      }
    ]
  },
  {
    path: '/register',
    name: 'RegisterView',
    component: RegisterView,
    children: [
      {
        path: '/:lang/register',
        name: 'RegisterView',
        component: RegisterView,
      }
    ]
  },
  {
    path: '/order-confirmation',
    name: 'OrderConfirmation',
    component: OrderConfirmation,
    children: [
      {
        path: '/:lang/order-confirmation',
        name: 'OrderConfirmation',
        component: OrderConfirmation,
      }
    ]
  },
  {
    path: '/event',
    name: 'EventView',
    component: EventView,
    children: [
      {
        path: '/:lang/event',
        name: 'EventView',
        component: EventView,
      }
    ]
  },
  // {
  //   path: '/vip-membership',
  //   name: 'VipMembership',
  //   component: VipMembership,
  //   children: [
  //     {
  //       path: '/:lang/vip-membership',
  //       component: VipMembership,
  //     }
  //   ]
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const newLocale = to.params.lang !== 'cn' ? 'en' : 'cn';
  localStorage.language = newLocale;
  store.state.multiLanguage = localStorage.language == 'en' ? '/' : `/${localStorage.language}/`;
  i18n.global.locale = newLocale;

  if (newLocale === 'cn') {
    document.documentElement.style.setProperty('--current-font-family', 'var(--font-family-chinese)');
  } else {
    document.documentElement.style.setProperty('--current-font-family', 'var(--font-family-secondary)');
  }

  // Redirect if user's phone is not verified and the target route is not RegisterView
  if (store.state.user && store.state.user.phone_verified_at == null && to.name !== 'RegisterView') {
    next({ name: 'RegisterView' });
  } else {
    next();
  }
});

export default router

<template>
	<div class="faq_popup" id="faq_popup">
		<div class="faq_popup__box">
			<div class="faq_popup__body" ref="faqPopupBody" v-on:scroll="handleScroll">
				<div class="faq_content">
					<div class="faq_content-item en_version">
						<p class="faq_content-item-title">TERMS AND CONDITIONS FOR 1602 CRAFT BEER</p>
						<ol>
							<li v-for="item in termsEn" :key="item.id">
								<strong>{{ item.title }}</strong>
								<p v-html="item.info"></p>
							</li>
						</ol>
					</div>

					<div class="faq_content-item cn_version">
						<p class="faq_content-item-title">1602精酿啤酒条款与条件</p>
						<ol>
							<li v-for="item in termsCn" :key="item.id">
								<strong>{{ item.title }}</strong>
								<p v-html="item.info"></p>
							</li>
						</ol>
					</div>
				</div>
			</div>
			<div class="faq_popup__action">
				<button class="btn" id="faq_popup__action--positive " @click="acceptCheckout()"
					:disabled="termsEnd == false">Accept</button>
				<button class="btn" id="faq_popup__action--positive " @click="declineCheckout()">Decline</button>
			</div>
		</div>
	</div>

</template>


<script>
import termsEng from '@/assets/data/terms.json';
import termsCns from '@/assets/data/terms_cn.json';

export default {
	name: 'FaqPopup',
	props: {
		popupData: {}
	},
	data() {
		return {
			termsEn: termsEng,
			termsCn: termsCns,
			termsEnd: false
		}
	},
	created() {

	},
	async mounted() {

		if (this.popupData != null) {
			document.getElementById('faq_popup').style.display = 'block';
		}
	},
	methods: {
		handleScroll() {
			const element = this.$refs.faqPopupBody;
			const endValue = (element.scrollHeight - element.scrollTop).toFixed();

			if (endValue <= 382) {
				this.termsEnd = true;
			}
		},
		acceptCheckout() {
			localStorage.acceptCheckout = true;
			this.$emit('close');
		},
		declineCheckout() {
			localStorage.acceptCheckout = false;
			this.$emit('close');
		}
	}
}
</script>

<style scoped>
.faq_popup {
	display: none;
	width: 100%;
	height: 100vh;
	max-height: 100vh;
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	top: 0px;
	left: 0px;
	z-index: 500;

	.faq_popup__box {
		background-color: var(--color-white);
		border-radius: 20px;
		position: absolute;
		max-width: 380px;
		width: 100%;
		height: 400px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		top: 0px;
		margin: auto;

		.faq_popup__body {
			padding: var(--padding-primary);
			height: 330px;
			overflow: auto;

			.faq_content {
				.faq_content-item {
					padding-bottom: var(--padding-secondary);

					.faq_content-item-title {
						font-weight: 500;
						font-size: 18px;
					}

					p {
						margin-top: 0px
					}

					ol {
						li {
							font-weight: 400;

							p {
								margin-top: 5px
							}
						}
					}
				}
			}
		}

		.faq_popup__action {
			border-top: 1px solid rgba(0, 0, 0, 0.5);
			padding: var(--padding-secondary) var(--padding-primary);
			text-align: center;

			button {
				margin: 0px 5px;

				&:disabled {
					pointer-events: none;
					opacity: 0.5;
				}
			}
		}
	}
}
</style>
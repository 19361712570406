<template>
  <!-- Modal -->
  <div class="popup popupOrderCoupon" id="popup">
    <div class="popup__box">
      <div class="popup__header">
        <h4 class="popup__header-promocode-title">{{ $t("checkout_detail.order_summary.voucher.select") }}</h4>
        <div class="popup__close" @click="closePopup()"><img src="/images/icons/close.png"></div>
        <input class="popup__header-promocode-input"
          :placeholder='$t("checkout_detail.order_summary.voucher.enter_promocode")' v-model="promocode" @input="promocode = promocode.toUpperCase()" />
        <button class="apply-promocode" @click="applyPromocode()">{{ $t("checkout_detail.order_summary.voucher.apply")
          }}</button>
      </div>
      <div class="popup__body">
        <div class="coupon-container" v-for="(coupon, index) in coupons" :key="index" @click="couponSelected(coupon)"
          v-show="coupons.length > 0">
          <div class="inner-card">
            <div class="inner-card-left">
              <img v-if="coupon.image" :src='coupon.image' class="inner-card-left-img" />
              <img v-else src="/images/logo.png" class="inner-card-left-img" />
            </div>
            <div class="inner-card-right">
              <!-- <h4 class="inner-card-right-top">{{ coupon.caption }}</h4> -->
              <div class="inner-card-right-bottom">
                <div class="inner-card-right-bottom-top" v-if="coupon.discount_type == 'gift'">
                  <span>{{ $t("checkout_detail.order_summary.voucher.free") }}</span>
                </div>
                <div class="inner-card-right-bottom-bottom">
                  <p>{{ coupon.caption }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="coupon-action">
            <div class="coupon-action-box" v-if="coupon.expiry_in_days">
              <div class="coupon-action-title">{{
          $t("checkout_detail.order_summary.voucher.validity") }}</div>
              <div class="coupon-action-detail" v-if="coupon.expiry_in_days">{{ coupon.expiry_in_days }} {{
          $t("checkout_detail.order_summary.voucher.days") }}</div>
            </div>
            <div class="coupon-action-box">
              <div class="coupon-action-title" v-if="coupon.display_min_spend">{{
          $t("checkout_detail.order_summary.voucher.min_spend") }}</div>
              <div class="coupon-action-detail" v-if="coupon.display_min_spend">{{ coupon.display_min_spend }}</div>
            </div>
            <div class="coupon-action-box" v-if="!coupon.expiry_in_days">
            </div>
            <div class="coupon-action-box" v-if="!coupon.display_min_spend">
            </div>
            <div class="coupon-action-box">
              <div :class="'coupon-action-apply' + (applied_coupons.includes(coupon.id) ? ' selected' : '')"></div>
            </div>
          </div>
        </div>
        <div class="no-coupon-container" v-show="coupons.length <= 0">
          <img class="no-coupon-image" src="/images/voucher/bro.png">
          <p class="no-coupon-title">{{ $t("checkout_detail.order_summary.voucher.coupon_not_available") }}</p>
        </div>
      </div>
      <div class="popup__action">
        <button class="apply-coupon" @click="applyCoupon()">{{ $t("checkout_detail.order_summary.voucher.apply_coupon")
          }}</button>
      </div>
    </div>
  </div>

</template>

<script>

import { getCoupons, getCartCoupons } from "@/js/cart";
import moment from "moment";
export default {
  name: 'couponsPopup',
  emits: ["applyCoupons", "removeCoupon", "applyPromocode"],
  props: {
    couponOpened: {
			type: String,
			required: false,
      
		},
    cartItem: null,
  },
  data() {
    return {
      showLoader: false,
      coupons: [],
      applied_coupons: [],
      windowWidth: 0,
      not_allow_to_stack: false,
      promocode: null,
      couponTCContent: null,
    };
  },
  async mounted() {
		if (this.couponOpened != null) {
			document.getElementById('popup').style.display = 'block';
			
		}
    await this.getCoupons();
    await this.getCustomerCoupons();
  },
  watch: {
  },
  methods: {

    async getCoupons() {
      this.showLoader = true;
      var couponResponse = await getCoupons(true, true);
      if (couponResponse !== false) {
        this.coupons = couponResponse ? couponResponse : [];
        this.coupons.forEach((item) => {
          item.no_expiry = item.expiry == undefined || item.expiry == null;
          if (!item.no_expiry) {
            item.formatted_expiry = moment(item.expiry).format("DD/MM/YYYY");
            item.expiry_in_days = moment(item.expiry).diff(moment(), "days");
          }

          item.preOrderLabel = '';
          var formatedTime = '';
          if (item.discount_type == 'gift' && item.gift) {
            if (item.gift.product_type == 'configurable' && item.gift.selected_variant) {
              if (item.gift.selected_variant.is_preorder == 1) {
                formatedTime = moment(item.gift.selected_variant.preorder_arrived).format("DD MMM YYYY");
                item.preOrderLabel = 'Pre-order ' + formatedTime + ' ';
              }
            } else {
              if (item.gift.is_preorder == 1) {
                formatedTime = moment(item.gift.preorder_arrived).format("DD MMM YYYY");
                item.preOrderLabel = 'Pre-order ' + formatedTime + ' ';
              }
            }
          }

          if (item.discount_type == 'gift' && item.gift && item.gift.product_type == 'configurable') {
            var gift_variant_properties = [];
            if (item.gift && item.gift.variant_properties) {
              gift_variant_properties = item.gift.variant_properties.filter((inner_item) => inner_item.variant_id == item.gift_variant_id);
              var display_variant;
              display_variant = gift_variant_properties.reduce((carry, item) => carry + (carry.trim() != '' ? ', ' : '') + item.variant, '');
              item.display_variant = display_variant;
            }
            if (item.use_default_image == 1) {
              if (item.gift && item.gift.images) {
                var findVariantId = item.gift.variant_properties.find((inner_item) => inner_item.variant_id == item.gift_variant_id);
                var find_gift_variant_image_id = [];
                find_gift_variant_image_id = item.gift.variants.find((inner_item) => inner_item.variant_id == findVariantId.variant_id);
                var gift_variant_image = [];
                gift_variant_image = item.gift.images.find((inner_item) => inner_item.id == find_gift_variant_image_id.images[0]);
                item.display_image = gift_variant_image;
              }
            }
            return item;
          }
        });
        // $this.claim_coupons = $this.coupons.filter((item) => item.type == "claim");
        // $this.point_coupons = $this.coupons.filter((item) => item.type == "exchange");
      }
      this.showLoader = false;
    },

    async getCustomerCoupons() {
      this.showLoader = true;
      var customerCouponResponse = await getCartCoupons();

      if (customerCouponResponse !== false) {
        var customer_coupons = customerCouponResponse ? customerCouponResponse : [];
        var applied_coupons = [];
        customer_coupons.forEach((item) => {
          applied_coupons.push(item.member_coupon_id);
        });
        this.applied_coupons = applied_coupons;
      }
      this.showLoader = false;
    },

    closePopup() {
      this.$emit("closeCouponsPopUp");
    },

    resetCoupon() {
      this.applied_coupons = [];
      this.not_allow_to_stack = false;
    },

    async couponSelected(coupon) {
      if (coupon.outlet_coupon_error) {
        this.$swal.fire({
          toast: true,
          position: "top",
          text: this.$t('not_available_for_outlet'),
          icon: 'error',
          showConfirmButton: false,
          timer: 2000,
          didOpen: (toast) => {
            toast.addEventListener('click', () => {
              this.$swal.close();
            });
          }
        });
      } else if (coupon.min_spend && coupon.min_spend !== null && (this.cartItem.subtotal < coupon.min_spend)) {
        this.$swal.fire({
          toast: true,
          position: "top",
          text: this.$t('checkout_detail.order_summary.voucher.min_spend_not_reached'),
          icon: 'error',
          showConfirmButton: false,
          timer: 2000,
          didOpen: (toast) => {
            toast.addEventListener('click', () => {
              this.$swal.close();
            });
          }
        });
      } else {
        var checkApplied = this.applied_coupons.find((item) => item == coupon.id);
        if (checkApplied) {
          await this.removeCoupon(coupon.id);
          await this.getCoupons();
          await this.getCustomerCoupons();
        } else {
          // e.preventDefault();
          var findCouponIndex = this.applied_coupons.findIndex((item) => item == coupon.id);
          if (findCouponIndex > -1) {
            if (coupon.allow_to_stack == '1') {
              this.not_allow_to_stack = false;
            }
            this.applied_coupons.splice(findCouponIndex, 1);

          } else {

            var _current_coupon_type = 'c'; // unknown then don stack
            if (coupon.type == 'exchange' && coupon.discount_type == 'product') {
              _current_coupon_type = 'a';
            } else if (coupon.type == 'exchange' && coupon.discount_type == 'gift') {
              _current_coupon_type = 'b';
            } else if (coupon.type == 'claim') {
              if (coupon.allow_to_stack) {
                _current_coupon_type = 'c1';
              } else {
                _current_coupon_type = 'c0';
              }
            }

            var _current_coupon_stackable = true;
            var _target_coupon;
            var _compare_coupon;
            var _target_coupon_type;

            this.applied_coupons.map((item) => {

              _target_coupon = item;
              _compare_coupon = this.coupons.find((check_coupon) => check_coupon.id == _target_coupon);

              _target_coupon_type = 'c0';
              if (_compare_coupon) {
                if (_compare_coupon.type == 'exchange' && _compare_coupon.discount_type == 'product') {
                  _target_coupon_type = 'a';
                } else if (_compare_coupon.type == 'exchange' && _compare_coupon.discount_type == 'gift') {
                  _target_coupon_type = 'b';
                } else if (_compare_coupon.type == 'claim') {
                  if (_compare_coupon.allow_to_stack) {
                    _target_coupon_type = 'c1';
                  } else {
                    _target_coupon_type = 'c0';
                  }
                }
              }

              if (_current_coupon_type == 'a') {
                if (_target_coupon_type == 'a') {  // a + a
                  //

                } else if (_target_coupon_type == 'b') {    // a + b
                  _current_coupon_stackable = false;

                } else if (_target_coupon_type == 'c1') {   // a + c (true)
                  //

                } else if (_target_coupon_type == 'c0') {   // a + c (false)
                  _current_coupon_stackable = false;

                } else {                                     // unknown
                  _current_coupon_stackable = false;

                }

              } else if (_current_coupon_type == 'b') {
                if (_target_coupon_type == 'a') {           // b + a
                  _current_coupon_stackable = false;
                } else if (_target_coupon_type == 'b') {    // b + b
                  _current_coupon_stackable = false;

                } else if (_target_coupon_type == 'c1') {   // b + c (true)
                  //

                } else if (_target_coupon_type == 'c0') {   // b + c (false)
                  _current_coupon_stackable = false;

                } else {
                  _current_coupon_stackable = false;

                }

              } else if (_current_coupon_type == 'c1') {
                if (_target_coupon_type == 'a') {           // c + a
                  //

                } else if (_target_coupon_type == 'b') {    // c + b

                } else if (_target_coupon_type == 'c1') {   // c + c (true)
                  _current_coupon_stackable = false;

                } else if (_target_coupon_type == 'c0') {   // c + c (false)
                  _current_coupon_stackable = false;

                } else {
                  _current_coupon_stackable = false;

                }

              } else if (_current_coupon_type == 'c0') { // all + c (false)
                _current_coupon_stackable = false;

              } else {
                _current_coupon_stackable = false;

              }
            });

            var errorMessage = '';
            if (!_current_coupon_stackable) {
              errorMessage = this.$t('non_stack_coupon');
              this.resetCoupon();
              this.applied_coupons.push(coupon.id);
            }
            if (errorMessage.trim()) {

              this.$swal.fire({
                toast: true,
                position: "top",
                text: errorMessage,
                icon: 'error',
                showConfirmButton: false,
                timer: 2000,
                didOpen: (toast) => {
                  toast.addEventListener('click', () => {
                    this.$swal.close();
                  });
                }
              });
            } else {
              if (coupon.allow_to_stack == '1') {
                this.not_allow_to_stack = true;
              }
              this.applied_coupons.push(coupon.id);
            }
          }
        }
      }

    },
    async applyPromocode() {
      this.showLoader = true;
      if (this.promocode !== '') {
        await this.$emit('applyPromocode', this.promocode);
        this.promocode = '';
      }
      this.showLoader = false;
    },
    async applyCoupon() {
      await this.$emit('applyCoupons', this.applied_coupons, async () => {
        await this.getCustomerCoupons();
      });
      this.showLoader = false;
    },
    applyDiscounts(promocode, coupons) {
      var selectedCoupons = [];
      coupons.forEach((coupon) => {
        if (coupon.selected) {
          selectedCoupons.push(coupon.id);
        }
      });
      this.$emit("applyDiscounts", promocode, selectedCoupons);
      this.$emit("closeCouponsPopUp");
    },

    async removeCoupon(coupon_id) {
      this.$emit('removeCoupon', coupon_id);
    },

  }

}
</script>

<style lang="scss" scoped></style>
<template>
    <div class="frame">
        <div class="page page-voucher">
            <h1>{{ $t('rewards.title') }}</h1>
            <section class="points__balance">
                <div class="points__balance_display">{{ userPoint.displayPoint }} Pts</div>
                <span>{{ userPoint.displayExpireSoonPoint }} {{ $t('rewards.point_expiry1') }} {{
                userPoint.displayExpiryDate }} {{ $t('rewards.point_expiry') }}</span>
            </section>
            <section class="voucher__listing">
                <div class="voucher__menu">
                    <div :class="voucherTab == 'voucher-content-redeem' ? 'selected ' : ''"
                        class="voucher__menu--redeem" target-tab="voucher-content-redeem"
                        @click="selectVoucherTab('voucher-content-redeem')">{{ $t('rewards.redeem_rewards') }}</div>
                    <div :class="voucherTab == 'voucher-content-my' ? 'selected ' : ''" class="voucher__menu--my"
                        target-tab="voucher-content-my" @click="selectVoucherTab('voucher-content-my')">{{
                $t('rewards.my_rewards') }}</div>
                </div>
                <div class="voucher__body">
                    <div :class="voucherTab == 'voucher-content-redeem' ? 'selected ' : ''" class="voucher__content"
                        id="voucher-content-redeem">
                        <!-- <label>Redeem voucher</label> -->
                        <div class="voucher__content_box" v-for="coupon in this.couponExchange.coupons" :key="coupon.id"
                            @click="openVoucher(coupon)">
                            <!-- <div class="voucher__content_box--banner">
                                <img :src="coupon.image">
                            </div> -->
                            <div class="coupon-container">
                                <div class="inner-card">
                                    <div class="inner-card-left">
                                        <img :src="coupon.image != null ? coupon.image : require('/public/images/logo.png')"
                                            class="inner-card-left-img" />
                                    </div>
                                    <div class="inner-card-right">
                                        <p class="inner-card-right-caption">{{ coupon.caption }}</p>
                                        <p class="inner-card-right-points">{{
                parseInt(coupon.points_needed).toLocaleString() }} <span>{{ $t('points')
                                                }}</span></p>
                                    </div>
                                </div>
                            </div>

                            <div class="voucher__content_box--left">
                                <!-- <div class="voucher__content_box--title">{{ coupon.caption }} ({{
                coupon.points_needed }} POINTS)</div> -->
                                <div class="voucher__content_box--desc">{{ $t('rewards.view_t_c') }}</div>
                            </div>
                            <div class="voucher__content_box--right">
                                <button class="btn btn-primary">{{ $t('rewards.redeem') }}</button>
                            </div>
                        </div>
                    </div>
                    <div :class="voucherTab == 'voucher-content-my' ? 'selected ' : ''" class="voucher__content"
                        id="voucher-content-my">
                        <div v-if="userExchangeCoupon.count <= 0" class="voucher__content_empty">
                            <img src="/images/voucher/bro.png">
                            <label>{{ $t('rewards.no_voucher') }}</label>
                        </div>
                        <div v-if="userExchangeCoupon.count > 0">
                            <div v-for="myCoupon in userExchangeCoupon.coupons" :key="myCoupon.id"
                                class="voucher__content_box" @click="openMyVoucher(myCoupon)">
                                <!-- <div class="voucher__content_box--banner">
                                    <img src="/images/sample/voucher.png">
                                </div> -->
                                <div class="coupon-container">
                                    <div class="inner-card">
                                        <div class="inner-card-left">
                                            <img :src="myCoupon.image != null ? myCoupon.image : require('/public/images/logo.png')"
                                                class="inner-card-left-img" />
                                        </div>
                                        <div class="inner-card-right">
                                            <p class="inner-card-right-caption">{{ myCoupon.caption }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="voucher__content_box--left">
                                    <!-- <div class="voucher__content_box--title">{{ myCoupon.caption }}</div> -->
                                    <div class="voucher__content_box--desc">{{ $t('rewards.view_t_c') }}</div>
                                    <div class="voucher__content_box--expiry" v-if="myCoupon.display_expiry != null">{{ $t('rewards.expiry_date') }}
                                        {{ myCoupon.display_expiry }}</div>
                                </div>
                                <div class="voucher__content_box--right">
                                    <span class="status__label status__label--active">{{ myCoupon.coupon_status
                                        }}</span>
                                    <div class="voucher__content_quantity">x1</div>
                                </div>
                            </div>
                            <!-- <div class="voucher__content_box" onclick="openMyVoucher()">
                                <div class="voucher__content_box--banner">
                                    <img src="/images/sample/voucher.png">
                                </div>
                                <div class="voucher__content_box--left">
                                    <div class="voucher__content_box--title">RM3 OFF (100 POINTS)</div>
                                    <div class="voucher__content_box--desc">查看条款和条件</div>
                                    <div class="voucher__content_box--expiry">Expiry date - 16/08/2024</div>
                                </div>
                                <div class="voucher__content_box--right">
                                    <span class="status__label status__label--expired">expiry</span>
                                    <div class="voucher__content_quantity">x10</div>
                                </div>
                            </div> -->
                            <p class="points__history-loadmore" v-if="allDataLoaded == false" @click="loadMore()">{{
                $t('load_more') }}</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <RewardPopup v-if="triggerRewardDialog != null" :popupData="triggerRewardDialog" @close="closeRewardDialog" />
    <popup v-if="popupData != null" :popupData="popupData" :rewardType="rewardType" @close="closePopup()"
        @redeem="redeemVoucher()" @decodeQr="decodeQr"/>
    <Loader_component :loading="showLoader" :isFullPage="true" />

</template>

<script>
import { getAPI, postAPI } from '@/js/general';
import moment from "moment";
import Loader_component from "@/include/loader_component.vue";
import popup from '@/components/voucher/PopupVoucher.vue'
import RewardPopup from '@/components/menu/RewardPopup.vue';
export default {
    name: 'VoucherView',
    data() {
        return {
            userPoint: '',
            voucherTab: 'voucher-content-redeem',

            couponExchange: [],
            couponDetail: [],
            couponRewardDetail: [],
            userExchangeCoupon: [],

            limit: 20,
            allDataLoaded: false,
            showLoader: false,

            popupData: null,
            rewardType: null,

            attemptCount: 0,
            maxAttempts: 10,
            intervalId: null,
            triggerRewardDialog: null,
            tracking_id: null,
        }
    },
    async created() {
        this.$store.state.showFooter = true;
        this.showLoader = true;
        await this.getPoints();
        await this.getVouchers();
        await this.userCoupon();
        if (this.limit >= this.userExchangeCoupon.count) {
            this.allDataLoaded = true;
        }
        this.showLoader = false;
    },
    components: {
        popup,
        Loader_component,
        RewardPopup
    },
    computed: {
    },
    async mounted() {
        window.scrollTo(0, 0);

    },
    methods: {
        async redeemVoucher() {
            const redeemResponse = await postAPI('/coupon/exchange', {
                coupon: this.popupData.id,
            });

            if (redeemResponse.code == "success") {
                await this.getPoints();
                await this.getVouchers();
                await this.userCoupon();

                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('rewards.redeemed'),
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });

                this.closePopup();
            } else {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: redeemResponse.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            }
        },
        async decodeQr(hash, device_id, member_coupon_id) {
            this.showLoader = true;
            const gameResponse = await postAPI('/coupon/cloudstation/lock', {
                device_id: device_id,
                hash: hash,
                member_coupon_id: member_coupon_id,
            });
            
            if (gameResponse.code == "success") {
                if (gameResponse.data && gameResponse.data.station_status && gameResponse.data.station_status !== '') {
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: this.$t('rewards.invalid_qr'),
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 5000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                    this.closePopup();
                    this.showLoader = false;
                } else {
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: this.$t('rewards.please_wait'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 5000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                    this.closePopup();
                    this.checkReward(member_coupon_id);
                }
            } else {
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: gameResponse.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
                this.closePopup();
                this.showLoader = false;
            }
        },

        async checkReward(member_coupon_id) {
            this.intervalId = setInterval(async () => {
                this.attemptCount++;

                try {
                    const checkRewardResponse = await postAPI('/game/cloudstation/check', {
                        member_coupon_id: member_coupon_id,
                    });

                    if (checkRewardResponse.data && checkRewardResponse.data.game_status && checkRewardResponse.data.game_status == "ended") {
                        clearInterval(this.intervalId);
                        this.attemptCount = 0;
                        this.showLoader = false;
                        this.triggerRewardDialog = checkRewardResponse.data ? checkRewardResponse.data : null;
                    } else if (this.attemptCount >= this.maxAttempts) {
                        await this.getPoints();
                        await this.getVouchers();
                        await this.userCoupon();
                        clearInterval(this.intervalId);
                        this.attemptCount = 0;

                        this.$swal.fire({
                            toast: true,
                            position: "top",
                            text: this.$t('rewards.please_check_wallet'),
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 5000,
                            didOpen: (toast) => {
                                toast.addEventListener('click', () => {
                                    this.$swal.close();
                                });
                            }
                        });

                        this.showLoader = false;
                    }
                } catch (error) {
                    console.error('Error during reward check:', error);
                    clearInterval(this.intervalId);
                    this.attemptCount = 0;
                }
            }, 3000); // Trigger every 3 seconds
        },

        async closeRewardDialog(is_reward) {
            if (is_reward == 1) {
                this.triggerRewardDialog = null;
                this.showLoader = true;
                await this.getVouchers();
                await this.userCoupon();
                this.showLoader = false;

                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('rewards.added_to_wallet'),
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 5000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            } else {
                this.triggerRewardDialog = null;
            }
        },

        openMyVoucher(data) {
            this.popupData = data;
            this.rewardType = "my_rewards";
        },
        openVoucher(data) {
            this.popupData = data;
            this.rewardType = "redeem_rewards";
        },
        closePopup() {
            this.popupData = null;
            this.rewardType = null;
        },
        selectVoucherTab(tab) {
            this.voucherTab = tab;
        },
        loadMore() {
            this.limit += 20;
            this.userCoupon();

            if (this.limit >= this.userExchangeCoupon.count) {
                this.allDataLoaded = true;
            }
        },
        async userCoupon() {
            this.showLoader = true;
            var UserCouponResponse = await getAPI("/user/coupon", {
                limit: this.limit,
                // type: 'exchange',
                // language_id: this.$store.state.language_id[this.$i18n.locale],
                // redeemableOnly: 1,
                availableOnly: 1,
            });

            if (UserCouponResponse.code == 'success') {
                this.userExchangeCoupon = UserCouponResponse.data;
                this.userExchangeCoupon.coupons.forEach((coupon) => {
                    const currentDate = new Date();

                    if (coupon.redeemed != null) {
                        coupon.coupon_status = this.$t('used');
                    } else if (coupon.expiry != null) {
                        const timestamp = new Date(coupon.expiry);
                        if (currentDate > timestamp) {
                            coupon.coupon_status = this.$t('expired');
                        } else {
                            coupon.coupon_status = this.$t('active');
                        }
                    } else {
                        coupon.coupon_status = this.$t('active');
                    }

                    if (coupon.active != null) {
                        const timestamp = new Date(coupon.active);
                        if (currentDate < timestamp) {
                            coupon.coupon_status = this.$t('available_on');
                        }
                    }
                })
                this.totalPages = Math.ceil(UserCouponResponse.data.count / this.limit);
            }
            this.showLoader = false;
        },
        async getVouchers() {
            this.showLoader = true;
            var exchangeCoupon = await getAPI("/coupon/exchange/get", {
                // language_id: this.$store.state.language_id[this.$i18n.locale],
            });

            if (exchangeCoupon.code == 'success') {
                this.couponExchange = exchangeCoupon.data;

            }
            this.showLoader = false;
        },
        async getPoints() {
            var userResponse = await getAPI('/user/point');

            if (userResponse.code == 'success') {
                this.userPoint = userResponse.data;
                this.userPoint.displayExpiryDate = this.userPoint.expiry_soon_date != null ? moment(this.userPoint.expiry_soon_date).format("YYYY/MM/DD") : '-';
                this.userPoint.displayPoint = parseInt(this.userPoint.point_balance).toLocaleString();
                this.userPoint.displayExpireSoonPoint = parseInt(this.userPoint.expiry_soon_points).toLocaleString();

                this.userPoint.points.forEach((point) => {
                    point.displayDate = moment(point.created_at).format("YYYY/MM/DD, h:mmA");
                    point.displayAmount = parseInt(point.amount).toLocaleString();
                });

                this.pointHistory = this.userPoint.points;
            }
        }
    }
}
</script>
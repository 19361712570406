<template>
    <div class="frame">
        <div class="page page-order-confirmation">
            <h1>{{ $t('order_confirmation.title') }}</h1>
            <router-link :to="this.$store.state.multiLanguage + 'menu'" class="link-back"><img
                    src="/images/icons/back.png"></router-link>
            <section class="order_confirmation__address " @click="addMore()"
                :class="orderType == 'pickup' ? 'order_confirmation__address-pickup' : ''">
                <div v-if="orderType == 'delivery'">
                    <label>{{ this.$store.state.selectedAddress != '' ?
                this.$store.state.selectedAddress.label : '-' }}</label>
                    <span>{{ this.$store.state.selectedAddress != '' ?
                this.$store.state.selectedAddress.displayAddressLine : '-' }}</span>
                </div>
                <div v-if="orderType == 'pickup'">
                    <label>{{ outletDetail != null ?
                outletDetail.name : '-' }}</label>
                    <span>{{ outletDetail != null ?
                outletDetail.address_line_1 : '-' }}</span>
                </div>
            </section>
            <section class="order_confirmation__method" v-if="orderType == 'delivery'">
                <span>{{ $t('order_confirmation.delivery_options') }}</span>
                <label>{{ $t('order_confirmation.standard') }}</label>
            </section>
            <section class="order_confirmation__items">
                <label>{{ $t('order_confirmation.your_order') }}</label>
                <div class="order_confirmation__item" v-for="(item, index) in cartItem.items" :key="index">
                    <div class="order_confirmation__item-main">
                        <div class="order_confirmation__item--quantity">
                            <span>x{{ item.cart_item_quantity }}</span>
                        </div>
                        <div class="order_confirmation__item--thumbnail">
                            <img :src="item.thumbnail_image != null ? item.thumbnail_image : require('/public/images/sample/no-image.png')">
                        </div>
                        <div class="order_confirmation__item--details">
                            <label class="order_confirmation__item--details_name">{{ item.name }}</label>
                            <span class="order_confirmation__item--details_desc"
                                v-if="item.product_type == 'configurable'">- {{ item.variants.find((variant) =>
                    variant.variant_id == item.cart_item_variant_id) ? item.variants.find((variant) =>
                        variant.variant_id == item.cart_item_variant_id).name : '' }}</span>
                            <span class="order_confirmation__item--details_desc"
                                v-if="item.product_type == 'configurable_bundle'">- {{ item.bundle_types.find((variant) =>
                    variant.bundle_type_id == item.cart_item_bundle_type_id) ? item.bundle_types.find((variant) =>
                    variant.bundle_type_id == item.cart_item_bundle_type_id).name : "" }}</span>
                            <span class="order_confirmation__item--details_desc" v-for="bundle in item.bundle_items" :key="bundle.id">
                                - {{ bundle.name }}
                            </span>

                            <small v-if="item.not_available">{{ $t('no_longer') }}</small>
                            <!-- <small v-if="item.out_of_stock">Item out of stock</small> -->
                            <small v-if="item.invalid && item.invalid_text != ''">{{ item.invalid_text }}</small>
                        </div>
                        <span class="order_confirmation__item--details_price">{{ item.cart_item_total.toFixed(2) }}</span>
                    </div>

                    <div class="order_confirmation__item-addon" v-if="item.cart_addons != ''">
                        <h2 class="">{{ $t('add_on') }}</h2>
                        <!-- <div class="order_confirmation__item-addon-item" v-for="(addon , index) in item.cart_addons" :key="index">
                            <p>{{ addon.name + ' x ' + addon.cart_item_addon_quantity }}</p>
                            
                            <span v-if="addon.quantity <= 0" class="out_of_stock">{{ $t('out_of_stock') }}</span>
                            <span v-if="addon.quantity > 0 && addon.cart_item_addon_quantity <= addon.quantity">{{ ' + ' + addon.cart_item_addon_display_price }}</span>
                            <span v-if="addon.cart_item_addon_quantity > addon.quantity" class="out_of_stock">{{ 'Exceed Available quantity' }}</span>
                        </div> -->
                        <div class="order_confirmation__item" v-for="(addon , index) in item.cart_addons" :key="index">
                            <div class="order_confirmation__item-main">
                                <div class="order_confirmation__item--quantity">
                                    <span>x{{ addon.cart_item_addon_quantity }}</span>
                                </div>
                                <div class="order_confirmation__item--thumbnail">
                                    <img :src="addon.thumbnail_image != null ? addon.thumbnail_image : require('/public/images/sample/no-image.png')">
                                </div>
                                <div class="order_confirmation__item--details">
                                    <label class="order_confirmation__item--details_name">{{ addon.name }}</label>
                                    <span class="order_confirmation__item--details_desc" v-for="bundleItem in addon.bundle_items" :key="bundleItem.id" >- {{ bundleItem.name }}<br/></span>
                                    <span class="order_confirmation__item--details_desc" v-if="addon.product_type == 'configurable'" >- {{ addon.selected_variant.name }}<br/></span>
                                </div>
                                <div class="order_confirmation__item-addon-price">
                                    <span v-if="addon.quantity <= 0" class="out_of_stock">{{ $t('out_of_stock') }}</span>
                                    <div v-if="addon.quantity > 0 && addon.cart_item_addon_quantity <= addon.quantity">
                                        <span class="order_confirmation__item-addon-price-discounted">{{ addon.cart_item_addon_total.toFixed(2) }}</span>
                                        <span class="order_confirmation__item-addon-price-actual" v-if="addon.cart_item_addon_actual_price > addon.cart_item_addon_price">{{ (addon.cart_item_addon_actual_price * addon.cart_item_addon_quantity).toFixed(2) }}</span>
                                    </div>
                                    <span v-if="addon.cart_item_addon_quantity > addon.quantity" class="out_of_stock">{{ $t('exceed_quantity') }}</span>
                                    <span v-if="addon.cart_item_addon_quantity > addon.upsell_max_purchase_qty" class="out_of_stock">{{ $t('max_purchase') + addon.upsell_max_purchase_qty }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="order_confirmation__item--action" v-if="item.invalid">
                        <span v-if="!item.reduceQuantity" @click="removeCartItem(item.cart_item_id)">{{ $t('remove')
                            }}</span>
                        <span v-if="item.reduceQuantity" @click="loadProduct(item)">{{ $t('order_confirmation.edit')
                            }}</span>
                    </div>
                    <div class="order_confirmation__item--action" v-else @click="loadProduct(item)">
                        <span>{{ $t('order_confirmation.edit') }}</span>
                    </div>
                </div>
                <div class="order_confirmation__item" v-for="(gift_item, index) in cartItem.coupon_gifts" :key="index">
                    <div class="order_confirmation__item-main">
                        <div class="order_confirmation__item--quantity">
                            <span>x1</span>
                        </div>
                        <div class="order_confirmation__item--thumbnail">
                            <img :src="gift_item.thumbnail_image != null ? gift_item.thumbnail_image : require('/public/images/sample/no-image.png')">
                        </div>
                        <div class="order_confirmation__item--details">
                            <label class="order_confirmation__item--details_name">{{ gift_item.name }}</label>
                            <span class="order_confirmation__item--details_desc"
                                v-if="gift_item.product_type == 'configurable'">- {{ gift_item.variants.find((variant) =>
                    variant.variant_id == gift_item.cart_item_variant_id) ? gift_item.variants.find((variant) =>
                        variant.variant_id == gift_item.cart_item_variant_id).name : '' }}</span>
                            <span class="order_confirmation__item--details_desc"
                                v-if="gift_item.product_type == 'configurable_bundle'">- {{ gift_item.bundle_types.find((variant) =>
                    variant.bundle_type_id == gift_item.cart_item_bundle_type_id) ? gift_item.bundle_types.find((variant) =>
                    variant.bundle_type_id == gift_item.cart_item_bundle_type_id).name : "" }}</span>
                            <span class="order_confirmation__item--details_desc" v-for="bundle in gift_item.bundle_items" :key="bundle.id">
                                - {{ bundle.name }}
                            </span>
                            <small v-if="gift_item.not_available">{{ $t('no_longer') }}</small>
                            <!-- <small v-if="item.out_of_stock">Item out of stock</small> -->
                            <small v-if="gift_item.invalid && gift_item.invalid_text != ''">{{ gift_item.invalid_text }}</small>
                        </div>
                        <span class="order_confirmation__item--details_price">0.00</span>

                    </div>
                    <div class="order_confirmation__item--action">
                        <span class="order_confirmation__item--details_remove" @click="removeCoupon(gift_item.coupon_id)">{{ $t('remove') }}</span>
                    </div>
                </div>
                
           
                <div class="order_confirmation__item" v-if="cartItem.items == ''">
                    <span>{{ $t('order_confirmation.no_item') }}</span>
                </div>
                <label @click="addMore()" class="order_confirmation__items-addmore"><img src="/images/icons/plus.png" > {{ $t('order_confirmation.add_more') }}</label>
            </section>
            <section class="order_confirmation__promoters" v-if="false">
                <label>推销员</label>
                <div class="order_confirmation__promoter">
                    <div class="order_confirmation__promoter--thumbnail">
                        <img src="/images/sample/promoter-a.png">
                    </div>
                    <div class="order_confirmation__promoter--name">
                        <label>Allure</label>
                        <span>+2小时 </span>
                    </div>
                    <div class="order_confirmation__promoter--action">
                        <span>编辑</span>
                    </div>
                </div>
                <div class="order_confirmation__promoter">
                    <div class="order_confirmation__promoter--thumbnail">
                        <img src="/images/sample/promoter-b.png">
                    </div>
                    <div class="order_confirmation__promoter--name">
                        <label>Random</label>
                        <span>+2小时 </span>
                    </div>
                    <div class="order_confirmation__promoter--action">
                        <span>编辑</span>
                    </div>
                </div>
                <div class="order_confirmation__promoter--add">
                    添加更多推销员
                </div>
            </section>
            <section class="order_confirmation__remark">
                <label>{{ $t('order_confirmation.remarks') }}</label>
                <div class="order_confirmation__remark--input">
                    <textarea rows="3" :placeholder="$t('order_confirmation.add_your_request')"
                        v-model="remark"></textarea>
                </div>
            </section>
            <section class="order_confirmation__referral">
                <label>{{ $t('order_confirmation.referral_code') }}</label>
                <div class="order_confirmation__referral--input">
                    <input type="text" v-model="referral_code" @input="referral_code = referral_code.toUpperCase()" maxlength="6"/>
                </div>
            </section>
            <section class="order_confirmation__promocode">
                <label>{{ $t('order_confirmation.promo_code') }}</label>
                <div class="order_confirmation__promocode_box" @click="openCouponPopUp()">
                    <span>{{ $t('order_confirmation.select_promo') }}</span>
                </div>
                <div class="order_summary_voucher-selected">
                    <p class="invalid-coupon" v-if="cartItem.not_applied_coupons">
                        *{{ $t('checkout_detail.order_summary.voucher.invalid_coupon') }}<br/>
                        <span v-for="invalidCoupon in cartItem.not_applied_coupons" :key="invalidCoupon.id"> - {{ invalidCoupon.caption }} <br/></span>
                    </p>
                    <p class="invalid-coupon" v-if="cartItem.not_applied_promocodes">
                        *{{ $t('checkout_detail.order_summary.promocode.invalid_promocode') }}<br/>
                        <span v-for="invalidPromocode in cartItem.not_applied_promocodes" :key="invalidPromocode.id"> - {{ invalidPromocode.caption }} <br/></span>
                    </p>
                    <div class="order_summary_voucher-selected-items promocode"
                        v-for="(coupon_applied, index) in cartItem.coupon_applied" :key="index">
                        {{ coupon_applied.caption }}<span class="order_summary_voucher-selected-items-remove promocode"
                            @click="removeCoupon(coupon_applied.id)">X</span></div>
                    <div class="order_summary_voucher-selected-items promocode"
                        v-for="(not_applied_coupons, index) in cartItem.not_applied_coupons" :key="index">
                        {{ not_applied_coupons.caption }}<span class="order_summary_voucher-selected-items-remove promocode"
                            @click="removeCoupon(not_applied_coupons.member_coupon_id)">X</span></div>
                    <div class="order_summary_voucher-selected-items coupon"
                        v-for="(promocode_applied, index) in cartItem.promocode_applied" :key="index">
                        {{ promocode_applied.code }}<span class="order_summary_voucher-selected-items-remove coupon"
                            @click="removePromocode(promocode_applied.id)">X</span></div>
                    <div class="order_summary_voucher-selected-items coupon"
                        v-for="(not_applied_promocodes, index) in cartItem.not_applied_promocodes" :key="index">
                        {{ not_applied_promocodes.code }}<span class="order_summary_voucher-selected-items-remove coupon"
                            @click="removePromocode(not_applied_promocodes.promocode_id)">X</span></div>
                </div>
            </section>
            <section class="order_confirmation__contact">
                <label>{{ $t('order_confirmation.contact_info') }}</label>
                <div class="order_confirmation__contact_box">
                    <div class="order_confirmation__contact--thumbnail">
                        <img src="/images/sample/profile.png">
                    </div>
                    <div class="order_confirmation__contact--name">
                        <label>{{ user ? user.firstname : '-' }}</label>
                        <span>{{ user ? (user.phone != null ? user.phone : '-') : '-' }}</span>
                    </div>
                </div>
            </section>
            <section class="order_confirmation__payment">
                <label>{{ $t('order_confirmation.payment_method') }}</label>
                <div class="order_confirmation__payment_box">
                    <div class="order_confirmation__payment_box_selection" v-for="payment in paymentMethods"
                        :key="payment.id">
                        <label class="radio__wrap">
                            <input name="payment_method_id" id="payment_method_id-a" class="radio__input" type="radio"
                                :value="payment.id" v-model="paymentSelected">
                            <div class="radio__design"></div>
                            <div class="radio__text">
                                <div class="radio__thumbnail" v-if="false">
                                    <!-- <img src="/images/sample/product-addon.png"> -->
                                </div>
                                {{ payment.name }}
                            </div>
                        </label>
                    </div>
                </div>
            </section>
            <section class="order_confirmation__summary">
                <table class="table">
                    <tr class="bold">
                        <td class="td--label">{{ $t('order_confirmation.summary') }}</td>
                    </tr>
                    <tr>
                        <td class="td--label">{{ $t('order_confirmation.acc_incl_sst') }}</td>
                        <td class="td--value td--to-right">{{ cartItem.display_subtotal }}</td>
                    </tr>
                    <tr>
                        <td class="td--label">{{ $t('order_confirmation.subtotal') }}</td>
                        <td class="td--value td--to-right">{{ cartItem.display_subtotal }}</td>
                    </tr>
                    <tr v-if="false">
                        <td class="td--label">推销员</td>
                        <td class="td--value td--to-right">RM 165.00</td>
                    </tr>
                    <tr v-if="orderType == 'delivery'">
                        <td class="td--label">{{ $t('order_confirmation.delivery_fee') }}</td>
                        <td class="td--value td--to-right">{{ cartItem.display_shipping_fee }}</td>
                    </tr>
                    <tr v-if="orderType == 'delivery' && cartItem.shipping_fee_discount > 0">
                        <td class="td--label">{{ $t('order_confirmation.delivery_fee_discount') }}</td>
                        <td class="td--value td--to-right">{{ cartItem.display_shipping_fee_discount }}</td>
                    </tr>
                    <tr class="bold">
                        <td class="td--label">{{ $t('order_confirmation.grand_total') }}</td>
                        <td class="td--value td--to-right">{{ cartItem.display_total }}</td>
                    </tr>
                    <tr>
                        <td class="td--label">{{ $t('order_confirmation.1602_exp_earned') }}</td>
                        <td class="td--value td--to-right">{{ cartItem.exp_earned }} exp</td>
                    </tr>
                    <tr>
                        <td class="td--label">{{ this.$store.state.user.member_group_id == 3 ? $t('order_confirmation.1602_points_earned_vip') : $t('order_confirmation.1602_points_earned') }} ({{ cartItem.point_rate }}x)</td>
                        <td class="td--value td--to-right">{{ cartItem.point_earned }} pts</td>
                    </tr>
                    <tr v-for="coupon in cartItem.coupon_applied" :key="coupon.id">
                        <td class="td--label"><img src="/images/icons/loyaltycard.png"/>{{ coupon.caption }}</td>
                        <td class="td--value td--to-right coupon" v-if="coupon.discount_type != 'gift'">{{ coupon.display_discount }}</td>
                        <td class="td--value td--to-right coupon" v-else>{{ $t("checkout_detail.order_summary.voucher.free") }}</td>
                    </tr>
                    <tr v-for="promo in cartItem.promocode_applied" :key="promo.id">
                        <td class="td--label"><img src="/images/icons/movieticket.png"/>{{ promo.code }}</td>
                        <td class="td--value td--to-right coupon">{{ promo.display_discount }}</td>
                    </tr>
                </table>
            </section>
            <section class="order_confirmation__action">
                <div class="order_confirmation__action_summary">
                    <span>{{ $t('order_confirmation.total') }}</span>
                    <label>{{ cartItem.display_total }}</label>
                </div>
                <template v-for="(voucher, index) in cartItem.coupons" :key ="index">
                    <div class="order_confirmation__action_invalid" v-if="cartItem.invalid && voucher.error == 'not_reach_min_spend'">
                        {{ voucher.caption + $t('voucher_apply.only_applicable') + 'RM' + voucher.min_spend.toFixed(2) }}
                    </div>
                    <div class="order_confirmation__action_invalid" v-if="cartItem.invalid && voucher.error == 'voucher_not_applied'">
                        {{ $t('voucher_apply.cart_not') + voucher.caption + $t('voucher_apply.eligible') }}
                    </div>
                    <div class="order_confirmation__action_invalid" v-if="cartItem.invalid && voucher.error == 'product_is_inactive'">
                        {{ $t('voucher_apply.product_of') + voucher.caption + $t('voucher_apply.unavailable') }}
                    </div>
                    <div class="order_confirmation__action_invalid" v-if="cartItem.invalid && voucher.error == 'voucher_expired'">
                        {{ voucher.caption + $t('voucher_apply.expired')  }}
                    </div>
                </template>
                <template v-for="(promo, index) in cartItem.promocodes" :key ="index">
                    <div class="order_confirmation__action_invalid" v-if="cartItem.invalid && promo.error == 'not_reach_min_spend'">
                        {{ promo.caption + $t('promocode_apply.only_applicable') + 'RM' + promo.min_spend.toFixed(2) }}
                    </div>
                    <div class="order_confirmation__action_invalid" v-if="cartItem.invalid && promo.error == 'promocode_not_applied'">
                        {{ $t('promocode_apply.cart_not') + promo.caption + $t('promocode_apply.eligible') }}
                    </div>
                    <div class="order_confirmation__action_invalid" v-if="cartItem.invalid && promo.error == 'product_is_inactive'">
                        {{ $t('promocode_apply.product_of') + promo.caption + $t('promocode_apply.unavailable') }}
                    </div>
                    <div class="order_confirmation__action_invalid" v-if="cartItem.invalid && promo.error == 'promocode_only_allow_for_selected_products'">
                        {{ promo.caption + $t('promocode_apply.selected_product') }}
                    </div>
                </template>
                <div class="order_confirmation__action_button">
                    <button class="btn btn-primary " :class="{ 'disabled': !submitButton }" @click="placeOrder()">{{
                $t('order_confirmation.order_now')
            }}</button>
                </div>
            </section>
        </div>
    </div>
    <MenuProduct v-if="productPopup != null" :popupData="productPopup" :editItem="editItem" @close="closeProduct()" />
    <CouponsPopUp v-if="couponOpened != null" :couponOpened="couponOpened" :cartItem="cartItem" @applyCoupons="applyCoupons"
        @removeCoupon="removeCoupon" @applyPromocode="applyPromocode" @closeCouponsPopUp="closeCouponsPopUp()">
    </CouponsPopUp>
    <Loader_component :loading="showLoader" :isFullPage="true" />
</template>


<script>
import { getCart, deleteCartItem } from '@/js/cart';
import MenuProduct from './MenuProduct.vue';
import CouponsPopUp from '@/components/voucher/CouponsPopUp.vue';
import { getGuestID, postAPI, getAPI } from '@/js/general';
import { createOrder } from '@/js/order'
import Loader_component from "@/include/loader_component.vue";

export default {
    name: 'OrderConfirmation',
    data() {
        return {
            showLoader: false,
            productPopup: null,
            editItem: null,
            cartItem: [],
            paymentMethods: [],
            user: this.$store.state.user,
            paymentSelected: '',
            couponOpened: null,
            remark: '',
            referral_code: '',
            outletDetail: null,

            orderType: 'delivery',
            submitButton: true,
        }
    },
    async created() {
        this.referral_code = localStorage.referral_code;
        this.$store.state.outlet_id = localStorage.outlet_id;

        var generalResponse = await getAPI('/general');

        if (generalResponse.code == "success") {
            // this.$store.state.categories = [];
            this.paymentMethods = generalResponse.data.payment_methods;
        }

        if (this.$store.state.outlet_id == null) {
            this.$router.push(`${this.$store.state.multiLanguage}` + 'menu');
        }

        this.$store.state.showFooter = false;
        if (localStorage.order) {
            if (localStorage.order == 'delivery') {
                this.orderType = 'delivery';
            } else if (localStorage.order == 'pickup') {
                this.orderType = 'pickup';
                if (localStorage.outletSelected != 'undefined') {
                    this.outletDetail = JSON.parse(localStorage.outletSelected);
                }
            } else {
                this.orderType = 'delivery';
            }

        } else {
            this.$swal.fire({
                toast: true,
                position: "top",
                text: this.$t('order_confirmation.select_type'),
                icon: 'error',
                showConfirmButton: false,
                timer: 3000,
                didOpen: (toast) => {
                    toast.addEventListener('click', () => {
                        this.$swal.close();
                        this.$router.push(`${this.$store.state.multiLanguage}` + 'menu');
                    });
                }
            });
        }
        this.$store.state.selectedAddress = localStorage.addressSelected ? JSON.parse(localStorage.addressSelected) : '';

        await this.getCartData();
    },
    components: {
        MenuProduct,
        CouponsPopUp,
        Loader_component
    },
    computed: {
    },
    mounted() {
    },
    methods: {
        addMore() {
            this.$router.push(`${this.$store.state.multiLanguage}` + 'menu');
        },
        async removeCartItem(cartItemId) {
            var removeCartItemResponse = await deleteCartItem(cartItemId);
            if (removeCartItemResponse.code == "success") {
                await this.getCartData();
            }
        },
        async getCartData() {
            this.showLoader = true;
            var selectedAddress = this.$store.state.selectedAddress;
            var selected_shipping = null
            if (this.orderType == 'delivery') {
                selected_shipping = 'shipping';
            } else {
                selected_shipping = 'pickup';
            }
            var cartResponse = await getCart(selectedAddress.country_id, selectedAddress.postal_code, selected_shipping);

            if (cartResponse.code == "success") {
                this.cartItem = cartResponse.data;

                if (this.cartItem.invalid) {
                    this.submitButton = false;
                } else {
                    this.submitButton = true;
                }
                
                this.cartItem.items.forEach((item) => {

                    item.selectedImageId = "";
                    item.selectedImage = null;
                    item.displayBundleItem = [];
                    item.selectedVariant = [];
                    item.invalid_text = "";
                    item.reduceQuantity = false;

                    item.selectedImage = item.thumbnail_image;
                    if (item.product_type == "configurable" && item.invalid != true) {
                        var findVariantId = item.variant_properties.filter((inner_item) => inner_item.variant_id == item.cart_item_variant_id);
                        item.displayVariantProperties = findVariantId;

                        item.selectedVariant = item.variants.find((variant) => variant.variant_id == item.cart_item_variant_id);
                        item.selectedImageId = item.selectedVariant.images[0];
                        if (item.selectedImageId != null) {
                            item.selectedImage = item.images.find((img) => img.id == item.selectedImageId).image;
                        }
                    }

                    if (item.product_type == "configurable_bundle" && item.invalid != true) {
                        item.displayBundleItem = item.bundle_types.find((bundle) => bundle.bundle_type_id == item.cart_item_bundle_type_id);
                        item.selectedImage = item.thumbnail_image;
                    }

                    if (item.invalid) {
                        item.invalid_text = this.$t('invalid.item');

                        if (item.cart_item_quantity > item.quantity) {
                            item.reduceQuantity = true;
                            item.invalid_text = this.$t('invalid.reduce_qty') + item.quantity;
                        }

                        if (item.invalid_variant) {
                            item.reduceQuantity = false;
                            item.invalid_text = this.$t('invalid.variant');
                        }

                        if (item.out_of_stock) {
                            item.reduceQuantity = false;
                            item.invalid_text = this.$t('invalid.oos');
                        }
                    }

                })

            }

            this.showLoader = false;
        },
        closePopup() {
            this.popupData = null;
        },
        // viewRemark(item) {
        //     this.popupData = {
        //         remark: item.remark,
        //         label: item.name,
        //     }
        // },
        loadProduct(item) {
            this.productPopup = item.slug;

            if (item.product_type == 'configurable_bundle') {
                this.editItem = item.cart_item_bundle_type_id;
            } else if (item.product_type == 'configurable') {
                this.editItem = item.cart_item_variant_id;
            }
        },
        async closeProduct() {
            this.productPopup = null;
            await this.getCartData();
        },
        async placeOrder() {
            this.showLoader = true;

            var placeOrderStatus = true;

            if (this.cartItem.items.length < 1) {
                placeOrderStatus = false;
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('order_confirmation.empty_cart'),
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$router.push(`${this.$store.state.multiLanguage}` + 'menu');
                            this.$swal.close();
                        });
                    }
                });
            } else if (this.paymentSelected == '') {
                placeOrderStatus = false;
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('order_confirmation.select_payment_method'),
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            } else if (this.orderType == 'delivery' && this.$store.state.selectedAddress == '') {
                placeOrderStatus = false;
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('order_confirmation.select_delivery_address'),
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$router.push(`${this.$store.state.multiLanguage}` + 'menu');
                            this.$swal.close();
                        });
                    }
                });
            } else {
                placeOrderStatus = true;
            }

            if (placeOrderStatus) {
                var address = this.$store.state.selectedAddress;

                var order_data;

                if (this.orderType == 'pickup') {
                    order_data = {
                        firstname: this.$store.state.user.firstname,
                        lastname: '',
                        email: '',
                        phone: this.$store.state.user.phone,
                        remark: this.remark,
                        selected_shipping: 'pickup', // hardcoded
                        payment_method_id: this.paymentSelected ? this.paymentSelected : null,
                        outlet_id: this.$store.state.outlet_id,
                        billing_same_as_shipping: 1,
                        shipping_phone: this.$store.state.user.phone,
                        billing_phone: this.$store.state.user.phone,
                        referral_code: this.referral_code
                    };
                } else {
                    order_data = {
                        firstname: this.$store.state.user.firstname,
                        lastname: '',
                        email: '',
                        outlet_id: this.$store.state.outlet_id,
                        phone: this.$store.state.user.phone,
                        remark: this.remark,
                        selected_shipping: 'shipping', // hardcoded
                        selected_shipping_ref: 'delivery', // hardcoded
                        billing_same_as_shipping: 1,
                        shipping_address_line_1: address.displayAddressLine,
                        shipping_address_line_2: '',
                        longitude: address.longitude,
                        latitude: address.latitude,
                        shipping_postal_code: address.postal_code,
                        shipping_phone: this.$store.state.user.phone,
                        shipping_city: address.city,
                        shipping_state_id: address.state_id,
                        shipping_country_id: address.country_id,

                        billing_address_line_1: address.displayAddressLine,
                        billing_address_line_2: '',
                        billing_postal_code: address.postal_code,
                        billing_phone: this.$store.state.user.phone,
                        billing_city: address.city,
                        billing_state_id: address.state_id,
                        billing_country_id: address.country_id,
                        payment_method_id: this.paymentSelected ? this.paymentSelected : null,
                        referral_code: this.referral_code
                    };
                }

                var createOrderResponse = await createOrder(order_data);
                if (createOrderResponse.code == 'success') {
                    this.submitButton = false;
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: this.$t('order_confirmation.order_place_success'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        },
                        // willClose: () => {
                        //     this.$router.push(this.$store.state.multiLanguage + 'order_details?order_id=' + createOrderResponse.data.hash_id);
                        //     window.location.href = createOrderResponse.data.url;
                        // },
                    });
                    window.location.href = createOrderResponse.data.url;
                } else {
                    this.submitButton = true;
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: createOrderResponse.message,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                }
            }
            this.showLoader = false;
        },
        async applyCoupons(applied_coupons = [], callback = undefined) {
            this.showLoader = true;
            if (applied_coupons.length > 0) {
                this.showLoader = true;
                var applyCouponsResponse = await postAPI('/cart/coupons/apply', {
                    coupons: applied_coupons
                });

                if (applyCouponsResponse.code == 'success') {

                    await this.getCartData();
                    // this.cartData(this.shipping_country_id, this.shipping_postal_code, this.selectedShipping);
                    var coupons_errors = applyCouponsResponse.data;
                    if (coupons_errors.length > 0) {
                        this.$swal.fire({
                            toast: true,
                            position: "top",
                            text: this.$t('someCouponsNotApplied'),
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000,
                            didOpen: (toast) => {
                                toast.addEventListener('click', () => {
                                    this.$swal.close();
                                });
                            }
                        });
                    } else {
                        this.$swal.fire({
                            toast: true,
                            position: "top",
                            text: this.$t('couponApplied'),
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000,
                            didOpen: (toast) => {
                                toast.addEventListener('click', () => {
                                    this.$swal.close();
                                });
                            }
                        });
                    }
                    if (callback != undefined) {
                        await callback();
                    }
                } else {
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: applyCouponsResponse.message ? applyCouponsResponse.message : 'Unexpected error occurs.',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                }
                this.showLoader = false;
                this.closeCouponsPopUp();
            }
            this.showLoader = false;
        },
        openCouponPopUp() {
            this.couponOpened = 'coupon';
        },
        closeCouponsPopUp() {
            this.couponOpened = null;
        },

        async removeCoupon(coupon_id) {
            if (coupon_id) {
                this.showLoader = true;
                var removeCouponResponse = await postAPI('/cart/coupon/remove', {
                    coupon: coupon_id
                });

                if (removeCouponResponse.code == 'success') {

                    await this.getCartData();
                    // await this.cartData();
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text:  this.$t('cart_updated'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                } else {
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: removeCouponResponse.message ? removeCouponResponse.message : 'Unexpected error occurs.',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                }
                this.showLoader = false;
            }
        },

        async applyPromocode(accept_promocode = null) {
            var promocode = this.cart_promocode;
            if (accept_promocode != null) {
                promocode = accept_promocode
            }

            if (promocode && promocode.trim()) {
                this.showLoader = true;
                var promocode_data = {
                    code: promocode,
                };
                if (!this.$store.state.isLoggedIn) {
                    promocode_data['guest_id'] = getGuestID();
                }
                var applyPromocodeResponse = await postAPI('/cart/promocode/apply', promocode_data);

                if (applyPromocodeResponse.code == 'success') {
                    this.cart_promocode = '';

                    await this.getCartData();
                    // await this.cartData();
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: this.$t('promoCodeApplied'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                } else {
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: applyPromocodeResponse.message ? applyPromocodeResponse.message : 'Unexpected error occurs.',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                }
                this.showLoader = false;
            }
        },

        async removePromocode(promocode_id) {
            if (promocode_id) {
                this.showLoader = true;
                var promocode_data = {
                    promocode: promocode_id,
                };
                if (!this.$store.state.isLoggedIn) {
                    promocode_data['guest_id'] = getGuestID();
                }
                var removePromocodeResponse = await postAPI('/cart/promocode/remove', promocode_data);

                if (removePromocodeResponse.code == 'success') {

                    await this.getCartData();
                    // await this.cartData();
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: this.$t('promoCodeRemoved'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                } else {
                    this.$swal.fire({
                        toast: true,
                        position: "top",
                        text: removePromocodeResponse.message ? removePromocodeResponse.message : 'Unexpected error occurs.',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                        didOpen: (toast) => {
                            toast.addEventListener('click', () => {
                                this.$swal.close();
                            });
                        }
                    });
                }
                this.showLoader = false;
            }
        },
    }
}
</script>

<style scoped>
.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import i18n from './js/i18n';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import VueSweetalert2 from 'vue-sweetalert2';

const vue_tel_input_options = {
	'defaultCountry': 'my',
	'inputOptions': {
		maxlength: 14,
	},
	'dropdownOptions': {
		'showDialCodeInSelection': true,
		'showDialCodeOnList': true,
	},
	'mode': 'national',
	// 'customValidate': '/^\+\d+$/',
};

createApp(App)
	.use(i18n).use(VueSweetalert2).use(vSelect)
	.use(VueTelInput, vue_tel_input_options)
	.use(store).use(VCalendar).use(router)
	.mount('#app')

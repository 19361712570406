import { postAPI, getGuestID } from "./general";
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'
import store from '../store/index.js';

const createOrder = async (data) => {
	if (!store.state.isLoggedIn) {
		data['guest_id'] = getGuestID();
	}

	var response = await postAPI('/order/create', data);
	if (response.code == 'success') {
		//
	} else if (response.code != 'invalid_token') {
		var errorMessage = 'Something went wrong.';
		if (response.message) {
			errorMessage.message;
		}

		Swal.fire({
			toast: true,
			position: "top",
			text: errorMessage,
			icon: 'error',
			showConfirmButton: false,
			timer: 2000,
			didOpen: (toast) => {
				toast.addEventListener('click', () => {
					Swal.close();
				});
			}
		});
	}
	return response;
}

export { 
    createOrder,
};
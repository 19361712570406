<template>
    <div class="popup" id="popup">
        <div class="popup__box bg-white" v-if="editAddressData == null">
            <div class="popup__header">
                <label>{{ title }}</label>
                <div class="popup__close" @click="closepopup()"><img src="/images/icons/close-white-bg.svg"></div>
            </div>
            <div class="popup__body" v-if="addressType == 'delivery'">
                <div class="popup__addresses">
                    <p v-if="userAddresses == ''">{{ $t('add_address.no_address') }}</p>
                    <div class="popup__address" v-for="address in userAddresses" :key="address.id">
                        <div class="popup__address_selection">
                            <label class="radio__wrap">
                                <input v-model="addressSelected" :value="address" name="delivery_address"
                                    :id="'delivery_address-' + address.id" class="radio__input" type="radio"
                                    :checked="this.addressSelected && this.addressSelected.id == address.id">
                                <div class="radio__design_address"></div>
                            </label>
                        </div>
                        <div class="popup__address_info">
                            <div class="popup__address_title">
                                {{ address.label == '' ? '-' : address.label }}
                            </div>
                            <div class="popup__address_address">
                                {{ address.displayAddressLine }}
                            </div>
                        </div>
                        <div class="popup__address_edit">
                            <span @click="editAddressInfo(address)"><img src="/images/icons/navigate-next.svg"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popup__body" v-if="addressType == 'pickup'">
                <div class="popup__outlets">
                    <div class="popup__outlet_search">
                        <div class="popup__outlet_searchbox">
                            <input type="text" id="input-search-outlet" v-model="searchQuery" @input="filterOutlets">
                        </div>
                    </div>
                    <div class="popup__outlet--nofound" v-if="noOutletsFound">{{ $t('store_not_found') }}</div>
                    <div class="popup__outlet " :class="outlet.id == selectedOutletId ? 'selected' : ''"
                        v-for="(outlet, index) in filteredOutlets" :key="index" @click="changeOutlet(outlet.id)">
                        <div class="popup__outlet_title">
                            {{ outlet.title }}
                        </div>
                        <div class="popup__outlet_address">
                            {{ outlet.address }}
                        </div>
                        <div class="popup__outlet_status">
                            <span
                                :class="outlet.status == 1 ? 'status__label status__label--open' : 'status__label status__label--close'">{{
            outlet.display_status }} - {{ outlet.distance_to_outlet }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popup__action" v-if="addressType == 'delivery'">
                <button class="btn" id="popup__action--positive " @click="checkAddress()"
                    :class="(userAddresses == '' || addressSelected == null) ? 'disabled' : ''">{{ $t('select_address')
                    }}</button>
                <button class="btn" id="popup__action--negative" @click="addAddress()">{{ $t('add_new') }}</button>
            </div>
        </div>
        <div class="popup__box bg-white" v-if="editAddressData != null">
            <div class="popup__header">
                <label>{{ $t('edit_remark') }}</label>
                <div class="popup__close" @click="closeDetail()"><img src="/images/icons/close.png"></div>
            </div>
            <div class="popup__body">
                <div class="popup__addresses">
                    <div class="popup__edit">
                        <div class="popup__edit_info">
                            <div class="popup__address_title">
                                {{ editAddressData.label == '' ? '-' : editAddressData.label }}
                            </div>
                            <div class="popup__address_address">
                                {{ editAddressData.displayAddressLine }}
                            </div>
                            <div class="popup__address_address">
                                {{ $t('remarks') }}
                                <textarea v-model="editAddressData.remark"></textarea>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="popup__action">
                <button class="btn" id="popup__action--positive" @click="updateAddressRemark(editAddressData)">{{ $t('add_address.save_address') }}</button>
                <button class="btn" id="popup__action--negative" @click="deleteAddress()">{{ $t('add_address.delete_address') }}</button>
            </div>
        </div>
    </div>

</template>


<script>
import { getAPI, postAPI } from '@/js/general';

export default {
    name: 'PopupAddress',
    props: {
        popupData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            addressType: '',
            title: '',
            searchQuery: '',
            outlets: [],
            userAddresses: [],

            editAddressData: null,
            states: [],
            countries: [],
            addressSelected: null,
            selectedOutletId: null
        }
    },
    computed: {
        filteredOutlets() {
            return this.outlets.filter(outlet => {
                const outletInfo = outlet.name.toLowerCase() + outlet.address_line_1.toLowerCase() + outlet.address_line_1.toLowerCase() + outlet.display_status.toLowerCase();
                return outletInfo.includes(this.searchQuery.toLowerCase());
            });
        },
        noOutletsFound() {
            return this.filteredOutlets.length === 0 && this.searchQuery !== '';
        }
    },
    created() {
        if (localStorage.addressSelected) {
            this.addressSelected = JSON.parse(localStorage.addressSelected);
        }
    },
    async mounted() {

        if (this.popupData != null) {
            document.getElementById('popup').style.display = 'block';
            this.addressType = this.popupData.addressPicked;
            this.title = this.popupData.label;
            this.outlets = this.popupData.outlets;
        }

        if (this.addressType == 'pickup') {
            //
            this.selectedOutletId = localStorage.outlet_id;
        }

        if (this.addressType == 'delivery') {
            this.states = this.$store.state.states;
            this.countries = this.$store.state.countries;
            await this.userAddress();
        }
    },
    methods: {
        async userAddress() {
            var userAddressResponse = await getAPI('/user/address');
            if (userAddressResponse.code == 'success') {
                this.userAddresses = userAddressResponse.data;

                this.userAddresses.forEach((address) => {
                    var display_addresses = [];
                    address.displayAddressLine = '';
                    if (address.address_line_1 !== '') display_addresses.push(address.address_line_1);
                    if (address.postal_code !== '') display_addresses.push(address.postal_code);
                    if (address.city !== '') display_addresses.push(address.city);

                    if (address.state_id !== '') display_addresses.push(this.states.find((state) => state.id == address.state_id).name);
                    if (address.country_id !== '') display_addresses.push(this.countries.find((country) => country.id == address.country_id).display_name);

                    address.displayAddressLine = display_addresses.join(", ");
                })
            }
        },
        async updateAddressRemark(userAddress) {
            var firstname = this.$store.state.user.firstname;

            const data = {
                label: userAddress.label,
                default_billing_address: 0,
                default_shipping_address: 0,
                firstname: firstname,
                lastname: "",
                email: "",
                phone: this.$store.state.user.phone,
                address_line_1: userAddress.address_line_1,
                address_line_2: "",
                postal_code: userAddress.postal_code,
                city: userAddress.city,
                state_id: userAddress.state_id,
                country_id: userAddress.country_id,
                remark: this.editAddressData.remark,
                latitude: userAddress.latitude,
                longitude: userAddress.longitude,
                member_address_id: userAddress.id,
            }

            var userAddressResponse = await postAPI('/user/address/update', data);

            if (userAddressResponse.code == 'success') {
                this.closeDetail();
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('add_address.success_remark_update'),
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            }
        },
        async deleteAddress() {
            this.showLoader = true;

            const data = {
                member_address_id: this.editAddressData.id,
            }

            const deleteResponse = await postAPI('/user/address/delete', data);

            if (deleteResponse.code == 'success') {
                await this.userAddress();
                if (localStorage.addressSelected && this.editAddressData.id == JSON.parse(localStorage.addressSelected).id) {
                    this.$emit('addressRemoved');
                }
                this.closeDetail();
                this.$swal.fire({
                    toast: true,
                    position: "top",
                    text: this.$t('add_address.success_remove'),
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                    didOpen: (toast) => {
                        toast.addEventListener('click', () => {
                            this.$swal.close();
                        });
                    }
                });
            }
            this.showLoader = false;
        },
        checkAddress() {

            if (this.$store.state.carts.items.length > 0 && (localStorage.addressSelected && (JSON.parse(localStorage.addressSelected).id != this.addressSelected.id))) {
                this.$swal.fire({
                    position: "top",
                    text: this.$t('change_address_outlet.change_address'),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: this.$t('change_address_outlet.yes'),
                    cancelButtonText: this.$t('change_address_outlet.no'),
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.$emit('clearCart');
                        localStorage.addressSelected = JSON.stringify(this.addressSelected);
                        this.changeAddress();
                        this.closepopup();
                    }
                });
            } else {
                localStorage.addressSelected = JSON.stringify(this.addressSelected);
                this.changeAddress();
                this.closepopup();
            }
        },
        editAddressInfo(address) {
            this.editAddressData = address;

        },
        closeDetail() {
            this.editAddressData = null
        },
        addAddress() {
            this.$router.push(`${this.$store.state.multiLanguage}` + 'addaddress');
        },
        selectDeliveryAddress() {
            this.deliveryAddressSelected = true;
            this.pickupStoreSelected = false;
        },
        selectPickupStore() {
            this.deliveryAddressSelected = false;
            this.pickupStoreSelected = true;
        },
        closepopup() {
            this.$emit('close');
        },
        changeOutlet(outlet_id) {
            if (this.$store.state.carts.items.length > 0 && (localStorage.outlet_id && (JSON.parse(localStorage.outlet_id) != outlet_id))) {
                this.$swal.fire({
                    position: "top",
                    text: this.$t('change_address_outlet.change_outlet'),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: this.$t('change_address_outlet.yes'),
                    cancelButtonText: this.$t('change_address_outlet.no'),
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.$emit('clearCart');
                        this.closepopup();
                        this.$emit('changeOutlet', outlet_id);
                    }
                });
            } else {
                this.closepopup();
                this.$emit('changeOutlet', outlet_id);
            }
        },
        changeAddress() {
            this.$emit('changeAddress');
        }
    }
}
</script>

<style scoped>
.popup__box {
    animation: slideIn 0.5s ease-in-out forwards;
}
</style>
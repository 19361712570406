<template>
    <AgeLegal v-if="this.$store.state.legalAccept != 'true'"></AgeLegal>
    <router-view v-if="!showLoader" :key="$route.fullPath" />
    <FooterView v-if="!showLoader" />

    <Loader_component :loading="showLoader" :isFullPage="true" />
</template>

<script>
import FooterView from './include/FooterView.vue';
import { verifyLoginAPI } from "./js/auth";
import { getAPI } from './js/general';
import Loader_component from "@/include/loader_component.vue";
import AgeLegal from "@/views/LandingView.vue";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "@/config/env.js";
import { getMessaging, onMessage } from "firebase/messaging";
import Swal from 'sweetalert2/dist/sweetalert2';
document.ondblclick = function(e) {
    e.preventDefault();
}

initializeApp(firebaseConfig);
const messaging = getMessaging();
onMessage(messaging, (payload) => {
    Swal.fire({
        toast: true,
        position: "top",
        title: payload.data.title,
        text: payload.data.body,
        icon: "info",
        showConfirmButton: false,
        didOpen: (toast) => {
            toast.addEventListener('click', () => {
                Swal.close();
            });
        }
    });
});

export default {
    data() {
        return {
            showLoader: false,
        }
    },
    components: {
        FooterView,
        Loader_component,
        AgeLegal
    },
    async created() {
        this.referral_code = window.location.hash.substring(1);
        this.showLoader = true;
        if (localStorage.getItem("language")) {
            this.$i18n.locale = localStorage.getItem("language");
            this.$store.state.multiLanguage = this.$i18n.locale == 'en' ? '/' : `/${this.$i18n.locale}/`;
        } else {
            localStorage.setItem("language", 'en');
        }

        this.$store.state.legalAccept = localStorage.getItem('legalCondition');

        var generalResponse = await getAPI('/general');

        if (generalResponse.code == "success") {
            // this.$store.state.categories = [];
            this.$store.state.payment_methods = generalResponse.data.payment_methods;
        }

        const localisationLibResponse = await fetch('https://staging-api.1602craftbeer.com/lib/localisation.json');
        const generalDataLocalisationResponse = await localisationLibResponse.json();
        if (generalDataLocalisationResponse.code == "success") {
            this.$store.state.states = generalDataLocalisationResponse.data.states ? generalDataLocalisationResponse.data.states : [];
            this.$store.state.countries = generalDataLocalisationResponse.data.countries ? generalDataLocalisationResponse.data.countries : [];
            localStorage.states = JSON.stringify(this.$store.state.states);
            localStorage.countries = JSON.stringify(this.$store.state.countries);
        }

        this.showLoader = false;
    },
    async mounted() {
        this.$store.state.access_token = localStorage.access_token ? localStorage.access_token : "";
        if (this.$store.state.access_token.trim()) {
            var verifyLoginResponse = await verifyLoginAPI();
            if (verifyLoginResponse.code == "success") {
                this.$store.state.isLoggedIn = true;
                this.$store.state.user = verifyLoginResponse.data;
            } else {
                this.$store.state.isLoggedIn = false;
                this.$store.state.access_token = null;
                localStorage.access_token = "";
            }
        }
        if (!this.$store.state.isLoggedIn) {
            if (window.location.hash) {
                this.$router.push(`${this.$store.state.multiLanguage}` + 'register#' + this.referral_code);
            } else {
                this.$router.push(`${this.$store.state.multiLanguage}` + 'login');
            }
            
        }

        if (this.$store.state.user && this.$store.state.user.phone_verified_at == null) {
            this.$router.push(`${this.$store.state.multiLanguage}` + 'register');
        }
    },
    methods: {
    }
}
</script>

<style>
@import './scss/app.scss';
</style>
<template>
	<div class="popup" id="popup">
		<div class="popup__box">
			<div class="popup__header">
				<label>{{	$t('list_addon') }}</label>
			</div>
			<div class="popup__body">
				<div class="addon_unavailable">
					<div class="addon_unavailable-item" v-for="(addon, index) in addonItemRemove"
						:key="index">
						<div class="addon_unavailable-item-data" v-if="addon.waitingUpdate">
							<p>{{ addon.name }}</p>
							<span v-if="addon.quantity <= 0">{{ $t('out_of_stock') }}</span>
							<span v-if="addon.quantity > 0 && addon.cart_item_addon_quantity > addon.quantity">
								{{ $t('quantity_left') + addon.quantity }}
							</span>
							<span v-if="addon.quantity > 0 && addon.cart_item_addon_quantity > addon.upsell_max_purchase_qty">
								{{ $t('max_purchase') + addon.upsell_max_purchase_qty }}
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="popup__action">
				<button class="btn" id="popup__action--positive " @click="updateBasket()">{{ $t('update_basket')
					}}</button>
			</div>
		</div>
	</div>

</template>


<script>
export default {
	name: 'PopupAddress',
	props: {
		popupData: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			addonItemRemove: null
		}
	},
	created() {

	},
	async mounted() {

		if (this.popupData != null) {
			document.getElementById('popup').style.display = 'block';
			this.addonItemRemove = this.popupData;
		}
	},
	methods: {
		updateBasket() {
			this.$emit('close');
		},
	}
}
</script>

<style scoped>
.popup__box {
	animation: slideIn 0.5s ease-in-out forwards;
}
</style>
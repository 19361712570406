const displayPrice = (price, decimal, currency) => {
    currency = currency || 'MYR';
    decimal = decimal || 2;
    let formatting_options = {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: decimal,
    }
    // users can see how locale passed as a parameter.
    let dollarString = new Intl.NumberFormat("en-US", formatting_options);
    return dollarString.format(price);
}

export { displayPrice };



